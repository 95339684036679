import { Box, Flex, Text } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { CustomForm } from 'models';
import Image from 'next/image';
import React, { FunctionComponent } from 'react';
import { imageFormatter } from 'utils/imageFormatter';

interface ThankYouFormBlockProps {
  model: CustomForm;
}

export const ThankYouFormBlock: FunctionComponent<ThankYouFormBlockProps> = ({ model }) => {
  const imgAltText = model.elements.alternativeText?.value;
  const imageData = imageFormatter(model.elements.thankYouImage.value, imgAltText);
  const title = model.elements.thankYouTitle.value;
  const html = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: model.elements.thankYouText,
  }).html;
  return (
    <Flex color='mountain.base' flexDir='column' alignItems={'center'}>
      <Image width={51} height={70} style={{ objectFit: 'cover' }} src={imageData.src} alt={imageData.alt} />
      <Text
        as='h2'
        mt={{ base: 'xs', md: 's' }}
        textStyle={{ base: 'mobile.h4', md: 'desktop.h4' }}
        textAlign='center'
        maxW={{ base: '100%', md: '420px' }}
        px={{ base: 's', md: '0px' }}
        color='cyan.web'
      >
        {title}
      </Text>
      <Box
        maxW={'420px'}
        mt={{ base: 's', md: 'xs' }}
        textAlign='center'
        fontFamily={{ base: 'HCo Gotham Rounded Book', md: 'HCo Gotham Rounded Light' }}
        fontStyle={'normal'}
        fontWeight={400}
        fontSize={'16px'}
        lineHeight={'24px'}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Flex>
  );
};
