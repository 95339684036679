import { AspectRatio, Box, Container, Flex, Grid, GridItem, Text, VisuallyHidden } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { ShareButton } from 'components/base/ShareButton';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { imageFormatter } from 'utils/imageFormatter';
import { hrefLink } from 'utils/normalization/hrefLink';

import { colorScheme } from '../../lib/colorScheme';
import { ProjectHero as ProjectHeroModel } from '../../models/content-types';
import Breadcrumb, { BackLink } from '../base/Breadcrumb';

interface ProjectHeroProps {
  model: ProjectHeroModel;
  customBreadcrumb?: BackLink;
}

export const ProjectHero: FunctionComponent<ProjectHeroProps> = ({ model, customBreadcrumb }) => {
  const { aria, common } = withTranslations();
  const translations = withTranslations();

  const router = useRouter();

  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;

  const imgAltText = model.elements.alternativeText?.value;
  const compressimage = model.elements.compressImage.value ? model.elements.compressImage.value : 75;
  const imageData = imageFormatter(model.elements.imageFrontify?.value, imgAltText);
  const bgColor = model.elements.backgroundColor.value[0].codename;
  const bgColorScheme = bgColor != 'white' ? colorScheme[bgColor as keyof typeof colorScheme]['base'] : 'white';
  const body = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: model.elements.body,
  }).html;

  const glanceTextData = {
    client: model.elements.atAGlance.linkedItems[0]?.elements.client.value,
    completion: model.elements.atAGlance.linkedItems[0]?.elements.completion.value,
    location: model.elements.atAGlance.linkedItems[0]?.elements.location.value,
    contact: model.elements.atAGlance.linkedItems[0]?.elements.contact.linkedItems[0]?.elements.displayName.value,
    partners: model.elements.atAGlance.linkedItems[0]?.elements.partners.value,
  };

  const breadcrumbOverride = model.elements.breadcrumbOverride.linkedItems.length
    ? ({
        href: hrefLink(
          model.elements.breadcrumbOverride.linkedItems[0],
          (slug as unknown as string)?.split('/'),
          true,
          router.locale,
          translations,
        ),
        label: model.elements.breadcrumbOverride.linkedItems[0].elements.summaryTitle.value,
      } as BackLink)
    : customBreadcrumb != undefined
    ? customBreadcrumb
    : undefined;
  const glanceInfo = Object.keys(glanceTextData).filter((key) => !!glanceTextData[key]);

  return (
    <Box
      id={model.system.codename}
      w='100vw'
      maxW={moduleMaxWidth}
      mx='auto'
      bgGradient={{
        base: `linear(${bgColorScheme} 95%, white 5%)`,
        lg: `linear(${bgColorScheme} 87%, white 13%)`,
      }}
    >
      <Container pt='m' px={{ base: 'xs', lg: 'm', xl: 'l' }}>
        <Box>
          <Breadcrumb color='mountain.base' customLink={breadcrumbOverride} />
        </Box>
        <Flex w='100%' direction={{ base: 'column', lg: 'row' }} py={{ base: 's', lg: 'm' }}>
          {/* ------------- HEADLINE ------------- */}
          <Text
            as='h1'
            w={{ base: '100%', lg: 'calc(33% + 80px)' }}
            pr='l'
            pb={{ base: 'xs', lg: '0' }}
            textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }}
          >
            {model.elements.headline.value}
          </Text>
          {/* ------------- HEADLINE END ------------- */}

          {/* ------------- DESCRIPTION & SUB TITLES ------------- */}
          <Flex flexDir='column' gap={{ base: 'xs', lg: 'm' }} w={{ base: '100%', lg: '70%' }}>
            <Box
              textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.l' }}
              dangerouslySetInnerHTML={{ __html: body }}
            />
            {!!model.elements.atAGlance.value.length && (
              <Box>
                <VisuallyHidden as='h2'>{aria('projectDetails')}</VisuallyHidden>
                <Grid
                  gridTemplateColumns={{ base: `repeat(1, 100%)`, lg: `25.8% 15% 15% 25.8%` }}
                  gap={{ base: 'xs', lg: 's' }}
                >
                  {glanceInfo.map((key, index) => {
                    return (
                      <GridItem key={index}>
                        <Text textStyle='desktop.body.medium.s'>{common(key)}</Text>
                        <Text mt='xxs' textStyle='mobile.body.s'>
                          {glanceTextData[key]}
                        </Text>
                      </GridItem>
                    );
                  })}
                </Grid>
              </Box>
            )}
          </Flex>
          {/* ------------- DESCRIPTION & SUB TITLES END ------------- */}
        </Flex>
        <Box>
          <AspectRatio ratio={16 / 9}>
            <Image
              style={{ objectFit: 'cover', alignSelf: 'center' }}
              src={imageData.src}
              alt={imageData.alt}
              width={imageData.width}
              height={imageData.height}
              quality={compressimage}
            />
          </AspectRatio>
        </Box>
        <Flex justifyContent={'end'} my='xs'>
          <Box w='fit-content'>
            <ShareButton />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
