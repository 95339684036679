import { Avatar, Box, Flex, Text, UnorderedList, useDisclosure, VisuallyHidden } from '@chakra-ui/react';
import { getButtonWithLinkStyling } from 'components/base/Button';
import Image from 'next/image';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';
import { imageFormatter } from 'utils/imageFormatter';
import { normalize } from 'utils/normalization/atAGlance';

import { AtAGlance as AtAGlanceModel } from '../../models/content-types/at_a_glance';
import { ContactFormModal } from './ContactFormModal';

interface AtAGlanceProps {
  model: AtAGlanceModel;
}

export const AtAGlance: FunctionComponent<AtAGlanceProps> = ({ model }) => {
  const { aria, common } = withTranslations();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const TextWrapper = ({ name, text }: { name: string; text: string }) => (
    <Box pb='s'>
      <Text as='dt' textStyle='desktop.body.medium.s'>
        {name}
      </Text>
      <Text as='dd' pt='xxs' textStyle='desktop.body.book.s'>
        {text}
      </Text>
    </Box>
  );

  const ImageWrapper = ({ imageFrontify, msImage, alt }: { imageFrontify?: string; msImage?: string; alt: string }) => {
    const imageData = imageFormatter(imageFrontify);
    return (
      <>
        {imageFrontify || msImage ? (
          <Box h='82px' w='82px' borderRadius='50%' overflow='hidden' mb='xs' order='1'>
            <Image
              height={imageData.height}
              width={imageData.width}
              src={imageFrontify ? imageData.src : 'data:image/png;base64,' + msImage}
              alt={imageFrontify ? imageData.alt : alt}
            />
          </Box>
        ) : (
          <Avatar name={alt} size={{ base: 'xl', lg: '3xl' }} src={alt} alignSelf={{ base: 'center', md: 'start' }} />
        )}
      </>
    );
  };

  const data = normalize(model);
  return (
    // -- For this element to work properly, both children need to be inside a flex --
    <Box
      id={model.system.codename}
      as='aside'
      h={{ base: 'auto', lg: '100%' }}
      position='sticky'
      bottom='0'
      alignSelf='flex-start'
    >
      <VisuallyHidden as='h2'>{aria('projectDetails')}</VisuallyHidden>
      <UnorderedList
        as='dl'
        position={{ base: 'relative', lg: 'sticky' }}
        top='0'
        left='0'
        margin='0'
        w={{ base: 'auto', xl: 'min(420px, 100vw)' }}
        listStyleType='none'
      >
        {!!data.client && <TextWrapper name={common('client')} text={data.client} />}
        {!!data.completion && <TextWrapper name={common('completion')} text={data.completion} />}
        {!!data.location && <TextWrapper name={common('location')} text={data.location} />}
        {!!data.partners && <TextWrapper name={common('partners')} text={data.partners} />}
        {data.contacts.map((contact) => (
          <Flex key={contact.system.id} direction='column' pb='s'>
            <Text textStyle='desktop.body.book.s' order='3'>
              <VisuallyHidden>{aria('jobTitle')}: </VisuallyHidden>
              {contact.elements.jobTitle.value}
            </Text>
            <Text textStyle='desktop.body.medium.s' order='2'>
              <VisuallyHidden>{aria('name')}: </VisuallyHidden>
              {contact.elements.displayName.value}
            </Text>
            <Text textStyle='desktop.body.book.s' order='4'>
              <VisuallyHidden>{aria('telephoneNumber')} </VisuallyHidden>
              {contact.elements.phoneNumber.value}
            </Text>
            {data.contactsCtaModal && (
              <Box order='5'>
                {getButtonWithLinkStyling(
                  `${aria('openContactGetInTouchWith')} ${contact.elements.displayName.value}`,
                  data.contactsCtaText,
                  onOpen,
                  data.contactsCtaModal.system.name,
                )}
              </Box>
            )}
            <ImageWrapper
              imageFrontify={contact.elements.frontifyImage.value}
              msImage={contact.elements.msImage.value}
              alt={contact.elements.displayName.value}
            />
            {data.contactsCtaModal && (
              <ContactFormModal
                id={`${model.system.id}${data.contactsCtaModal.system.id}`}
                model={data.contactsCtaModal}
                isOpen={isOpen}
                contactPersonEmail={contact.elements.email.value}
                onClose={onClose}
              />
            )}
          </Flex>
        ))}
      </UnorderedList>
    </Box>
  );
};
