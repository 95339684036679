import { FileInfo, UPLOAD_FORM_FIELD_KEY } from 'components/modules/CustomForm';
import { CheckboxFormField, DropdownFormField, InputFormField, RadioFormField } from 'models';

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const telephoneRegexCharacters = /^[ 0-9+/-]+/;
const telephoneRegexLength = /^[ 0-9+/-]{5,15}$/;

export const validatCheckField = (field: RadioFormField | CheckboxFormField | DropdownFormField) => (value) => {
  let error;
  if (field.elements.required.value[0].codename === 'yes' && !value) {
    error = `${field.elements.name.value} is required`;
  }
  return error;
};

export const validateInputField = (field: InputFormField) => (value) => {
  let error;
  if (field.elements.required.value[0].codename === 'yes' && !value) {
    error = `${field.elements.name.value} is required`;
  }
  if (value && field.elements.type.value[0].codename === 'number' && isNaN(+value)) {
    error = `${field.elements.name.value} is not a number`;
  }
  if (value && field.elements.type.value[0].codename === 'email' && !emailRegex.test(value)) {
    error = `${field.elements.name.value} is not in valid format`;
  }
  if (field.elements.label.value.includes('CPR') && value.length > 10) {
    error = `${field.elements.name.value} should not be more than 10 digits`;
  }
  if (value && field.elements.type.value[0].codename === 'telephone') {
    if (!telephoneRegexCharacters.test(value)) {
      error = `${field.elements.name.value} has characters that are not allowed.`;
    } else if (!telephoneRegexLength.test(value)) {
      error = `${field.elements.name.value} must have 5 - 15 characters.`;
    }
  }
  return error;
};

export const isUploadFieldInvalid = (files: FileInfo[], field: InputFormField) => {
  return (
    field.elements.required.value[0].codename === 'yes' &&
    !files.some((file) => file.fieldName === UPLOAD_FORM_FIELD_KEY + field.system.codename)
  );
};

export const validateUploadField = (files: FileInfo[], field: InputFormField) => () => {
  let error;
  if (isUploadFieldInvalid(files, field)) {
    error = `${field.elements.name.value} is required`;
  }
  return error;
};

export const getLabelName = (field: InputFormField | RadioFormField | DropdownFormField) => {
  return field.elements.label.value.concat(field.elements.required.value[0].codename === 'yes' ? '*' : '');
};
