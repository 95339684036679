import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import { colorScheme } from 'lib/colorScheme';
import Image from 'next/image';
import React, { FunctionComponent, useState } from 'react';
import { imageFormatter } from 'utils/imageFormatter';
import { StandaloneFormData } from 'utils/types/formData';

import { StandaloneForm as StandaloneFormModel } from '../../models/content-types/standalone_form';
import Breadcrumb from '../base/Breadcrumb';
import { FormWrapper } from './FormWrapper';

interface StandaloneFormProps {
  model: StandaloneFormModel;
  onSubmit?: () => void;
}

export const StandaloneForm: FunctionComponent<StandaloneFormProps> = ({ model, onSubmit }) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  const formDataObj = JSON.parse(model.elements.hubspotFormSnippetForm.value);
  const headline = model.elements.headline.value;

  const backgroundColor = model.elements.backgroundColor.value;
  const thankImgAltText = model.elements.alternativeText?.value;
  const thankYouImageData = imageFormatter(model.elements.frontifyThankYouImage?.value, thankImgAltText);
  const bgColor = colorScheme[backgroundColor[0].codename as keyof typeof colorScheme].base;

  const data = {
    formId: formDataObj.FormGUID,
    portalId: formDataObj.PortalID,
    headline: headline,
    backgroundColor: bgColor,
    thankYouImage: thankYouImageData,
    submittedMessageAlignment: 'center',
    fieldColor: bgColor === 'white' ? 'gray.98' : 'white',
  } as StandaloneFormData;

  return (
    <Container px='0' id={model.system.codename} bg={data.backgroundColor}>
      <Box ml='40px'>
        <Breadcrumb color='mountain.base' />
      </Box>
      <Flex alignItems={'center'} w='100%' flexDir='column' p={{ base: 's', lg: 'l' }}>
        <Flex gap={{ base: 's', lg: 'm' }} flexDir='column' w={{ base: '100%', lg: '50%', xl: '60%' }}>
          {/* text */}
          {!hasBeenSubmitted && (
            <Heading
              textAlign={'center'}
              as='h1'
              color='mountain.base'
              textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }}
            >
              {data.headline}
            </Heading>
          )}
          {/* form */}
          <Box>
            {hasBeenSubmitted && data.thankYouImage && (
              <Box my='l' w='fit-content' h='fit-content' mx='auto'>
                <Image
                  style={{ objectFit: 'cover' }}
                  width={80}
                  height={70}
                  src={data.thankYouImage.src}
                  alt={data.thankYouImage.alt}
                />
              </Box>
            )}
            <FormWrapper
              data={data}
              onSubmit={() => {
                setHasBeenSubmitted(true);
                if (onSubmit) {
                  onSubmit();
                }
              }}
            />
          </Box>
        </Flex>
      </Flex>
    </Container>
  );
};
