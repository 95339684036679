import { AspectRatio, Box, Button, Container, Flex, Text, VisuallyHidden } from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect } from 'react';
import { withTranslations } from 'store/translations';
import { imageFormatter } from 'utils/imageFormatter';
import { hrefLink } from 'utils/normalization/hrefLink';

import { LinkType } from '../../lib/enums/LinkType';
import { QuestionBlock as QuestionBlockModel } from '../../models';
import { IconComp } from '../base/IconComp';
import { Link as CustomLink } from '../base/Link';
interface QuestionBlockProps {
  model: QuestionBlockModel;
}

export const QuestionBlockMobile: FunctionComponent<QuestionBlockProps> = ({ model }) => {
  const translations = withTranslations();
  const router = useRouter();
  let index = 0;
  const [options, setOptions] = React.useState(model.elements.showcasedOptions?.linkedItems[index]);
  const [restart, setRestart] = React.useState(false);
  const [question, setQuestion] = React.useState<string>(
    model.elements.showcasedOptions?.linkedItems[index]?.elements.decisionTreeOptionBlockMainQuestion.value,
  );
  //2-block option
  const [firstOpt, setFirstOpt] = React.useState(false);
  const [secondOpt, setSecondOpt] = React.useState(false);
  //3-block options
  const [firstTwoOpt, setFirstTwoOpt] = React.useState(false);
  const [secondTwoOpt, setSecondTwoOpt] = React.useState(false);
  const [thirdTwoOpt, setThirdTwoOpt] = React.useState(false);
  useEffect(() => {
    model.elements.showcasedOptions?.linkedItems?.map((item, ind) => {
      if (question == item.elements.decisionTreeOptionBlockMainQuestion.value) {
        index = ind;
      }
    });
    setOptions(model.elements.showcasedOptions?.linkedItems[index]);
  }, [question]);
  //End of wizard data
  const EOWData = {
    title: options?.elements.title.value,
    description: options?.elements.description.value,
    ctaText: options?.elements.ctaSnippetCtaText.value,
    fileFrontify: options?.elements.ctaSnippetFrontifyFile.value,
    kontentAiItem: options?.elements.ctaSnippetKontentAiUrl.linkedItems[0],
  };
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  const imageEOWData = imageFormatter(options?.elements.imageFrontify.value, '');

  const url = hrefLink(options, (slug as unknown as string)?.split('/'), false, router.locale, translations);
  const toggleRestart = () => {
    setRestart(!restart);
    index = 0;
    setOptions(model.elements.showcasedOptions?.linkedItems[index]);
    setQuestion(model.elements.showcasedOptions?.linkedItems[index].elements.decisionTreeOptionBlockMainQuestion.value);
  };
  return (
    <>
      {options?.elements.decisionTreeOptionBlockOptionOne.value.length <= 0 ||
      options?.elements.endOfWizard?.value[0]?.codename == 'yes' ? (
        <Box w='352px' h='438px' bg='forest.10' display={{ lg: 'none' }}>
          <Box
            as={'button'}
            style={{
              marginLeft: '292px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flexShrink: 0,
              marginTop: '21px',
            }}
            onClick={toggleRestart}
          >
            <IconComp iconName='rotate' />
          </Box>
          <Text
            style={{
              display: 'flex',
              width: '298px',
              // height: "58px",
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: '26px',
              flexShrink: 0,
            }}
            color='forest.base'
            textStyle='mobile.h4'
          >
            {EOWData.title}
          </Text>
          <Text
            style={{
              display: 'flex',
              width: '240px',
              height: '189px',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: '56px',
              flexShrink: 0,
            }}
            color='forest.base'
            textStyle='desktop.body.xs'
          >
            {EOWData.description}
          </Text>

          {/* CTA block */}
          <Flex mt={{ base: 's', md: 'xs' }} justifyContent='left' ml='56px'>
            <CustomLink
              href={url}
              // href="/"
              type={LinkType.SecondaryButton}
              iconName='chevronRightForLink'
              colorScheme='mountain.base'
              locale={router.locale}
            >
              {EOWData.ctaText}
              {/* {mediaContent.ctaSnippetCtaText.value} */}
              <VisuallyHidden>{/* {translations.aria('about')} {mediaContent.headline.value} */}</VisuallyHidden>
            </CustomLink>{' '}
          </Flex>

          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='187'
            height='312'
            viewBox='0 0 187 312'
            fill='none'
            style={{ marginTop: '-220px', marginLeft: '30px' }}
          >
            <path
              d='M2 2V294C2 302.837 9.16344 310 18 310H185'
              stroke='url(#paint0_linear_31782_6598)'
              strokeWidth='3'
              strokeLinecap='round'
            />
            <defs>
              <linearGradient
                id='paint0_linear_31782_6598'
                x1='93.5'
                y1='2'
                x2='93.5'
                y2='310'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='#125A40' stopOpacity='0'>
                  <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                </stop>
                <stop offset='0.251366' stopColor='#125A40'>
                  <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                </stop>
                <animateTransform
                  attributeName='gradientTransform'
                  dur='1s'
                  type='rotate'
                  from='0 .5 .5'
                  to='360 .5 .5'
                  repeatCount='1'
                />
              </linearGradient>
            </defs>
          </svg>
          {/* ----- IMAGE START ----- */}
          <Box
            // justifyContent={styles.media.justifyContent}
            alignSelf='end'
            w='195px'
            h='195px'
            style={{ marginLeft: '169px', marginTop: '-115px', color: 'forest.base' }}
            // pr={mediaMode === 'start' ? { base: '0', lg: 'l' } : 'unset'}
            // pl={mediaMode === 'end' ? { base: '0', lg: 'l' } : 'unset'}
          >
            <AspectRatio w='100%' ratio={16 / 9}>
              <Image
                sizes='(max-width: 1023) 100vw,(min-width: 1024) 50vw'
                style={{ objectFit: 'cover' }}
                src={imageEOWData.src}
                alt={imageEOWData.alt}
                width={imageEOWData.width}
                height={imageEOWData.height}
                //   quality={compressimage}
              ></Image>
            </AspectRatio>
          </Box>
          {/* ----- IMAGE END ----- */}
        </Box>
      ) : (
        <Box
          id={model.system.codename}
          w='352px'
          //  w='100vw' maxW={'350px'}
          h='403px'
          //   bg='pebble.20'
          style={{ flexShrink: 0, backgroundColor: '#FFF' }}
          //  px={{ base: 'xs', md: 's', lg: 'l' }}
          display={{ lg: 'none' }}
        >
          {options?.elements.decisionTreeOptionBlockOptionThree.value.length > 0 ? (
            <Container
              // pt='126px'
              //  pr='676px'
              // pb='76px'
              // pl='107px'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flexShrink: 0,
                color: '#0098EB',
                //  padding: '126px 676px 76px 107px'style={{padding: '192px 442px 192px 538px'}}
              }}
            >
              {!firstTwoOpt && !secondTwoOpt && !thirdTwoOpt ? (
                <Text id='main_question' w='298px' h='73px' textStyle={'mobile.h4'}>
                  {/* {data.firstQuestion} */}
                  {question}
                </Text>
              ) : (
                <Text w='497px' h='180px' textStyle={'desktop.h4'} style={{ display: 'none' }}>
                  {/* {data.firstQuestion} */}
                  {question}
                </Text>
              )}
              {firstTwoOpt || secondTwoOpt || thirdTwoOpt ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='127'
                  height='45'
                  viewBox='0 0 127 45'
                  fill='none'
                  style={{ marginTop: '30px' }}
                >
                  <path
                    d='M2 2V27C2 35.8366 9.16344 43 18 43H125'
                    stroke='#0098EB'
                    strokeWidth='3'
                    strokeLinecap='round'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6572'
                      x1='2.5'
                      y1='17'
                      x2='2.5'
                      y2='66'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#0098EB' stopOpacity='0'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>

                      <stop offset='0.655612' stopColor='#0098EB'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='127'
                  height='45'
                  viewBox='0 0 127 45'
                  fill='none'
                  style={{ marginTop: '30px' }}
                >
                  <path
                    d='M2 2V27C2 35.8366 9.16344 43 18 43H125'
                    stroke='#0098EB'
                    strokeWidth='3'
                    strokeLinecap='round'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6572'
                      x1='2.5'
                      y1='17'
                      x2='2.5'
                      y2='66'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#0098EB' stopOpacity='0'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>

                      <stop offset='0.655612' stopColor='#0098EB'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              )}
              <Button
                h='60px'
                w='144px'
                p='30px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  marginTop: '-30px',
                  marginLeft: '120px',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  setFirstTwoOpt(true);
                  setTimeout(() => {
                    setFirstTwoOpt(false);
                  }, 2000);
                  setTimeout(() => {
                    setQuestion(options?.elements.decisionTreeOptionBlockFollowUpQuestionOne.value);
                  }, 2000);
                }}
              >
                {options?.elements.decisionTreeOptionBlockOptionOne.value[0].name}
              </Button>
              {firstTwoOpt ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='127'
                  height='45'
                  viewBox='0 0 127 45'
                  fill='none'
                  style={{
                    marginLeft: '190px',
                    marginTop: '-40px',
                  }}
                >
                  <path
                    d='M125 43L125 18C125 9.16344 117.837 2 109 2L2 1.99999'
                    stroke='#0098EB'
                    strokeWidth='3'
                    strokeLinecap='round'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6572'
                      x1='2.5'
                      y1='17'
                      x2='2.5'
                      y2='66'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#0098EB' stopOpacity='0'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>

                      <stop offset='0.655612' stopColor='#0098EB'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : null}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='127'
                height='122'
                viewBox='0 0 127 122'
                fill='none'
                style={{ marginTop: '-50px' }}
              >
                <path
                  d='M2 2V104C2 112.837 9.16344 120 18 120H125'
                  stroke='#0098EB'
                  strokeWidth='3'
                  strokeLinecap='round'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_31782_6572'
                    x1='2.5'
                    y1='17'
                    x2='2.5'
                    y2='66'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='#0098EB' stopOpacity='0'>
                      <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                    </stop>

                    <stop offset='0.655612' stopColor='#0098EB'>
                      <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                    </stop>
                    <animateTransform
                      attributeName='gradientTransform'
                      dur='1s'
                      type='rotate'
                      from='0 .5 .5'
                      to='360 .5 .5'
                      repeatCount='1'
                    />
                  </linearGradient>
                </defs>
              </svg>
              <Button
                h='60px'
                w='144px'
                p='30px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  marginTop: '-17px',
                  marginLeft: '120px',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  setSecondTwoOpt(true);
                  setTimeout(() => {
                    setSecondTwoOpt(false);
                  }, 2000);
                  setTimeout(() => {
                    setQuestion(options?.elements.decisionTreeOptionBlockFollowUpQuestionTwo.value);
                  }, 2000);
                }}
              >
                {options?.elements.decisionTreeOptionBlockOptionTwo.value[0].name}
              </Button>
              {secondTwoOpt ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='127'
                  height='45'
                  viewBox='0 0 127 45'
                  fill='none'
                  style={{
                    marginLeft: '190px',
                    marginTop: '-40px',
                  }}
                >
                  <path
                    d='M125 43L125 18C125 9.16344 117.837 2 109 2L2 1.99999'
                    stroke='#0098EB'
                    strokeWidth='3'
                    strokeLinecap='round'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6572'
                      x1='2.5'
                      y1='17'
                      x2='2.5'
                      y2='66'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#0098EB' stopOpacity='0'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>

                      <stop offset='0.655612' stopColor='#0098EB'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : null}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='127'
                height='122'
                viewBox='0 0 127 122'
                fill='none'
                style={{ marginTop: '-57px' }}
              >
                <path
                  d='M2 2V104C2 112.837 9.16344 120 18 120H125'
                  stroke='#0098EB'
                  strokeWidth='3'
                  strokeLinecap='round'
                />
              </svg>
              <Button
                h='60px'
                w='144px'
                p='30px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  marginTop: '-30px',
                  marginLeft: '120px',
                  marginRight: '234px',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  setThirdTwoOpt(true);
                  setTimeout(() => {
                    setThirdTwoOpt(false);
                  }, 2000);
                  setTimeout(() => {
                    setQuestion(options?.elements.decisionTreeOptionBlockFollowUpQuestionThree.value);
                  }, 2000);
                }}
              >
                {options?.elements.decisionTreeOptionBlockOptionThree.value[0]?.name}
              </Button>
              {thirdTwoOpt ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='127'
                  height='45'
                  viewBox='0 0 127 45'
                  fill='none'
                  style={{
                    marginLeft: '190px',
                    marginTop: '-30px',
                  }}
                >
                  <path
                    d='M125 43L125 18C125 9.16344 117.837 2 109 2L2 1.99999'
                    stroke='#0098EB'
                    strokeWidth='3'
                    strokeLinecap='round'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6572'
                      x1='2.5'
                      y1='17'
                      x2='2.5'
                      y2='66'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#0098EB' stopOpacity='0'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>

                      <stop offset='0.655612' stopColor='#0098EB'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : null}
            </Container>
          ) : (
            <Container
              pt='46px'
              pr='27px'
              pl='25px'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flexShrink: 0,
                color: '#0098EB',
                //  padding: '126px 676px 76px 107px'style={{padding: '192px 442px 192px 538px'}}
              }}
            >
              {!secondOpt && !firstOpt ? (
                <Text id='main_question' w='298px' h='73px' textStyle={'mobile.h4'}>
                  {/* {data.firstQuestion} */}
                  {question}
                </Text>
              ) : (
                <Text w='298px' h='73px' textStyle={'mobile.h4'} style={{ display: 'none' }}>
                  {/* {data.firstQuestion} */}
                  {question}
                </Text>
              )}

              {secondOpt || firstOpt ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='4'
                  height='213'
                  viewBox='0 0 4 213'
                  fill='none'
                  style={{ marginTop: '-100px' }}
                >
                  <path
                    d='M2.00001 211L2 0'
                    stroke='url(#paint0_linear_31782_6619)'
                    strokeWidth='3'
                    strokeLinecap='round'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6619'
                      x1='2.5'
                      y1='49.4531'
                      x2='2.50001'
                      y2='211'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#0098EB' stopOpacity='0'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>

                      <stop offset='0.655612' stopColor='#0098EB'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : (
                <svg xmlns='http://www.w3.org/2000/svg' width='4' height='68' viewBox='0 0 4 68' fill='none'>
                  <path d='M2 66V2' stroke='url(#paint0_linear_31782_6572)' strokeWidth='3' strokeLinecap='round' />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6572'
                      x1='2.5'
                      y1='17'
                      x2='2.5'
                      y2='66'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#0098EB' stopOpacity='0'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>

                      <stop offset='0.655612' stopColor='#0098EB'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              )}
              <svg xmlns='http://www.w3.org/2000/svg' width='127' height='45' viewBox='0 0 127 45' fill='none'>
                <path
                  d='M2 2V27C2 35.8366 9.16344 43 18 43H125'
                  stroke='#0098EB'
                  strokeWidth='3'
                  strokeLinecap='round'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_31782_6572'
                    x1='2.5'
                    y1='17'
                    x2='2.5'
                    y2='66'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='#0098EB' stopOpacity='0'>
                      <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                    </stop>

                    <stop offset='0.655612' stopColor='#0098EB'>
                      <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                    </stop>
                    <animateTransform
                      attributeName='gradientTransform'
                      dur='1s'
                      type='rotate'
                      from='0 .5 .5'
                      to='360 .5 .5'
                      repeatCount='1'
                    />
                  </linearGradient>
                </defs>
              </svg>
              <Button
                h='60px'
                w='104px'
                p='20px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  marginTop: '-30px',
                  marginLeft: '120px',
                  // marginRight: '4px'
                  borderRadius: '4px',
                }}
                onClick={() => {
                  setFirstOpt(true);
                  setTimeout(() => {
                    setFirstOpt(false);
                  }, 2000);
                  setTimeout(() => {
                    setQuestion(options?.elements.decisionTreeOptionBlockFollowUpQuestionOne.value);
                  }, 2000);
                }}
              >
                {options?.elements.decisionTreeOptionBlockOptionOne.value[0]?.name}
              </Button>
              {firstOpt ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='127'
                  height='45'
                  viewBox='0 0 127 45'
                  fill='none'
                  style={{
                    marginLeft: '180px',
                    marginTop: '-35px',
                  }}
                >
                  <path
                    d='M125 43L125 18C125 9.16344 117.837 2 109 2L2 1.99999'
                    stroke='#0098EB'
                    strokeWidth='3'
                    strokeLinecap='round'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6572'
                      x1='2.5'
                      y1='17'
                      x2='2.5'
                      y2='66'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#0098EB' stopOpacity='0'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>

                      <stop offset='0.655612' stopColor='#0098EB'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : null}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='127'
                height='122'
                viewBox='0 0 127 122'
                fill='none'
                style={{
                  marginTop: '-50px',
                }}
              >
                <path
                  d='M2 2V104C2 112.837 9.16344 120 18 120H125'
                  stroke='#0098EB'
                  strokeWidth='3'
                  strokeLinecap='round'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_31782_6572'
                    x1='2.5'
                    y1='17'
                    x2='2.5'
                    y2='66'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='#0098EB' stopOpacity='0'>
                      <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                    </stop>

                    <stop offset='0.655612' stopColor='#0098EB'>
                      <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                    </stop>
                    <animateTransform
                      attributeName='gradientTransform'
                      dur='1s'
                      type='rotate'
                      from='0 .5 .5'
                      to='360 .5 .5'
                      repeatCount='1'
                    />
                  </linearGradient>
                </defs>
              </svg>
              <Button
                h='60px'
                w='104px'
                p='20px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  marginTop: '-30px',
                  marginLeft: '120px',
                  borderRadius: '4px',
                }}
                onClick={() => {
                  setSecondOpt(true);
                  setTimeout(() => {
                    setSecondOpt(false);
                  }, 2000);
                  setTimeout(() => {
                    setQuestion(options?.elements.decisionTreeOptionBlockFollowUpQuestionTwo.value);
                  }, 2000);
                }}
              >
                {options?.elements.decisionTreeOptionBlockOptionTwo?.value[0]?.name}
              </Button>
              {secondOpt ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='127'
                  height='45'
                  viewBox='0 0 127 45'
                  fill='none'
                  style={{
                    marginLeft: '180px',
                    marginTop: '-30px',
                  }}
                >
                  <path
                    d='M125 43L125 18C125 9.16344 117.837 2 109 2L2 1.99999'
                    stroke='#0098EB'
                    strokeWidth='3'
                    strokeLinecap='round'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6572'
                      x1='2.5'
                      y1='17'
                      x2='2.5'
                      y2='66'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#0098EB' stopOpacity='0'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>

                      <stop offset='0.655612' stopColor='#0098EB'>
                        <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : null}
            </Container>
          )}
        </Box>
      )}
    </>
  );
};
