import { Container, Grid } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';

import { colorScheme } from '../../lib/colorScheme';
import { Download as DownloadModel } from '../../models/content-types/download';
import { DownloadItem } from './DownloadItem';

interface DownloadProps {
  model: DownloadModel;
}

export const Download: FunctionComponent<DownloadProps> = ({ model }) => {
  const bgKey = model.elements.backgroundColor.value[0].codename;
  const bg = colorScheme[bgKey as keyof typeof colorScheme]?.base || bgKey;
  const iconBgColor = bg === 'white' ? 'ocean.10' : 'white';
  const items = model.elements.downloadItems.linkedItems;

  return (
    <Container
      id={model.system.codename}
      bg={bg}
      paddingInlineStart={{ base: '0', md: '0', lg: '0' }}
      paddingInlineEnd={{ base: '0', md: '0', lg: '0' }}
      w='min(780px, 100vw)'
    >
      <Grid
        py='s'
        px={{ base: 'xs', md: 's' }}
        as='ul'
        gap={{ base: 's', md: 'm' }}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: `repeat(${items.length}, 1fr)`,
        }}
      >
        {model.elements.downloadItems.linkedItems.map((downloadItem, index) => (
          <DownloadItem
            key={index}
            flexDir={model.elements.downloadItems.linkedItems.length > 1 ? 'column' : 'row'}
            iconBgColor={iconBgColor}
            model={downloadItem}
          />
        ))}
      </Grid>
    </Container>
  );
};
