import {
  ChakraProps,
  ListItem,
  OrderedList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  UnorderedList,
} from '@chakra-ui/react';
import { listItemsToArray, regExpLinkElement } from 'helpers/htmlResolver';
import { useRouter } from 'next/router';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

interface BodyWrapperProps {
  tag: string;
  body: string;
  headingColor?: string;
  sx?: ChakraProps;
  isInTextModule?: boolean;
  textId?: string;
}

const updateInlineLinks = async (bodyText: string, setterFn: Dispatch<SetStateAction<string>>, locale?: string) => {
  const links = bodyText.match(regExpLinkElement);
  const dataItemIdRegex = /data-item-id=(["'])(.*?)\1/;

  let updatedText = bodyText;
  if (links) {
    await Promise.all(
      links.map(async (link) => {
        const id = link.match(dataItemIdRegex)?.[2];
        if (id) {
          const url = await fetch(`/api/sitemap?id=${id}&locale=${locale}`)
            .then((res) => res.json())
            .catch(() => '');
          updatedText = updatedText.replaceAll(`data-item-id="${id}" href`, `data-item-id="${id}" href="${url}"`);
        }
      }),
    );
  }
  setterFn(updatedText);
};

const encodeTextForUrl = (text: string) => {
  const reservedURIcharactersPattern = /[~`!#$^&*()_+={}[\]|\\:;"'<>,.?/]/g;
  return text.replaceAll(' ', '-').replaceAll(reservedURIcharactersPattern, '');
};

const styleByTag = (tag: string, headingColor?: string, router?: any): ChakraProps['sx'] => {
  if (tag.includes('h') && router?.asPath === '/contact-us/polska') {
    return {
      // textStyle: { base: `mobile.${tag}`, lg: `desktop.${tag}` },
      textStyle: { base: `mobile.poland_${tag}`, lg: `desktop.poland_${tag}` },

      color: headingColor,
    };
  }
  if (tag.includes('h') && router?.asPath !== '/contact-us/polska') {
    return {
      textStyle: { base: `mobile.${tag}`, lg: `desktop.${tag}` },
      color: headingColor,
    };
  }
  if (tag.includes('ol') || tag.includes('ul')) return { pl: '0' };

  if (tag.includes('table'))
    return {
      td: { border: '1px solid #526169', p: '3xs', fontSize: '17px' },
      th: { border: '1px solid #526169', p: '3xs' },
    };

  return {};
};

export const BodyWrapper: FC<BodyWrapperProps> = ({ tag, body, headingColor, sx, isInTextModule, textId }) => {
  const [bodyUpdated, setBodyUpdated] = useState(body);
  const { locale } = useRouter();
  const router = useRouter();
  useEffect(() => {
    !textId?.includes('ramboll_s_growth_momentum_continues___text_03') &&
      !textId?.includes('slow_start_in_a_tough_market___macroeconomic_headw') &&
      !textId?.includes('annual_results__test_table') &&
      !textId?.includes('annual_result_2023___table');
    updateInlineLinks(body, setBodyUpdated, locale);
  }, []);

  useEffect(() => {
    !textId?.includes('ramboll_s_growth_momentum_continues___text_03') &&
      !textId?.includes('slow_start_in_a_tough_market___macroeconomic_headw') &&
      !textId?.includes('annual_results__test_table') &&
      !textId?.includes('annual_result_2023___table');
    updateInlineLinks(body, setBodyUpdated, locale);
  }, [locale]);

  if (tag.includes('ol'))
    return (
      <OrderedList sx={styleByTag(tag, headingColor)} {...sx}>
        {listItemsToArray(bodyUpdated)?.map((item, index) => (
          <ListItem key={index} sx={{ pb: 'xxs' }} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
      </OrderedList>
    );

  if (tag.includes('ul'))
    return (
      <UnorderedList sx={styleByTag(tag, headingColor)} {...sx}>
        {listItemsToArray(bodyUpdated)?.map((item, index) => (
          <ListItem key={index} sx={{ pb: 'xxs' }} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
      </UnorderedList>
    );

  if (
    (tag.includes('table') &&
      (textId?.includes('ramboll_s_growth_momentum_continues___text_03') ||
        textId?.includes('slow_start_in_a_tough_market___macroeconomic_headw') ||
        textId?.includes('annual_results__test_table'))) ||
    textId?.includes('annual_result_2023___table')
  ) {
    // const tabledata : [] = bodyUpdated
    const tabledata = Object.assign([], bodyUpdated);

    return (
      <TableContainer whiteSpace='break-spaces' maxWidth='max-content'>
        <Table style={{ border: '1px solid black' }} size='lg'>
          <Tbody style={{ border: '1px solid black' }}>
            {tabledata?.map((item, index) => {
              const total: any = Object.values(item);
              return (
                <Tr
                  style={{
                    //fontWeight: index == 0 || index == 1 ? 'bold' : 'normal',
                    border: '1px solid black',
                    padding: '5px',
                    fontSize: '17px',
                  }}
                >
                  {total.map((data, ind) => {
                    //, fontWeight: total[2].length || total[2].includes('No') || total[2].includes('%')? 'normal' : 'bold'
                    console.log("data and ind",ind, index, total[1])
                    return index == 1 && ind == 1 && (total[1] == 'EURm' || total[1] == 'EUR m' ) ? (
                      <Td
                        style={{
                          // width: '100%',
                          textAlign: total[2].includes('No') || total[2] == '%' ? 'start' : 'end',
                          border: '1px solid black',
                          padding: '5px',
                          // whiteSpace: 'nowrap',
                          fontWeight: 'normal',
                        }}
                      >
                        {data.replaceAll('&amp;', '&')}
                      </Td>
                    ) : ind != 0 && index != 0 ? (
                      <Td
                        style={{
                          // width: '100%',
                          textAlign: total[2].includes('No') || total[2] == '%' ? 'start' : 'end',
                          border: '1px solid black',
                          padding: '5px',
                          // whiteSpace: 'nowrap',
                          fontWeight:
                            total[2].includes('No') ||
                            total[2] == '%' ||
                            index == 1 ||
                            textId?.includes('annual_results__test_table') ||
                            (textId?.includes('annual_result_2023___table') && ind == 2)
                              ? 'bold'
                              : 'normal',
                        }}
                      >
                        {data.replaceAll('&amp;', '&')}
                      </Td>
                    ) : (
                      <Td
                        style={{
                          // width: '120%',
                          border: '1px solid black',
                          padding: '5px',
                          fontWeight:
                            total[2]?.length <= 0 ||
                            total[2]?.includes('No') ||
                            total[2] == '%' ||
                            index == 0 ||
                            index == 1
                              ? 'bold'
                              : 'normal',
                          // whiteSpace: 'nowrap',
                        }}
                      >
                        {data.replaceAll('&amp;', '&')}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
  }

  if (
    (tag.includes('table') &&
      (!textId?.includes('ramboll_s_growth_momentum_continues___text_03') ||
        !textId?.includes('slow_start_in_a_tough_market___macroeconomic_headw') ||
        !textId?.includes('annual_results__test_table'))) ||
    !textId?.includes('annual_result_2023___table')
  )
    return (
      <Table
        width={'max-content'}
        margin='0 auto'
        w='100%'
        sx={styleByTag(tag, headingColor)}
        dangerouslySetInnerHTML={{ __html: bodyUpdated }}
        {...sx}
      />
    );

  return (
    <Text
      as={!isInTextModule ? (tag as any) : 'h2'}
      id={!tag.includes('table') ? `${encodeTextForUrl(bodyUpdated)}` : ''}
      sx={styleByTag(tag, headingColor, router)}
      dangerouslySetInnerHTML={{ __html: bodyUpdated }}
      {...sx}
    />
  );
};
