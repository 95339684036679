import {
  Avatar as ChakraAvatar,
  Box as ChakraBox,
  Divider as ChakraDivider,
  Flex as ChakraFlex,
} from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import { imageFormatter } from 'utils/imageFormatter';

import { Quote as QuoteModel } from '../../models/content-types';

interface QuoteBlockProps {
  model: QuoteModel;
}

export const QuoteBlock: FunctionComponent<QuoteBlockProps> = ({ model }) => {
  const quoteElements = model.elements;
  const imgAltText = quoteElements.alternativeText?.value;
  const compressimage = model.elements.compressImage.value ? model.elements.compressImage.value : 75;
  const imageData =
    !!quoteElements.imageFrontify?.value &&
    imageFormatter(quoteElements.imageFrontify.value, imgAltText, false, compressimage);
  return (
    <ChakraFlex
      id={model.system.codename}
      justifyContent='center'
      w='100%'
      py={{ base: 'l', md: 'xl' }}
      px={{ base: 'xs' }}
    >
      <ChakraFlex direction='column' w={{ base: '100%' }} maxW='768px'>
        <ChakraBox
          as='h3'
          color='cyan.web'
          textStyle={{ base: 'mobile.h4', md: 'desktop.h4' }}
          dangerouslySetInnerHTML={{ __html: quoteElements.quoteText.value }}
        ></ChakraBox>
        <ChakraFlex as='figure' direction={{ base: 'column', sm: 'row' }} alignSelf='start' pt='s'>
          {imageData ? (
            <ChakraBox pr='s'>
              <ChakraAvatar src={imageData.src} name={imageData.alt} size={{ base: 'lg', md: 'xl' }} />
            </ChakraBox>
          ) : (
            false
          )}
          <ChakraFlex direction='column' justifyContent='center' pt={{ base: 'xs', sm: '0' }}>
            <ChakraBox as='figcaption' textStyle='desktop.body.medium.l' color='mountain.base' pb='xxs'>
              {quoteElements.name.value}
            </ChakraBox>
            <ChakraBox as='figcaption' textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.l' }}>
              {quoteElements.occupation.value}
            </ChakraBox>
          </ChakraFlex>
        </ChakraFlex>
        <ChakraDivider size='1px' color='peeble.web' pt={{ base: 'xs', md: 's' }}></ChakraDivider>
      </ChakraFlex>
    </ChakraFlex>
  );
};
