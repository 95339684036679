import {
  AspectRatio,
  Box,
  Container,
  Flex,
  Link,
  useDisclosure,
  useMediaQuery,
  VisuallyHidden,
} from '@chakra-ui/react';
import { Button } from 'components/base/Button';
import { BodyWrapper } from 'components/wrapper/bodyWrapper';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';
import { imageFormatter } from 'utils/imageFormatter';
import { hrefLink } from 'utils/normalization/hrefLink';

import { htmlResolver } from '../../helpers/htmlResolver';
import { colorScheme } from '../../lib/colorScheme';
import { LinkType } from '../../lib/enums/LinkType';
import { MediaContent as MediaContentModel } from '../../models/content-types';
import { Link as CustomLink } from '../base/Link';
import { ContactFormModal } from './ContactFormModal';

interface MediaContentProps {
  model: MediaContentModel;
}

export const MediaContent: FunctionComponent<MediaContentProps> = ({ model }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const translations = withTranslations();
  const router = useRouter();
  const [isSmallScreen] = useMediaQuery('(max-width: 1023px)');
  const mediaContent = model.elements;
  const imgAltText = mediaContent.alternativeText?.value;
  const compressimage = model.elements.compressImage.value ? model.elements.compressImage.value : 75;
  const imageData = imageFormatter(mediaContent.mediaFrontify.value, imgAltText);
  const mediaMode = mediaContent.mediaPosition.value[0].codename;
  const mediaBody = htmlResolver(mediaContent.body);
  const isCampaign = mediaContent.isCampaign.value[0]?.codename === 'yes';
  const headlineSize = mediaContent.headlineSize.value[0]?.codename || 'h4';
  const headlineColor = mediaContent.headlineColor.value[0]?.codename;
  const bgColor = mediaContent.backgroundColor.value[0]?.codename;
  const bgWidth = mediaContent.backgroundWidth.value[0]?.codename;
  const video = model.elements.video23EmbedLink?.value.replaceAll('allowfullscreen', '');
  const fileFrontify = JSON.parse(model.elements.ctaSnippetFrontifyFile.value);
  const kontentAiItem = mediaContent.ctaSnippetKontentAiUrl.linkedItems[0];
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;

  const url = hrefLink(model, (slug as unknown as string)?.split('/'), false, router.locale, translations);
  const focalpointLeftvalue = model.elements.leftFocalPoint.value ? `${model.elements.leftFocalPoint.value}% ` : '50%';
  const focalpointRightvalue = model.elements.rightFocalPoint.value
    ? `${model.elements.rightFocalPoint.value}% `
    : '50%';

  const focalpointvalue = focalpointLeftvalue + focalpointRightvalue;
  const formattedBodyText = mediaBody && mediaBody.filter((item) => item.body !== '<br>');
  const colors =
    mediaMode === 'start'
      ? [
          bgWidth == 'full' ? colorScheme[bgColor as keyof typeof colorScheme]['base'] : 'white',
          colorScheme[bgColor as keyof typeof colorScheme]['base'],
        ]
      : [
          colorScheme[bgColor as keyof typeof colorScheme]['base'],
          bgWidth == 'full' ? colorScheme[bgColor as keyof typeof colorScheme]['base'] : 'white',
        ];

  const styles: any = {
    order: {
      row: mediaMode == 'start' ? 'row-reverse' : 'row',
      column: mediaMode == 'start' ? 'column-reverse' : 'column',
    },
    media: {
      justifyContent: mediaMode == 'start' ? 'end' : 'start',
    },
    text: {
      alignItems: mediaMode == 'start' ? 'start' : 'end',
      maxWidth: isSmallScreen ? '100%' : '480px',
    },
    bgWidth: isSmallScreen ? (mediaMode == 'end' ? '80%' : '80%') : '48.5%',
  };
  // --------------------- VARIABLES END ---------------------
  return (
    <Box id={model.system.codename} w='100vw' position='relative'>
      <Box position='relative' zIndex='1' w='100vw'>
        <Container
          px={{ base: 'xs', lg: 'l' }}
          display={'flex'}
          justifyContent={{ base: 'flex-start', lg: 'center' }}
          flexDir={{ base: 'column-reverse', lg: styles.order.row }}
          w='100%'
          h='100%'
          bgGradient={{
            base: `linear(to-b, ${colors[0]} 20%, ${colors[1]} 0%)`,
            sm: `linear(to-b, ${colors[0]} 30%, ${colors[1]} 0%)`,
            lg: `linear(to-r, ${colors[0]} 50%, ${colors[1]} 0%)`,
          }}
          py={{ base: 's', lg: 'l' }}
        >
          {/* ---- TEXT BLOCK START ---- */}
          <Flex alignItems={{ base: 'unset', lg: 'start' }} w={{ base: '100%', lg: '40%' }} direction='column' h='100%'>
            {/* ---- HEADLINE ---- */}
            {mediaContent.ctaSnippetUrl.value || kontentAiItem ? (
              <>
                <Link href={url}>
                  <Flex flexDir='column-reverse'>
                    <Box
                      as='h2'
                      textStyle={{
                        base: `mobile.${headlineSize}`,
                        lg: `desktop.${headlineSize}`,
                      }}
                      fontSize={{ base: '24px', lg: '40px' }}
                      pb={{ base: 's', lg: 'm' }}
                      pt={{ base: 'xs', lg: 'xs' }}
                      color={
                        isCampaign && headlineColor == 'cyan' && bgColor === 'white' ? 'cyan.web' : 'mountain.base'
                      }
                    >
                      {mediaContent.headline.value}
                    </Box>
                    <Box textStyle='desktop.body.medium.s'>
                      <VisuallyHidden>{translations.aria('thisPageHasTagOf')}: </VisuallyHidden>
                      {mediaContent.tag.value[0]?.name}
                    </Box>
                  </Flex>
                </Link>
              </>
            ) : (
              <>
                <Flex flexDir='column-reverse'>
                  {mediaContent.formModalCtaFormModal.linkedItems[0] && mediaContent.formModalCtaButtonText.value ? (
                    <>
                      <Box as='button' onClick={onOpen}>
                        <Box
                          as='h2'
                          textStyle={{
                            base: `mobile.${headlineSize}`,
                            lg: `desktop.${headlineSize}`,
                          }}
                          fontSize={{ base: '24px', lg: '40px' }}
                          pb={{ base: 's', lg: 'm' }}
                          pt={{ base: 'xs', lg: 'xs' }}
                          color={
                            isCampaign && headlineColor == 'cyan' && bgColor === 'white' ? 'cyan.web' : 'mountain.base'
                          }
                          textAlign={'start'}
                        >
                          {mediaContent.headline.value}
                        </Box>{' '}
                      </Box>
                    </>
                  ) : (
                    <Box
                      as='h2'
                      textStyle={{
                        base: `mobile.${headlineSize}`,
                        lg: `desktop.${headlineSize}`,
                      }}
                      fontSize={{ base: '24px', lg: '40px' }}
                      pb={{ base: 's', lg: 'm' }}
                      pt={{ base: 'xs', lg: 'xs' }}
                      color={
                        isCampaign && headlineColor == 'cyan' && bgColor === 'white' ? 'cyan.web' : 'mountain.base'
                      }
                    >
                      {mediaContent.headline.value}
                    </Box>
                  )}
                  <Box textStyle='desktop.body.medium.s'>
                    <VisuallyHidden>{translations.aria('thisPageHasTagOf')}: </VisuallyHidden>
                    {mediaContent.tag.value[0]?.name}
                  </Box>
                </Flex>
              </>
            )}

            {/* ---- HEADLINE ---- */}

            <Box alignSelf={{ base: 'unset', lg: 'unset' }}>
              {/* ---- BODY TEXT ---- */}
              {formattedBodyText?.filter(Boolean).map((text, index) => {
                const isHeadline = text.tag.includes('h');
                if (mediaContent.ctaSnippetUrl.value || kontentAiItem)
                  return (
                    <Link href={url}>
                      <Box
                        key={index}
                        aria-label='formated-body-text'
                        textStyle={{ base: 'mobile.body.s', md: 'desktop.body.l' }}
                        w='100%'
                        display='inline-block'
                        sx={{ breakInside: 'avoid' }}
                        _notLast={{
                          pb: {
                            base: 'xs',
                          },
                        }}
                      >
                        <BodyWrapper
                          tag={text.tag}
                          body={text.body.replace('&nbsp;', ' ')}
                          headingColor={headlineColor}
                          isInTextModule={isHeadline}
                        />
                      </Box>
                    </Link>
                  );
                if (mediaContent.formModalCtaFormModal.linkedItems[0] && mediaContent.formModalCtaButtonText.value)
                  return (
                    <>
                      <Box as='button' textAlign='start' onClick={onOpen}>
                        <Box
                          key={index}
                          id='without-snippet-url'
                          aria-label='formated-body-text'
                          textStyle={{ base: 'mobile.body.s', md: 'desktop.body.l' }}
                          w='100%'
                          display='inline-block'
                          sx={{ breakInside: 'avoid' }}
                          _notLast={{
                            pb: {
                              base: 'xs',
                            },
                          }}
                        >
                          <BodyWrapper
                            tag={text.tag}
                            body={text.body.replace('&nbsp;', ' ')}
                            headingColor={headlineColor}
                            isInTextModule={isHeadline}
                          />
                        </Box>
                      </Box>
                    </>
                  );
                return (
                  <>
                    <Box
                      key={index}
                      id='without-snippet-url'
                      aria-label='formated-body-text'
                      textStyle={{ base: 'mobile.body.s', md: 'desktop.body.l' }}
                      w='100%'
                      display='inline-block'
                      sx={{ breakInside: 'avoid' }}
                      _notLast={{
                        pb: {
                          base: 'xs',
                        },
                      }}
                    >
                      <BodyWrapper
                        tag={text.tag}
                        body={text.body.replace('&nbsp;', ' ')}
                        headingColor={headlineColor}
                        isInTextModule={isHeadline}
                      />
                    </Box>
                  </>
                );
              })}

              {/* ---- BODY TEXT ---- */}
              {mediaContent.formModalCtaFormModal.linkedItems[0] && mediaContent.formModalCtaButtonText.value && (
                <>
                  <Flex
                    mt={{ base: 's', md: mediaContent.ctaSnippetStyle.value[0].name === 'link' ? 'xs' : 'xs' }}
                    justifyContent='left'
                  >
                    <Button
                      size='medium'
                      aria-controls={mediaContent.formModalCtaFormModal.linkedItems[0].system.name}
                      onClick={onOpen}
                    >
                      {mediaContent.formModalCtaButtonText.value}
                    </Button>
                  </Flex>
                  <ContactFormModal
                    id={`${model.system.id}${mediaContent.formModalCtaFormModal.linkedItems[0].system.id}`}
                    model={mediaContent.formModalCtaFormModal.linkedItems[0]}
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                </>
              )}
              {/* ---- CTA ---- */}
              <Box>
                {(mediaContent.ctaSnippetUrl.value || kontentAiItem || fileFrontify?.downloadUrl) && (
                  <>
                    {mediaContent.ctaSnippetStyle.value[0]?.codename === 'button' ? (
                      <Flex
                        mt={{ base: 's', md: mediaContent.ctaSnippetStyle.value[0]?.name === 'link' ? 'xs' : 'xs' }}
                        justifyContent='left'
                      >
                        <Button
                          as='a'
                          size={'medium'}
                          href={fileFrontify ? fileFrontify.downloadUrl : url}
                          target={mediaContent.ctaSnippetOpenIn.value[0].codename === 'new_tab' ? '_blank' : ''}
                        >
                          {mediaContent.ctaSnippetCtaText.value}
                          <VisuallyHidden>
                            {translations.aria('about')} {mediaContent.headline.value}
                          </VisuallyHidden>
                        </Button>{' '}
                      </Flex>
                    ) : (
                      <Flex
                        mt={{ base: 's', md: mediaContent.ctaSnippetStyle.value[0]?.name === 'link' ? 'xs' : 'xs' }}
                        justifyContent='left'
                      >
                        <CustomLink
                          href={fileFrontify ? fileFrontify.downloadUrl : url}
                          type={LinkType.SecondaryButton}
                          iconName='chevronRightForLink'
                          colorScheme='mountain.base'
                          isExternal={mediaContent.ctaSnippetOpenIn.value[0]?.codename === 'new_tab'}
                          locale={router.locale}
                        >
                          {mediaContent.ctaSnippetCtaText.value}
                          <VisuallyHidden>
                            {translations.aria('about')} {mediaContent.headline.value}
                          </VisuallyHidden>
                        </CustomLink>{' '}
                      </Flex>
                    )}
                  </>
                )}
              </Box>
              {/* ---- CTA ---- */}
            </Box>
          </Flex>
          {/* ---- TEXT BLOCK END ---- */}

          {/* ----- IMAGE START ----- */}
          <Box
            justifyContent={styles.media.justifyContent}
            alignSelf='center'
            w={{ base: '100%', lg: '60%' }}
            pr={mediaMode === 'start' ? { base: '0', lg: 'l' } : 'unset'}
            pl={mediaMode === 'end' ? { base: '0', lg: 'l' } : 'unset'}
          >
            <AspectRatio w='100%' ratio={16 / 9}>
              {model.elements.video23EmbedLink?.value ? (
                <Box w='100%' id={`video-${model.system.id}`} dangerouslySetInnerHTML={{ __html: video }} />
              ) : model.elements.ctaSnippetUrl.value.length || model.elements.ctaSnippetKontentAiUrl.value.length ? (
                <Link href={fileFrontify ? fileFrontify.downloadUrl : url} id='linked'>
                  <Image
                    sizes='(max-width: 1023) 100vw,(min-width: 1024) 50vw'
                    style={{ alignSelf: 'center', objectFit: 'cover', objectPosition: focalpointvalue }}
                    src={imageData.src}
                    alt={imageData.alt}
                    width={imageData.width}
                    height={imageData.height}
                    quality={compressimage}
                  ></Image>
                </Link>
              ) : mediaContent.formModalCtaFormModal.linkedItems[0] && mediaContent.formModalCtaButtonText.value ? (
                <>
                  <Box as='button' onClick={onOpen}>
                    <Image
                      sizes='(max-width: 1023) 100vw,(min-width: 1024) 50vw'
                      style={{ alignSelf: 'center', objectFit: 'cover', objectPosition: focalpointvalue }}
                      src={imageData.src}
                      alt={imageData.alt}
                      width={imageData.width}
                      height={imageData.height}
                      quality={compressimage}
                    ></Image>
                  </Box>
                </>
              ) : (
                <Image
                  sizes='(max-width: 1023) 100vw,(min-width: 1024) 50vw'
                  style={{ alignSelf: 'center', objectFit: 'cover', objectPosition: focalpointvalue }}
                  src={imageData.src}
                  alt={imageData.alt}
                  width={imageData.width}
                  height={imageData.height}
                  quality={compressimage}
                ></Image>
              )}
            </AspectRatio>
          </Box>
          {/* ----- IMAGE END ----- */}
        </Container>
      </Box>
    </Box>
  );
};
