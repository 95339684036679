import { Box, Container, Grid, GridItem, Text } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { emptyBody, resolveHtml } from 'helpers/htmlResolver';
import React, { FunctionComponent } from 'react';
import { moduleMaxWidth } from 'themes/overrides';

import { getColorScheme } from '../../lib/colorScheme';
import { Factbox as FactboxModel } from '../../models/content-types/factbox';
import { Fact } from './Fact';

interface FactboxProps {
  model: FactboxModel;
}

export const Factbox: FunctionComponent<FactboxProps> = ({ model }) => {
  const facts = model.elements.facts.linkedItems;
  const paddings = model.elements.paddings?.value.map((value) => value.codename);
  const backgroundColor = model.elements.backgroundColor.value[0].codename;
  const headlineColor = model.elements.headlineColor?.value[0]?.codename;
  const headlineNewHtml = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: model.elements.headlineNew,
  }).html;
  const headlineNewInfo = resolveHtml(model.elements.headlineNew.value);

  const colorScheme = getColorScheme(backgroundColor, headlineColor);

  const setPaddings = () => {
    const top = paddings.filter((item) => item.includes('top')).join(',');
    const bottom = paddings.filter((item) => item.includes('bottom')).join(',');
    return {
      pt: top.includes('all') ? 0 : top.includes('half') ? { base: '30px', md: 'm' } : { base: 'm', md: '120px' },
      pb: bottom.includes('all') ? 0 : bottom.includes('half') ? { base: '30px', md: 'm' } : { base: 'm', md: '120px' },
    };
  };

  return (
    <Box id={model.system.codename} w='100vw' maxW={moduleMaxWidth} mx='auto' bg={colorScheme.background}>
      <Container display='flex' flexDir='column' sx={setPaddings()} px={{ base: 'xs', md: 's', lg: 'l' }}>
        {headlineNewHtml !== emptyBody ? (
          <Box
            maxWidth={{ base: '100%', lg: '61%' }}
            textStyle={{ base: `mobile.${headlineNewInfo.tag}`, md: `desktop.${headlineNewInfo.tag}` }}
            dangerouslySetInnerHTML={{ __html: headlineNewHtml }}
            color={colorScheme.headline}
            pb={{ base: 'm', md: 'l' }}
          />
        ) : (
          model.elements.headline.value !== '' && (
            <Text
              as='h2'
              maxWidth={{ base: '100%', lg: '61%' }}
              color={colorScheme.headline}
              textStyle={{ base: 'mobile.h4', md: 'desktop.h4' }}
              pb={{ base: 'm', md: 'l' }}
            >
              {model.elements.headline.value}
            </Text>
          )
        )}
        <Grid
          as='ul'
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          templateRows={{
            base: `repeat(${facts.length}, fit-content)`,
            md: `repeat(${facts.length > 3 ? 0 : 1}, 1fr)`,
          }}
          gap={{ base: 'm', md: 'l' }}
        >
          {facts.map((fact, index) => {
            return (
              <GridItem
                key={index}
                as='li'
                listStyleType='none'
                colStart={!index ? { base: 0, md: facts.length % 2 ? 0 : 2 } : 0}
              >
                <Fact
                  model={fact}
                  backgroundColor={colorScheme.background}
                  accentColor={colorScheme.accent}
                  bodyColor={colorScheme.body}
                />
              </GridItem>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};
