import { Box, Button, Container, Flex, Heading } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { colorScheme } from 'lib/colorScheme';
import Image from 'next/image';
import React, { FunctionComponent, useState } from 'react';
import { imageFormatter } from 'utils/imageFormatter';
import { FormHeroWithAssetData } from 'utils/types/formData';

import { FormHeroWithAsset as FormHeroWithAssetModel } from '../../models/content-types/form_hero_with_asset';
import { FormWrapper } from './FormWrapper';

interface FormHeroWithAssetProps {
  model: FormHeroWithAssetModel;
  useScrollbar?: boolean;
  onSubmit?: () => void;
}

export const FormHeroWithAsset: FunctionComponent<FormHeroWithAssetProps> = ({
  model,
  useScrollbar = true,
  onSubmit,
}) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  const formDataObj = JSON.parse(model.elements.hubspotFormSnippetForm.value);
  const headline = model.elements.headline.value;
  const backgroundSize = model.elements.backgroundSize.value[0].codename;
  const backgroundColor = model.elements.backgroundColor.value;
  const video = model.elements.video23EmbedLink.value.replaceAll('allowfullscreen', '');
  const assetPosition = model.elements.assetPosition.value[0].codename;
  const shouldShowAssetAfterSubmit =
    assetPosition.length && model.elements.showAssetAfterSubmit.value[0].codename === 'yes';
  const downloadItem = model.elements.downloadItem.linkedItems;

  const imgAltText = model.elements.altTextImage?.value;
  const imageData = imageFormatter(model.elements.imageFrontify?.value, imgAltText);
  const thankImgAltText = model.elements.altTextThankyouImage?.value;
  const thankYouImageData = imageFormatter(model.elements.thankYouImageFrontify?.value, thankImgAltText);
  const bgColor = backgroundColor.length
    ? colorScheme[backgroundColor[0].codename as keyof typeof colorScheme].base
    : undefined;

  const data = {
    formId: formDataObj.FormGUID,
    portalId: formDataObj.PortalID,
    headline: headline !== '' ? headline : undefined,
    backgroundSize: backgroundSize,
    backgroundColor: bgColor,
    video,
    image: imageData,
    showAssetAfterSubmit: shouldShowAssetAfterSubmit,
    assetPosition: assetPosition,
    thankYouImage: thankYouImageData,
    downloadItem: downloadItem.length
      ? {
          fileUrl: JSON.parse(downloadItem[0].elements.fileFrontify.value).downloadUrl,
          descriptionHtml: createRichTextHtmlResolver(nodeParser).resolveRichText({
            element: downloadItem[0].elements.description,
          }).html,
          buttonText: downloadItem[0].elements.headline.value,
        }
      : undefined,
    submittedMessageAlignment: shouldShowAssetAfterSubmit ? 'start' : 'center',
    fieldColor: bgColor === 'white' ? 'gray.98' : 'white',
  } as FormHeroWithAssetData;

  return (
    <>
      <Container id={model.system.codename} px='0'>
        <Flex
          py={{ base: '0', lg: '80px' }}
          h={{ base: '', lg: useScrollbar ? '720px' : 'fit-content' }}
          flexDir={{ base: 'column', lg: data.assetPosition === 'left' ? 'row' : 'row-reverse' }}
          bgGradient={{
            base:
              data.backgroundSize == 'partial'
                ? ''
                : `linear(${data.backgroundColor} 100%, ${data.backgroundColor} 100%)`,
            lg:
              data.backgroundSize == 'partial' && data.showAssetAfterSubmit
                ? data.assetPosition === 'left'
                  ? `linear(90deg, white 51%, ${data.backgroundColor} 49%)`
                  : `linear(90deg, ${data.backgroundColor} 51%, white 49%)`
                : `linear(${data.backgroundColor} 100%, ${data.backgroundColor} 100%)`,
          }}
        >
          {/* asset */}
          {(!hasBeenSubmitted || data.showAssetAfterSubmit) && (
            <Box
              h={{ base: '250px', md: '350px', lg: '484px' }}
              w={{ base: '100%', lg: '60%' }}
              my={useScrollbar ? 'auto' : ''}
              position={{ base: 'static', lg: useScrollbar ? 'static' : 'sticky' }}
              top={0}
            >
              {data.image ? (
                <Image
                  width={data.image.width}
                  height={data.image.height}
                  src={data.image.src}
                  alt={data.image.alt}
                  style={{ objectFit: 'cover' }}
                />
              ) : data.video ? (
                <Box id={`video-${model.system.id}`} dangerouslySetInnerHTML={{ __html: video }} />
              ) : (
                <>No asset specified!</>
              )}
            </Box>
          )}

          {/* form */}
          <Flex
            w={{ base: '100%', lg: !hasBeenSubmitted || data.showAssetAfterSubmit ? '40%' : '100%' }}
            px={{ base: '', lg: 'l' }}
            mt={{ base: 's', lg: '0' }}
            flexDir='column'
            alignItems='center'
            justifyContent={'space-between'}
          >
            <Box
              pr='xs'
              pl={{ base: 'xs', lg: '0' }}
              pb='3xs'
              overflowY={'auto'}
              sx={{
                '::-webkit-scrollbar': {
                  bg: 'mountain.40',
                  width: '3px',
                  height: '3px',
                },
                '::-webkit-scrollbar-thumb': {
                  bg: 'cyan.web',
                },
                '::-webkit-scrollbar-track': {
                  width: '3px',
                  height: '3px',
                },
              }}
              h={useScrollbar ? '100%' : 'fit-content'}
            >
              {hasBeenSubmitted && data.thankYouImage && !data.showAssetAfterSubmit && (
                <Box my='l' w='fit-content' h='fit-content' mx='auto'>
                  <Image
                    width={60}
                    height={70}
                    src={data.thankYouImage.src}
                    alt={data.thankYouImage.alt}
                    style={{ objectFit: 'cover' }}
                  />
                </Box>
              )}
              {data.headline && !hasBeenSubmitted && (
                <Heading
                  mb={{ base: 'xs', lg: 's' }}
                  as='h2'
                  h={'fit-content'}
                  textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }}
                  color={{ base: 'cyan.web', lg: 'mountain.base' }}
                >
                  {data.headline}
                </Heading>
              )}
              <Flex h={hasBeenSubmitted ? '100%' : ''} alignItems={data.showAssetAfterSubmit ? 'start' : 'center'}>
                <FormWrapper
                  data={data}
                  onSubmit={() => {
                    setHasBeenSubmitted(true);
                    if (onSubmit) {
                      onSubmit();
                    }
                  }}
                />
              </Flex>
            </Box>
            {hasBeenSubmitted && data.downloadItem && (
              <Flex
                mx={{ base: 'xs', lg: '0px' }}
                mt={{ base: 's', lg: 'xl' }}
                flexDir='column'
                gap='30px'
                alignItems={!data.showAssetAfterSubmit ? 'center' : 'start'}
              >
                <Box
                  textStyle='desktop.body.light.s'
                  color='mountain.base'
                  dangerouslySetInnerHTML={{ __html: data.downloadItem.descriptionHtml }}
                />
                <Button
                  maxW='300px'
                  whiteSpace='normal'
                  wordBreak='break-word'
                  as='a'
                  size='medium'
                  href={data.downloadItem.fileUrl}
                  download={data.downloadItem.fileUrl}
                  borderRadius='4px'
                >
                  {data.downloadItem.buttonText}
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Container>
    </>
  );
};
