import { Box, Container, Flex, Grid, GridItem, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { ImageWrapper } from 'components/wrapper/imageWrapper';
import { Contact } from 'models';
import React, { FunctionComponent, useState } from 'react';
import { withTranslations } from 'store/translations';

import { colorScheme } from '../../lib/colorScheme';
import { ContactPersonBlock as ContactPersonBlockModel } from '../../models/content-types/contact_person_block';
import { getButtonWithLinkStyling } from '../base/Button';
import { ContactFormModal } from './ContactFormModal';
interface ContactPersonBlockProps {
  model: ContactPersonBlockModel;
}

export const ContactPersonBlock: FunctionComponent<ContactPersonBlockProps> = ({ model }) => {
  const { aria } = withTranslations();
  const backgroundColor =
    colorScheme[model.elements.backgroundColor?.value[0].codename as keyof typeof colorScheme]?.base ?? 'white';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [contactPerson, setContactPerson] = useState<Contact>();
  const people = model.elements.contact.linkedItems;
  return (
    <Container
      id={model.system.codename}
      bg={backgroundColor}
      display='flex'
      m='auto'
      maxWidth='780px'
      w={{ base: 'calc(100% - 10px)', md: '100%' }}
      py='s'
      px={{ base: 'xs', md: people?.length === 1 ? '207px' : 's' }}
      flexDir='column'
      gap='s'
    >
      <Text
        as='h2'
        w='100%'
        textAlign={{ base: 'center', md: people?.length === 1 ? 'center' : 'start' }}
        textStyle={{ base: 'desktop.body.book.l', md: 'desktop.h5' }}
      >
        {model.elements.headline.value}
      </Text>
      <Grid
        as='ul'
        columnGap='l'
        rowGap='s'
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: `repeat(${people && people?.length % 2 === 0 ? 2 : people?.length}, 1fr)`,
        }}
      >
        {people?.map((person, index) => (
          <GridItem
            key={index}
            m='auto'
            w='fit-content'
            as='li'
            margin={{ base: 'auto', md: 'unset' }}
            display='flex'
            justifyContent='start'
            alignItems={{ base: 'center', md: 'unset' }}
            textAlign={{ base: 'center', md: 'unset' }}
            flexDir={{ base: 'column-reverse', md: people?.length === 3 ? 'column-reverse' : 'row-reverse' }}
            gap={people?.length === 1 ? 's' : 'xs'}
          >
            <Flex flexDir='column' alignItems={{ base: 'center', md: 'unset' }}>
              <Text wordBreak='break-word' textStyle='mobile.body.medium.s'>
                {person.elements.displayName.value}
              </Text>
              <Text textStyle='mobile.body.s'>{person.elements.jobTitle.value}</Text>
              <Text textStyle='mobile.body.s'>{person.elements.phoneNumber.value}</Text>
              {model.elements.formModalCtaFormModal.linkedItems[0] &&
                getButtonWithLinkStyling(
                  `${aria('openContactGetInTouchWith')} ${person.elements.displayName.value}`,
                  model.elements.formModalCtaButtonText.value,
                  () => {
                    setContactPerson(person);
                    onOpen();
                  },
                  model.elements.formModalCtaFormModal.linkedItems[0].system.name,
                )}
            </Flex>
            <Box w={people?.length > 1 ? '80px' : '160px'}>
              <ImageWrapper
                avatarSize={people?.length > 1 ? { base: 'xl', lg: 'xl' } : { base: '3xl', lg: '3xl' }}
                imageSx={
                  people?.length > 1
                    ? { w: { base: '80px', lg: '80px' }, h: { base: '80px', lg: '80px' } }
                    : { w: { base: '160px', lg: '160px' }, h: { base: '160px', lg: '160px' } }
                }
                imageFrontify={person.elements.frontifyImage.value}
                msImage={person.elements.msImage.value}
                alt={person.elements.displayName.value}
              />
            </Box>
          </GridItem>
        ))}
        {!people && (
          <HStack justifyContent={'space-between'} gap='l'>
            {model.elements.contact.linkedItems.map((item, index) => {
              return <Text key={index}>{item.system.name}</Text>;
            })}
          </HStack>
        )}
      </Grid>
      {model.elements.formModalCtaFormModal.linkedItems[0] && (
        <ContactFormModal
          id={`${model.system.id}${model.elements.formModalCtaFormModal.linkedItems[0].system.id}${contactPerson?.system.id}`}
          model={model.elements.formModalCtaFormModal.linkedItems[0]}
          isOpen={isOpen}
          contactPersonEmail={contactPerson?.elements.email.value}
          onClose={onClose}
        />
      )}
    </Container>
  );
};
