import { Center, Container, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';

import { Team as TeamModel } from '../../models';
import { ContactGroupItem } from './ContactGroupItem';

interface TeamProps {
  model: TeamModel;
}

export const Team: FunctionComponent<TeamProps> = ({ model }) => {
  const { common } = withTranslations();
  const contactItems = model.elements?.contactItems?.linkedItems;
  return (
    <>
      <Container pb={{ base: 's', lg: 'm' }} pt='xs'>
        <Text
          as='h2'
          color='mountain.base'
          textStyle={{ base: `mobile.h4`, md: `desktop.h4` }}
          textAlign={'left'}
          pb='xl'
        >
          {model.elements?.heading?.value}
        </Text>
        <Flex
          align='center'
          direction={{ base: 'column', lg: 'row' }}
          gap={{ base: 'xs', lg: '0' }}
          mb={{ base: 's', lg: 'm' }}
        >
          {contactItems?.length ? (
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              //   spacingX={{ base: 0, md: '10' }}
              // spacingY={{ base: 's', lg: 'xl' }}
              pb={{ lg: 'lr' }}
              spacingX='130px'
              spacingY='50px'
            >
              {contactItems.map((person) => {
                return <ContactGroupItem model={person} />;
              })}
            </SimpleGrid>
          ) : (
            <Center>
              <Text color='mountain.base' textStyle={{ base: 'mobile.body.medium.s', lg: 'desktop.body.medium.l' }}>
                {common('noResultsFound')}
              </Text>
            </Center>
          )}
        </Flex>
      </Container>
    </>
  );
};
