import { Breadcrumb as ChakraBreadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useCookies } from 'react-cookie';
import { withTranslations } from 'store/translations';

import { toTitleCase } from '../../helpers/textCase';
import { IconComp } from './IconComp';

export type BackLink = {
  href: string;
  label: string;
};

interface BreadcrumbProps {
  customLink?: BackLink;
  name?: string;
  color?: string;
  onClick?: () => void;
}

export default function Breadcrumb({ customLink, name, color, onClick }: BreadcrumbProps): JSX.Element {
  const { common } = withTranslations();
  const router = useRouter();
  const [cookie] = useCookies(['breadcrumb_name']);
  const breadcrumbPath =
    router.asPath.split('/') &&
    router.asPath.split('/')[router.asPath.split('/').length - 1] !== undefined &&
    router.asPath.split('/')[1] != router.asPath.split('/')[router.asPath.split('/').length - 2]
      ? `${router.asPath.split('/')[1]}/${router.asPath.split('/')[router.asPath.split('/').length - 2]}`
      : `${router.asPath.split('/')[1]}`;
  //cacheData.get("BreadcrumbType") !== undefined  ? cacheData.get("BreadcrumbType") :
  const breadcrumbName = router.asPath.split('/')[2]
    ? toTitleCase(router.asPath.split('/')[router.asPath.split('/').length - 2])
        .replaceAll(/_+/g, ' ')
        .replaceAll(/-+/g, ' ')
    : '';
  const childUrl = `/${router.query.subcategory}`;

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <ChakraBreadcrumb w='fit-content' h='fit-content' onClick={onClick}>
      <BreadcrumbItem
        data-group
        color={color}
        textDecoration='underline transparent'
        textUnderlineOffset='3px'
        py='xxs'
        pr='xxs'
        _hover={{ cursor: 'pointer', textDecorationColor: color }}
      >
        <IconComp iconName='chevronLeft' chakraStyle={{ path: { stroke: color } }} />
        {onClick ? (
          <Text
            textDecoration='inherit'
            pl='xxs'
            textStyle='desktop.body.medium.s'
            transition={'all .75s ease-in-out'}
            _groupHover={{
              transform: 'translateX(-10px)',
            }}
          >
            {name}
          </Text>
        ) : (
          <BreadcrumbLink
            as={NextLink}
            href={
              customLink
                ? customLink.href
                : breadcrumbPath.length && breadcrumbName?.length
                ? `${
                    router.locale === process.env.NEXT_PUBLIC_DEFAULT_LOCALE! ? '/' : `/${router.locale}/`
                  }${breadcrumbPath}`
                : router.query.subcategory
                ? childUrl
                : '/'
            }
            textDecoration='inherit'
            pl='xxs'
            textStyle='desktop.body.medium.s'
            transition={'all .75s ease-in-out'}
            _groupHover={{
              transform: 'translateX(-10px)',
            }}
          >
            {name && name?.length > 0
              ? name
              : customLink
              ? customLink.label
              : // : cookie['breadcrumb_name']
              // ? cookie['breadcrumb_name'].replaceAll('-', ' ')
              breadcrumbName.length
              ? router.query.id && cookie['breadcrumb_name']?.length
                ? cookie['breadcrumb_name']
                : breadcrumbName
              : capitalizeFirstLetter(common('homepage'))}
          </BreadcrumbLink>
        )}
      </BreadcrumbItem>
    </ChakraBreadcrumb>
  );
}
