import { AspectRatio, Box, Container, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { ShareButton } from 'components/base/ShareButton';
import { motion, useInView } from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useRef } from 'react';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { getLocalizedDate } from 'utils/getLocalizedDate';
import { imageFormatter } from 'utils/imageFormatter';
import { hrefLink } from 'utils/normalization/hrefLink';
import { stripTags } from 'utils/stripTags';

import { colorScheme } from '../../lib/colorScheme';
import { ArticleHero as ArticleHeroModel } from '../../models';
import Breadcrumb, { BackLink } from '../base/Breadcrumb';
import { CustomTag } from '../base/CustomTag';
import { HyphenResult } from '../hyphen/hyphenation';

interface ArticleHeroProps {
  model: ArticleHeroModel;
  customBreadcrumb?: BackLink;
}

export const ArticleHero: FunctionComponent<ArticleHeroProps> = ({ model, customBreadcrumb }) => {
  const ref = useRef<HTMLImageElement>(null);
  const isInView = useInView(ref, {
    once: true,
  });

  const translations = withTranslations();
  const router = useRouter();
  // const hyphenate = require(`hyphen/${router.locale}`);
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  const altText = model.elements.alternativeText.value;
  const compressimage = model.elements.compressImage.value ? model.elements.compressImage.value : 75;

  const imageData =
    slug == 'news' || slug == 'nyheder' || slug == 'uutiset' || slug == 'nyheter'
      ? imageFormatter(model.elements.imageFrontify?.value, altText, true)
      : imageFormatter(model.elements.imageFrontify?.value, altText);
  const data = {
    authors: model.elements.authors.value,
    date: model.elements.date.value && getLocalizedDate(model.elements.date.value, router.locale),
    textHtml: createRichTextHtmlResolver(nodeParser).resolveRichText({
      element: model.elements.body,
    }).html,
    headline: model.elements.headline.value,
    hyphenation: model.elements.enableHyphenation?.value[0]?.codename,
    tagText: translations.theme_topic(model.elements.tag.value[0]?.codename),
    colorScheme: colorScheme[model.elements.backgroundColor.value[0].codename as keyof typeof colorScheme],
  };

  const breadcrumbOverride = model.elements.breadcrumbOverride.linkedItems.length
    ? ({
        href: hrefLink(
          model.elements.breadcrumbOverride.linkedItems[0],
          (slug as unknown as string)?.split('/'),
          true,
          router.locale,
          translations,
        ),
        label: model.elements.breadcrumbOverride.linkedItems[0].elements.summaryTitle.value,
      } as BackLink)
    : customBreadcrumb != undefined
    ? customBreadcrumb
    : undefined;
  const parsedCaption = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: model.elements.caption,
  });
  const caption = stripTags(parsedCaption.html).replace(/&amp;/g, '&');
  const shouldShowCaption = caption.length > 0;
  const headline = HyphenResult(data.headline, router.locale);
  const desc = HyphenResult(data.textHtml, router.locale);

  return (
    <Box
      id={model.system.codename}
      w='100vw'
      maxW={moduleMaxWidth}
      bgGradient={{
        base: shouldShowCaption
          ? `linear(${data.colorScheme.base} 77%, white 15.3%)`
          : `linear(${data.colorScheme.base} 84.7%, white 15.3%)`,
        md: `linear(${data.colorScheme.base} 87%, white 13%)`,
      }}
      mx='auto'
    >
      <Container pt={{ base: 's', md: 'm' }} pb={{ base: 'xs', md: '24px' }} px={{ base: 'xs', md: 'l' }}>
        <Flex flexDir='column'>
          <Breadcrumb color='mountain.base' customLink={breadcrumbOverride} />

          {/* --------- HEADLINES AND TITLES ---------  */}
          <Flex flexDir='column' mt={{ base: 's', md: 'm' }} w={{ base: '100%', md: '61%' }} alignSelf='center'>
            {data.tagText && (
              <CustomTag
                backgroundColor={data.colorScheme.baseAccent}
                textColor={data.colorScheme.accent}
                text={data.tagText}
              />
            )}
            {data.authors !== '' && (
              <Text
                aria-label={`${translations.aria('namesOfArticleAuthors')}: ${data.authors}`}
                mt='xs'
                textStyle={'mobile.body.medium.s'}
              >
                {data.authors}
              </Text>
            )}
            {data.date && (
              <Text aria-label={`${translations.aria('articleDate')}: ${data.date}`} textStyle={'mobile.body.s'}>
                {data.date}
              </Text>
            )}
            {data.headline !== '' && (
              <Text as='h1' mt={{ base: 's', md: 'm' }} textStyle={{ base: 'mobile.h4', md: 'desktop.h4' }}>
                {data.hyphenation == 'yes' ? headline : data.headline}
              </Text>
            )}
            <Box
              mt='xs'
              textStyle={{ base: 'mobile.body.s', md: 'desktop.body.l' }}
              dangerouslySetInnerHTML={{ __html: data.hyphenation == 'yes' ? desc : data.textHtml }}
            />
          </Flex>
          {/* --------- HEADLINES AND TITLES END ---------  */}

          {/* --------- IMAGE AND SHARE BUTTON ---------  */}
          {imageData.src && (
            <AspectRatio ratio={16 / 9} maxW='1276px' overflow='hidden' mt={{ base: 's', md: 'l' }}>
              <motion.div
                ref={ref}
                initial={{ scale: 0.61 }}
                animate={{ scale: isInView ? 1 : 0.61 }}
                transition={{ duration: 1 }}
              >
                <Image
                  src={imageData.src}
                  width={imageData.width}
                  height={imageData.height}
                  alt={imageData.alt}
                  quality={compressimage}
                />
              </motion.div>
            </AspectRatio>
          )}
          {imageData.src && (
            <Grid templateColumns='repeat(5, 1fr)' gap={4}>
              <GridItem colSpan={5}>
                {shouldShowCaption && (
                  <Box as='figcaption' mt='xs'>
                    {caption}
                  </Box>
                )}
              </GridItem>
              <GridItem colStart={6} colEnd={6}>
                <Box w='fit-content' mt='xs' alignItems={'end'}>
                  <ShareButton />
                </Box>
              </GridItem>
            </Grid>
          )}

          {/* --------- IMAGE AND SHARE BUTTON END ---------  */}
        </Flex>
      </Container>
    </Box>
  );
};
