import { Box, FormControl, FormErrorMessage, FormLabel, Select } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { Field } from 'formik';
import { DropdownFormField } from 'models';
import React, { FunctionComponent } from 'react';

import { getLabelName, validatCheckField } from '../../utils/form/validation';

interface DropdownFieldProps {
  model: DropdownFormField;
}

export const DropdownField: FunctionComponent<DropdownFieldProps> = ({ model }) => {
  return (
    <Field as={Select} name={model.system.codename} validate={validatCheckField(model)}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[model.system.codename] && form.touched[model.system.codename]}>
          {model.elements.label.value !== '' && <FormLabel>{getLabelName(model)}</FormLabel>}
          <Select {...field}>
            {model.elements.dropdownOptions.linkedItems.map((item) => {
              const labelHtml = createRichTextHtmlResolver(nodeParser).resolveRichText({
                element: item.elements.label,
              }).html;
              return (
                <option key={item.system.name} value={item.system.name}>
                  <Box
                    textStyle={'desktop.body.s'}
                    sx={{
                      a: {
                        textDecor: 'underline',
                      },
                    }}
                    dangerouslySetInnerHTML={{ __html: labelHtml }}
                  />
                </option>
              );
            })}
          </Select>
          <FormErrorMessage>{form.errors[model.system.codename]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
