import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Text } from '@chakra-ui/react';
import { IconComp } from 'components/base/IconComp';
import { FileInfo, UPLOAD_FORM_FIELD_KEY } from 'components/modules/CustomForm';
import { Field } from 'formik';
import { InputFormField } from 'models';
import React, { ChangeEvent, FunctionComponent, useCallback, useState } from 'react';
import { withTranslations } from 'store/translations';
import { FieldType, getFormFieldType } from 'utils/form/fieldType';
import { getLabelName, isUploadFieldInvalid, validateUploadField } from 'utils/form/validation';

interface UploadFieldProps {
  model: InputFormField;
  files: FileInfo[];
  setFiles: (files: FileInfo[]) => void;
}

export const UploadField: FunctionComponent<UploadFieldProps> = ({ model, files, setFiles }) => {
  const { common } = withTranslations();
  const [filesizeAlert, setFilesizeAlert] = useState('');
  const [fileValidAlert, setFileValidAlert] = useState(
    'Each file must be under 100MB and in one of the following formats: ".doc", ".docx", ".pdf", ".txt",".rtf", ".jpg", ".jpeg", ".tif", ".xlsx" or ".png".',
  );
  const [isValid, setIsValid] = useState(true);

  const fileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const filesCopy = [...files];

      if (event.target.files) {
        const filesArr = Array.from(event.target.files);
        filesArr.forEach((file) => {
          file.size > 104857600 ? setFilesizeAlert('File size should not be greater than 100MB') : setFilesizeAlert('');
          const fileInfo = {
            fieldName: UPLOAD_FORM_FIELD_KEY + model.system.codename,
            fileName: file.name,
            file: file,
          } as FileInfo;
          const isFileValid =
            file.type.includes('pdf') ||
            file.type.includes('text') ||
            file.type.includes('doc') ||
            file.type.includes('docx') ||
            file.type.includes('png') ||
            file.type.includes('rtf') ||
            file.type.includes('tif') ||
            file.type.includes('jpg') ||
            file.type.includes('jpeg');
          if (file.size > 104857600) {
            setFileValidAlert(`${file.name} - File size should not be greater than 20MB`);
            setIsValid(false);
          } else if (!isFileValid) {
            setIsValid(false);
            setFileValidAlert(`Please upload only pdf/docx/doc/txt/jpg/jpeg/tif/rtf/png/xlsx files`);
          } else {
            setIsValid(true);
            setFilesizeAlert('');
          }
          if (!filesCopy.some((f) => JSON.stringify(f) === JSON.stringify(fileInfo))) {
            const fieldType = getFormFieldType(model);
            if (
              fieldType === FieldType.UploadMultiple ||
              (fieldType === FieldType.UploadSingle &&
                !filesCopy.some((f) => f.fieldName === fileInfo.fieldName) &&
                file)
            ) {
              // add if multiple upload or single upload empty
              if (isFileValid) {
                filesCopy.push(fileInfo);
              }
            } else {
              // replace if single upload has some file
              const idx = filesCopy.findIndex((f) => f.fieldName === fileInfo.fieldName);
              if (isFileValid) {
                filesCopy.splice(idx, 1, fileInfo);
              } else {
                filesCopy.splice(idx, 1);
              }
            }
          }
        });
      }

      setFiles(filesCopy);
    },
    [files, model, setFiles],
  );

  return (
    <Box>
      <Field name={UPLOAD_FORM_FIELD_KEY + model.system.codename} validate={validateUploadField(files, model)}>
        {({ field, form }) => (
          <FormControl
            isInvalid={
              form.touched[UPLOAD_FORM_FIELD_KEY + model.system.codename] && isUploadFieldInvalid(files, model)
            }
          >
            {model.elements.label.value !== '' && <FormLabel>{getLabelName(model)}</FormLabel>}
            <Box data-group position={'relative'}>
              <Input
                position='absolute'
                top={0}
                left={0}
                {...field}
                opacity='0'
                zIndex={10}
                type='file'
                multiple={model.elements.type.value[0].codename === 'upload_multiple'}
                accept={model.elements.allowedTypesForUploadFiles.value}
                onChange={(event) => fileChange(event)}
              />
              <Flex
                bg='gray.red'
                py='xxs'
                pr='15px'
                pl='xxs'
                alignItems='center'
                borderColor={
                  form.touched[UPLOAD_FORM_FIELD_KEY + model.system.codename] && isUploadFieldInvalid(files, model)
                    ? 'field'
                    : 'mountain.20'
                }
                borderWidth={
                  form.touched[UPLOAD_FORM_FIELD_KEY + model.system.codename] && isUploadFieldInvalid(files, model)
                    ? '2px'
                    : '1px'
                }
                justifyContent='space-between'
                _groupFocusWithin={{
                  borderColor: 'cyan.web',
                  borderWidth: '2px',
                }}
              >
                <Text
                  _groupHover={{
                    color: 'mountain.base',
                  }}
                  color='mountain.60'
                  bg='gray.red'
                  textStyle='mobile.body.s'
                >
                  {common('chooseFile')}
                </Text>
                <IconComp iconName={'upload'} />
              </Flex>
            </Box>
            {files.filter((file) => file.fieldName === UPLOAD_FORM_FIELD_KEY + model.system.codename).length > 0 && (
              <Flex mt='xxs' flexDir='column' gap='xxs'>
                {files
                  .filter((file) => file.fieldName === UPLOAD_FORM_FIELD_KEY + model.system.codename)
                  .map((file, index) => {
                    return (
                      <>
                        {file.file.size >= 104857600 ? (
                          <Text className='chakra-form__error-message'>{filesizeAlert}</Text>
                        ) : (
                          <Flex
                            key={index}
                            py='xxs'
                            pr='15px'
                            pl='xxs'
                            bg='gray.red'
                            alignItems='center'
                            borderColor={'mountain.20'}
                            borderWidth='1px'
                            justifyContent='space-between'
                          >
                            <Text color='mountain.60' bg='gray.red' textStyle='mobile.body.s'>
                              {file.fileName}
                            </Text>
                            <Box
                              as='button'
                              type='button'
                              onClick={() => {
                                const filesCopy = [...files];
                                const idx = filesCopy.findIndex((f) => JSON.stringify(f) === JSON.stringify(file));
                                if (idx >= 0) {
                                  filesCopy.splice(idx, 1);
                                  setFiles(filesCopy);
                                }
                              }}
                            >
                              <IconComp iconName={'trash'} />
                            </Box>
                          </Flex>
                        )}
                      </>
                    );
                  })}
              </Flex>
            )}
            {!isValid && (
              <Text className='chakra-form__error-message' color={'#E53E3E'}>
                {fileValidAlert}
              </Text>
            )}
            <FormHelperText style={{ fontWeight: 'bold' }} textStyle='desktop.body.medium.l'>
              Each file must be under 100MB and in one of the following formats: ".doc", ".docx", ".pdf", ".txt",".rtf",
              ".jpg", ".jpeg", ".tif", ".xlsx" or ".png".
            </FormHelperText>
            <FormErrorMessage>
              {form.errors[UPLOAD_FORM_FIELD_KEY + model.system.codename] ??
                `${model.elements.name.value} ${common('isRequired')}`}
            </FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </Box>
  );
};
