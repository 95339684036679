import { AspectRatio, Box, Container } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { motion, useInView } from 'framer-motion';
import Image from 'next/image';
import React, { FunctionComponent, useRef } from 'react';
import { imageFormatter } from 'utils/imageFormatter';
import { stripTags } from 'utils/stripTags';

import { ImageVisualBreaker as ImageVisualBreakerModel } from '../../models';

interface ImageVisualBreakerProps {
  model: ImageVisualBreakerModel;
}

const headings = ['h2', 'h3', 'h4'];
const MotionBox = motion(Box);

export const ImageVisualBreaker: FunctionComponent<ImageVisualBreakerProps> = ({ model }) => {
  const imgAltText = model.elements.alternativeText?.value;
  const compressimage = model.elements.compressImage.value ? model.elements.compressImage.value : 75;
  const imageData = imageFormatter(model.elements.mediaFrontify?.value, imgAltText);
  const headline = model.elements.headline.value.includes('<')
    ? model.elements.headline.value.split('>')[1].split('<')[0]
    : model.elements.headline.value;
  const heading = model.elements.headline.value.substring(1, 3);
  const shouldShowHeadline = headings.includes(heading);
  const parsedCaption = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: model.elements.caption,
  });

  const caption = stripTags(parsedCaption.html).replace(/&amp;/g, '&');

  const shouldShowCaption = caption.length > 0;
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: '-25% 25%',
    once: true,
  });

  const paddings = model.elements.paddings?.value.map((value) => value.codename);

  const setPaddings = () => {
    const top = paddings.filter((item) => item.includes('top')).join(',');
    const bottom = paddings.filter((item) => item.includes('bottom')).join(',');
    return {
      pt: top.includes('all') ? 0 : top.includes('half') ? { base: 'xs', lg: 's' } : { base: 's', lg: 'l' },
      pb: bottom.includes('all') ? 0 : bottom.includes('half') ? { base: 'xs', lg: 's' } : { base: 's', lg: 'l' },
    };
  };

  return (
    <Container id={model.system.codename} px='0' w='100vw'>
      <Box as='figure' sx={setPaddings()} px={{ base: 'xs', lg: 'l' }} gap={{ base: 'xs', lg: 's' }} w='100%'>
        {shouldShowHeadline && (
          <Box mb={{ base: 'xs', md: '50px' }}>
            <Box
              suppressHydrationWarning
              as='h2'
              color={model.elements.headlineColor.value[0]?.codename === 'cyan' ? 'cyan.web' : 'mountain.base'}
              textStyle={{ base: `mobile.${heading}`, md: `desktop.${heading}` }}
              alignSelf={model.elements.headlinePosition?.value[0]?.codename}
              textAlign={model.elements.headlinePosition?.value[0]?.codename === 'left' ? 'start' : 'center'}
              maxWidth={{ base: '100%', lg: '70%' }}
              dangerouslySetInnerHTML={{ __html: headline }}
            />
          </Box>
        )}
        <MotionBox
          ref={ref}
          alignSelf='center'
          w='100%'
          initial={{ scale: 0.61 }}
          style={{ scale: isInView ? 1 : 0.61 }}
          transition='ease-out'
          transitionDuration='0.75s'
          overflow='hidden'
        >
          <AspectRatio ratio={16 / 9}>
            <Image
              src={imageData.src}
              style={{ objectFit: 'cover', maxHeight: '720px' }}
              alt={imageData.alt}
              width={imageData.width}
              height={imageData.height}
              quality={compressimage}
            />
          </AspectRatio>
        </MotionBox>
        {shouldShowCaption && <Box as='figcaption'>{caption}</Box>}
      </Box>
    </Container>
  );
};
