import { Box } from '@chakra-ui/react';
import React from 'react';

interface TabsProps {
  disabled?: boolean;
  active?: boolean;
  text: string;
  ariaControls: string;
  onClick?: () => void;
}

export const Tab = ({ disabled, text, active, ariaControls, onClick }: TabsProps) => {
  return (
    <Box
      aria-controls={ariaControls}
      as='button'
      display='inline-block'
      pb='xxs'
      px='xxs'
      w='100%'
      whiteSpace='nowrap'
      textStyle='desktop.body.book.l'
      textAlign='center'
      borderBottom={`${active ? 3 : 0}px solid`}
      borderColor={active ? 'cyan.web' : 'mountain.20'}
      color={active ? 'cyan.web' : disabled ? 'mountain.40' : 'mountain.base'}
      onClick={onClick}
    >
      {text}
    </Box>
  );
};
