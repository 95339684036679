import { Box, FormControl, FormErrorMessage, FormLabel, Radio, RadioGroup } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { Field } from 'formik';
import { RadioFormField } from 'models';
import React, { FunctionComponent } from 'react';

import { getLabelName, validatCheckField } from '../../utils/form/validation';

interface RadioFieldProps {
  model: RadioFormField;
}

export const RadioField: FunctionComponent<RadioFieldProps> = ({ model }) => {
  return (
    <Field name={model.system.codename} validate={validatCheckField(model)}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[model.system.codename] && form.touched[model.system.codename]}>
          {model.elements.label.value !== '' && <FormLabel>{getLabelName(model)}</FormLabel>}
          <RadioGroup display='flex' flexDir='column' gap='30px' {...field}>
            {model.elements.options.linkedItems.map((option, index) => {
              const html = createRichTextHtmlResolver(nodeParser).resolveRichText({
                element: option.elements.label,
              }).html;
              //html.replace(/<\/?[^>]+(>|$)/g, "")
              return (
                <Radio {...field} key={index} value={html.replace(/<\/?[^>]+(>|$)/g, '')}>
                  <Box dangerouslySetInnerHTML={{ __html: html }} />
                </Radio>
              );
            })}
          </RadioGroup>
          <FormErrorMessage>{form.errors[model.system.codename]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
