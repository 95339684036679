import { AspectRatio, Box, Button, Collapse, Flex, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { IconComp } from 'components/base/IconComp';
import { Link } from 'components/base/Link';
import { emptyBody, resolveHtml } from 'helpers/htmlResolver';
import { LinkType } from 'lib/enums/LinkType';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { imageFormatter } from 'utils/imageFormatter';
import { hrefLink } from 'utils/normalization/hrefLink';
import { getElementPosition } from 'utils/viewport';

import { getColorScheme } from '../../lib/colorScheme';
import { ColorBlockHero as ColorBlockHeroModel } from '../../models';
import Breadcrumb, { BackLink } from '../base/Breadcrumb';
import { ContactFormModal } from './ContactFormModal';

type ColorBlockHeroProps = {
  backLink?: BackLink;
  model: ColorBlockHeroModel;
  customBreadcrumb?: BackLink;
};
import { HyphenResult } from '../hyphen/hyphenation';
export const ColorBlockHero = ({ backLink, model, customBreadcrumb }: ColorBlockHeroProps) => {
  // ---{ Start logic for scrolling Engage button styles}---
  const [isSmallScreen] = useMediaQuery('(max-width: 1024px)');
  const containerRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLButtonElement>(null);
  // const [ctaRightPosition, setCtaRightPosition] = useState<number>(0);
  const [shouldShowCtaText, setShouldShowCtaText] = useState(true);
  const [ctaPosition, setCtaPosition] = useState('absolute');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const translations = withTranslations();
  const router = useRouter();
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  const url = hrefLink(model, (slug as unknown as string)?.split('/'), false, router.locale, translations);
  // let result = HyphenResult(model.elements.headline.value, router.locale)
  // setHeadline(result)
  useEffect(() => {
    if (!data.showEngageWithUs) return;

    // const containerStyle = window.getComputedStyle(containerRef.current as Element);
    // setCtaRightPosition(parseInt(containerStyle.marginRight) + parseInt(containerStyle.paddingRight));

    const options = {
      root: null, // the viewport
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && isSmallScreen) {
          setCtaPosition('fixed');
        }
      });
    }, options);

    if (ctaRef.current) {
      observer.observe(ctaRef.current);
    }

    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let isTicking = false;

    // const updateCTAPosition = () => {
    //   window.onresize = () => {
    //     const containerStyle = window.getComputedStyle(containerRef.current as Element);
    //     setCtaRightPosition(parseInt(containerStyle.marginRight) + parseInt(containerStyle.paddingRight));
    //   };
    // };

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;
      const ctaTopPosition = getElementPosition(ctaRef)?.offsetY;

      if (ctaTopPosition && window.innerHeight - ctaTopPosition > scrollY) setCtaPosition('absolute');
      else setCtaPosition('fixed');

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        isTicking = false;
        return;
      }
      setShouldShowCtaText(scrollY < lastScrollY);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      isTicking = false;
    };

    const onResize = () => {
      if (!isTicking) {
        // window.requestAnimationFrame(updateCTAPosition);
        window.requestAnimationFrame(updateScrollDir);
        isTicking = true;
      }
    };
    const onScroll = () => {
      if (!isTicking) {
        window.requestAnimationFrame(updateScrollDir);
        isTicking = true;
      }
    };

    window.addEventListener('scroll', onScroll);
    window.addEventListener('touchmove', onScroll, false);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('scroll', onScroll, false);
      window.removeEventListener('touchmove', onScroll, false);
      window.removeEventListener('resize', onResize);
    };
  }, [shouldShowCtaText]);
  // ---{ End logic for scrolling Engage button styles}---

  const altText = model.elements.alternativeText.value;
  const compressimage = model.elements.compressImage.value ? model.elements.compressImage.value : 75;
  const imageData = imageFormatter(model.elements.imageFrontify?.value, altText);
  const video = model.elements.video23EmbedLink?.value.replaceAll('allowfullscreen', '');
  const headlineHtml = resolveHtml(model.elements.headlineNew.value);
  const fileFrontify = JSON.parse(model.elements.ctaSnippetFrontifyFile.value);
  // console.log('headline new ',model.elements.headlineNew.value)
  // console.log('headlineHtml ',headlineHtml)
  const data = {
    headline: model.elements.headlineNew.value !== emptyBody ? headlineHtml.body : model.elements.headline.value,
    headlineTag: model.elements.headlineNew.value !== emptyBody ? 'h1' : 'h1',
    bodyHtml: createRichTextHtmlResolver(nodeParser).resolveRichText({
      element: model.elements.body,
    }).html,
    hyphenation: model.elements.enableHyphenation?.value[0]?.codename,
    backgroundColor: model.elements.backgroundColor.value[0].codename,
    backgroundWidth: model.elements.backgroundWidth.value[0].codename,
    colorScheme: getColorScheme(model.elements.backgroundColor.value[0].codename),
    showEngageWithUs: model.elements.showEngageWithUs.value[0]?.codename === 'yes',
    modal: model.elements.formModalCtaFormModal.linkedItems[0],
    hideBreadcrumb:
      model.elements.hideBreadcrumb.value.length && model.elements.hideBreadcrumb.value[0].codename === 'yes',
    breadcrumbOverride: model.elements.breadcrumbOverride.linkedItems.length
      ? ({
          href: hrefLink(
            model.elements.breadcrumbOverride.linkedItems[0],
            (slug as unknown as string)?.split('/'),
            true,
            router.locale,
          ),
          label: model.elements.breadcrumbOverride.linkedItems[0].elements.summaryTitle.value,
        } as BackLink)
      : customBreadcrumb != undefined
      ? customBreadcrumb
      : undefined,
  };
  //  console.log('hypenation',data.hyphenatedHeadline)
  // setHeadline(headlineTemp)
  // setHeadline(result)
  const desc = HyphenResult(data.bodyHtml, router.locale);
  const headline = HyphenResult(data.headline, router.locale);

  return (
    <Box
      id={model.system.codename}
      w='100vw'
      maxW={moduleMaxWidth}
      mx='auto'
      sx={
        data.backgroundWidth === 'partial'
          ? {
              bgGradient: {
                base: `linear(to-b, ${data.colorScheme.background} 80%, white 0%)`,
                sm: `linear(to-b, ${data.colorScheme.background} 70%, white 0%)`,
                lg: `linear(to-r, ${data.colorScheme.background} 65%, white 0%)`,
                xl: `linear(to-r, ${data.colorScheme.background} 55%, white 0%)`,
              },
            }
          : { bg: data.colorScheme.background }
      }
    >
      <Box
        ref={containerRef}
        px='0'
        display={'grid'}
        gridAutoFlow={{ base: 'row', lg: 'column' }}
        gridTemplateColumns={{ lg: '40% calc(60% - 80px)' }}
        gap={{ base: 's', lg: 'l' }}
        pl={{ base: 'xs', sm: 's', lg: 'l' }}
        pr={{ base: 'xs', sm: 's', lg: 0 }}
      >
        {/* --------------- TEXT BLOCK --------------- */}
        <Flex
          flexDir={'column'}
          width={{ base: '95%', sm: '100%', lg: '100%' }}
          maxWidth='420px'
          alignItems='left'
          mb={{ base: 0, lg: 'xl' }}
          color={data.colorScheme.body}
          justifyContent='space-between'
        >
          <Box>
            {!data.hideBreadcrumb && (
              <Flex maxH='120px' alignItems={'center'} my={{ base: 's' }}>
                <Breadcrumb customLink={backLink ?? data.breadcrumbOverride} />
              </Flex>
            )}
            {/* {(data.headlineTag === 'h1' || data.headlineTag === 'h2')  && (
             
             <Flex
               as={data.headlineTag}
               textStyle={{ base: 'mobile.h3', lg: 'desktop.h3' }}
               mb={{ base: 'm' }}
               wordBreak='break-word'
               mt={data.hideBreadcrumb ? { base: 'm', lg: '144px' } : '0px'}
               dangerouslySetInnerHTML={{ __html: data.headline }}
             /> 
           )} */}
            {(data.headlineTag === 'h1' || data.headlineTag === 'h2') &&
              router.asPath == '/contact-us/polska' &&
              model.system.codename == 'poland_color_block_hero' && (
                <Flex
                  as={data.headlineTag}
                  // textStyle={{ base: 'mobile.contact_poland', lg: 'desktop.contact_poland' }}
                  style={{
                    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    fontSize: '36px',
                    lineHeight: '44px',
                    letterSpacing: '-0.04em',
                  }}
                  mb={{ base: 'm' }}
                  wordBreak='break-word'
                  mt={data.hideBreadcrumb ? { base: 'm', lg: '144px' } : '0px'}
                  dangerouslySetInnerHTML={{ __html: data.headline }}
                />
              )}
            {(data.headlineTag === 'h1' || data.headlineTag === 'h2') &&
              model.system.codename != 'poland_color_block_hero' && (
                <Flex
                  as={data.headlineTag}
                  textStyle={{ base: 'mobile.h3', lg: 'desktop.h3' }}
                  mb={{ base: 'm' }}
                  wordBreak='break-word'
                  mt={data.hideBreadcrumb ? { base: 'm', lg: '144px' } : '0px'}
                  dangerouslySetInnerHTML={{ __html: data.hyphenation == 'yes' ? headline : data.headline }}
                />
              )}
          </Box>

          <Box>
            {model.system.codename == 'poland_color_block_hero' && (
              <Flex
                alignItems={'end'}
                textStyle={{ base: 'mobile.contact_poland', lg: 'desktop.contact_poland' }}
                dangerouslySetInnerHTML={{ __html: data.hyphenation == 'yes' ? desc : data.bodyHtml }}
                flexDir='column'
                gap='xxs'
                sx={{
                  p: {
                    w: '100%',
                  },
                }}
              />
            )}
            {model.system.codename != 'poland_color_block_hero' && (
              <Flex
                alignItems={'end'}
                textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.book.l' }}
                dangerouslySetInnerHTML={{ __html: data.hyphenation == 'yes' ? desc : data.bodyHtml }}
                flexDir='column'
                gap='xxs'
                sx={{
                  p: {
                    w: '100%',
                  },
                }}
              />
            )}
            {url !== '' && (
              <Box mt='s'>
                {model.elements.ctaSnippetStyle.value[0].codename === 'link' && (
                  <Link
                    colorScheme={data.colorScheme.body}
                    href={fileFrontify ? fileFrontify.downloadUrl : url}
                    type={LinkType.SecondaryButton}
                    iconName='chevronRightForLink'
                    isExternal={model.elements.ctaSnippetOpenIn.value[0].codename === 'new_tab'}
                    locale={router.locale}
                  >
                    {model.elements.ctaSnippetCtaText.value}
                  </Link>
                )}
                {model.elements.ctaSnippetStyle.value[0].codename === 'button' && (
                  <Button
                    id='color-block-hero-button'
                    fontFamily='HCo Gotham Rounded Book'
                    fontWeight={350}
                    fontSize='18px'
                    lineHeight='20px'
                    h='60px'
                    as='a'
                    href={fileFrontify ? fileFrontify.downloadUrl : url}
                    target={model.elements.ctaSnippetOpenIn.value[0].codename === 'new_tab' ? '_blank' : ''}
                    borderRadius='4px'
                  >
                    {model.elements.ctaSnippetCtaText.value}
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Flex>
        {/* --------------- TEXT BLOCK END --------------- */}

        <Flex alignItems='center' position='relative' mt={{ lg: 'xl' }} mb={{ base: 'm', lg: 'lr' }}>
          {model.elements.video23EmbedLink?.value ? (
            <Box w='100%' id={`video-${model.system.id}`} dangerouslySetInnerHTML={{ __html: video }} />
          ) : (
            <AspectRatio ratio={16 / 9} w='100%'>
              <Image
                src={imageData.src}
                width={imageData.width}
                height={imageData.height}
                alt={imageData.alt}
                quality={compressimage}
              />
            </AspectRatio>
          )}
          {data.showEngageWithUs && data.modal && (
            <Button
              ref={ctaRef}
              zIndex={5}
              sx={{
                position: { base: ctaPosition, lg: ctaRef ? 'fixed' : 'absolute' },
              }}
              bottom={0}
              right={0}
              mb={{ base: ctaPosition === 'absolute' ? '-m' : 0, lg: ctaPosition === 'absolute' ? '-100px' : 0 }}
              size={{ base: 'medium', lg: 'large' }}
              aria-controls={data.modal.system.name}
              borderTopLeftRadius={'4px'}
              onClick={onOpen}
            >
              <IconComp iconName='message' />
              <Collapse in={shouldShowCtaText}>
                <Text pl='xs'>{model.elements.formModalCtaButtonText.value}</Text>
              </Collapse>
            </Button>
          )}
        </Flex>
        {data.modal && (
          <ContactFormModal
            id={`${model.system.id}${data.modal.system.id}`}
            model={data.modal}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
      </Box>
    </Box>
  );
};
