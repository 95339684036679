import { Container, Divider } from '@chakra-ui/react';
import { colorScheme } from 'lib/colorScheme';
import { Spacing as SpacingModel } from 'models/content-types/spacing';
import React, { FC } from 'react';
import { spacing } from 'themes/overrides';

type SpacingProps = {
  model: SpacingModel;
};

export const Spacing: FC<SpacingProps> = ({ model }) => {
  const spacerColors = colorScheme[(model.elements.color.value[0]?.codename ?? 'white') as keyof typeof colorScheme];
  const hasLine = model.elements.withLine.value[0]?.codename === 'yes';
  return (
    <Container
      w='100vw'
      backgroundColor={spacerColors.base}
      py={{
        base: `calc(${spacing.space[model.elements.mobile.value[0]?.codename]}/2)`,
        md: `calc(${spacing.space[model.elements.desktop.value[0]?.codename]}/2)`,
      }}
    >
      {hasLine && <Divider w='100%' border='.5px' borderColor={spacerColors.baseAccent} />}
    </Container>
  );
};
