import { Box } from '@chakra-ui/react';
import { CheckboxField } from 'components/form/CheckboxField';
import { DropdownField } from 'components/form/DropdownField';
import { InputField } from 'components/form/InputField';
import { RadioField } from 'components/form/RadioField';
import { TextareaField } from 'components/form/TextareaField';
import { UploadField } from 'components/form/UploadField';
import { CheckboxFormField, DropdownFormField, InputFormField, RadioFormField } from 'models';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';
import { FieldType, getFormFieldType } from 'utils/form/fieldType';

import { FileInfo } from './CustomForm';

interface FormFieldProps {
  formField: InputFormField | RadioFormField | CheckboxFormField | DropdownFormField;
  files: FileInfo[];
  initialValue: any;
  setFiles: (files: FileInfo[]) => void;
}

export const FormField: FunctionComponent<FormFieldProps> = ({ formField, files, initialValue, setFiles }) => {
  const { common } = withTranslations();
  switch (getFormFieldType(formField)) {
    case FieldType.Radio:
      return <RadioField model={formField as RadioFormField} />;
    case FieldType.Dropdown:
      return <DropdownField model={formField as DropdownFormField} />;
    case FieldType.Checkbox:
      return <CheckboxField model={formField as CheckboxFormField} initialValue={initialValue} />;
    case FieldType.Text:
    case FieldType.Number:
    case FieldType.Email:
      return <InputField model={formField as InputFormField} />;
    case FieldType.Telephone:
      return <InputField model={formField as InputFormField} />;
    case FieldType.Textarea:
      return <TextareaField model={formField as InputFormField} />;
    case FieldType.UploadMultiple:
    case FieldType.UploadSingle:
      return <UploadField model={formField as InputFormField} files={files} setFiles={setFiles} />;
    default:
      return <Box>{common('fieldNotAvailable')}.</Box>;
  }
};
