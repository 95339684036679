import { CheckboxFormField, DropdownFormField, InputFormField, RadioFormField } from 'models';

export enum FieldType {
  Radio = 'radio',
  Checkbox = 'checkbox',
  Textarea = 'textarea',
  Number = 'number',
  Text = 'text',
  Email = 'email',
  UploadSingle = 'uploadSingle',
  UploadMultiple = 'uploadMultiple',
  Telephone = 'telephone',
  Dropdown = 'Dropdown',
}
export const getFormFieldType = (field: CheckboxFormField | RadioFormField | InputFormField | DropdownFormField) => {
  if ('options' in field.elements) {
    return FieldType.Radio;
  }
  if ('formOption' in field.elements) {
    return FieldType.Checkbox;
  }
  if ('dropdownOptions' in field.elements) {
    return FieldType.Dropdown;
  }
  if (field.elements.type.value[0].codename === 'text') {
    return FieldType.Text;
  }
  if (field.elements.type.value[0].codename === 'number') {
    return FieldType.Number;
  }
  if (field.elements.type.value[0].codename === 'email') {
    return FieldType.Email;
  }
  if (field.elements.type.value[0].codename === 'telephone') {
    return FieldType.Telephone;
  }
  if (field.elements.type.value[0].codename === 'textarea') {
    return FieldType.Textarea;
  }
  if (field.elements.type.value[0].codename === 'upload_single') {
    return FieldType.UploadSingle;
  }
  if (field.elements.type.value[0].codename === 'upload_multiple') {
    return FieldType.UploadMultiple;
  }
};
