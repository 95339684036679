import { Box, Checkbox, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { Field } from 'formik';
import { CheckboxFormField } from 'models';
import React, { FunctionComponent } from 'react';

import { validatCheckField } from '../../utils/form/validation';

interface CheckboxFieldProps {
  model: CheckboxFormField;
  initialValue: boolean;
}

export const CheckboxField: FunctionComponent<CheckboxFieldProps> = ({ model, initialValue }) => {
  const html = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: model.elements.formOption.linkedItems[0].elements.label,
  }).html;
  return (
    <Field type='checkbox' name={model.system.codename} validate={validatCheckField(model)}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[model.system.codename] && form.touched[model.system.codename]}>
          <Checkbox {...field} defaultChecked={initialValue} value={model.system.codename}>
            <Box
              textStyle={'desktop.body.s'}
              sx={{
                a: {
                  textDecor: 'underline',
                },
              }}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </Checkbox>
          <FormErrorMessage>{form.errors[model.system.codename]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
