import { Box, Container, Flex, Text } from '@chakra-ui/react';
import { htmlResolver } from 'helpers/htmlResolver';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { hrefLink } from 'utils/normalization/hrefLink';

import { getColorScheme } from '../../lib/colorScheme';
import { NarrowTextHero as NarrowTextHeroModel } from '../../models/content-types';
import Breadcrumb, { BackLink } from '../base/Breadcrumb';
import { HyphenResult } from '../hyphen/hyphenation';

interface NarrowTextHeroProps {
  model: NarrowTextHeroModel;
  customBreadcrumb?: BackLink;
}

export const NarrowTextHero: FunctionComponent<NarrowTextHeroProps> = ({ model, customBreadcrumb }) => {
  const router = useRouter();
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  const translations = withTranslations();

  const narrowTextElements = model.elements;
  const bgColor = narrowTextElements.backgroundColor.value[0].codename;
  const bgColorScheme = getColorScheme(bgColor);
  const narrowTextBody = htmlResolver(narrowTextElements.body);
  const hyphenation = model.elements.enableHyphenation?.value[0]?.codename;
  const headline = HyphenResult(model.elements.headline.value, router.locale);
  const breadcrumbOverride = model.elements.breadcrumbOverride.linkedItems.length
    ? ({
        href: hrefLink(
          model.elements.breadcrumbOverride.linkedItems[0],
          (slug as unknown as string)?.split('/'),
          true,
          router.locale,
          translations,
        ),
        label: model.elements.breadcrumbOverride.linkedItems[0].elements.summaryTitle.value,
      } as BackLink)
    : customBreadcrumb != undefined
    ? customBreadcrumb
    : undefined;
  return (
    <Box id={model.system.codename} w='100vw' maxW={moduleMaxWidth} mx='auto' bg={bgColorScheme.background}>
      <Container pt='m' px={{ base: 'xs', lg: 'l' }}>
        <Box>
          <Breadcrumb color='mountain.base' customLink={breadcrumbOverride} />
        </Box>
        <Flex direction={{ base: 'column', lg: 'row' }} py={{ base: 's', lg: 'm' }} gap={{ base: 's', lg: 'unset' }}>
          <Text
            w={{ base: '100%', md: 'calc(33% + 80px)' }}
            pr={{ base: 'unset', lg: 'l' }}
            as='h1'
            textStyle={{ base: 'mobile.h3', lg: 'desktop.h4' }}
          >
            {/* {narrowTextElements.headline.value} */}
            {hyphenation == 'yes' ? headline : narrowTextElements.headline.value}
          </Text>
          <Box w={{ base: '100%', md: '70%' }}>
            {narrowTextBody?.map((text, index) => (
              <Box
                key={index}
                textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.l' }}
                dangerouslySetInnerHTML={{
                  __html: hyphenation == 'yes' ? HyphenResult(text.body, router.locale) : text.body,
                }}
              />
            ))}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};
