import { Box, Container, SimpleGrid, Text, useDisclosure } from '@chakra-ui/react';
import { ImageWrapper } from 'components/wrapper/imageWrapper';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';

import { colorScheme } from '../../lib/colorScheme';
import { ExpertBlock as ExpertBlockModel } from '../../models/content-types/expert_block';
import { getButtonWithLinkStyling } from '../base/Button';
import { ContactFormModal } from './ContactFormModal';

interface ExpertBlockProps {
  model: ExpertBlockModel;
}

export const ExpertBlock: FunctionComponent<ExpertBlockProps> = ({ model }) => {
  const { aria } = withTranslations();
  const backgroundColor =
    colorScheme[model.elements.backgroundColor?.value[0].codename as keyof typeof colorScheme]?.base ?? 'white';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const persons = model.elements.contact.linkedItems;
  const contactModal = model.elements.formModalCtaFormModal.linkedItems[0];
  return (
    <Container
      display={'flex'}
      p={{ base: '20px', md: '40px', lg: '40px' }}
      ml={{ base: '20px', md: '40px', lg: '40px' }}
      maxW={'1320px'}
      w={{ base: '335px', md: '568px', lg: '100%', xl: '100%' }}
      alignItems={'flex-start'}
      bg={backgroundColor}
    >
      <Box
        id={model.system.codename}
        bg={backgroundColor}
        display={'flex'}
        w={{ base: '295px', md: '498px', lg: '1024px', xl: '1238px' }}
        gap={'40px'}
        flexDir={{ base: 'column', lg: 'column' }}
        alignItems={{ base: 'center', md: 'center', lg: 'flex-start', xl: 'flex-start' }}
        flexShrink={0}
      >
        <Text
          as='h2'
          alignSelf={{ base: 'center', lg: 'start', xl: 'start' }}
          textStyle={{ base: 'desktop.body.book.l', md: 'desktop.h5' }}
        >
          {model.elements.headline.value}{' '}
        </Text>
        <Box display={'flex'} flexDirection={{ base: 'column', lg: 'row', xl: 'row' }} gap={'60px'}>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 2 }}
            //   spacingX={{ base: 0, md: '10' }}
            // spacingY={{ base: 's', lg: 'xl' }}
            pb={{ lg: 'lr' }}
            spacingX='130px'
            spacingY='50px'
          >
            {persons.map((item) => {
              const person = item.elements;
              return (
                <>
                  <Box
                    display={'flex'}
                    w={{ lg: '486px', xl: '586px' }}
                    flexDirection={{ base: 'column', md: 'column', lg: 'column', xl: 'row' }}
                    alignItems={{ base: 'center', md: 'center', lg: 'flex-start' }}
                    flexShrink={0}
                    gap={{ base: '20px', md: '20px', lg: '40px' }}
                  >
                    <Box order={0}>
                      <ImageWrapper
                        imageSx={{ w: { base: '100px', md: '160px' }, h: { base: '100px', md: '160px' } }}
                        avatarSize={{ base: '2xl', md: '3xl' }}
                        imageFrontify={person.frontifyImage.value}
                        msImage={person.msImage.value}
                        alt={person.displayName.value}
                      />
                    </Box>
                    <Box
                      display={'flex'}
                      w={{ base: '295px', md: '224px', lg: '364px' }}
                      flexDirection={'column'}
                      alignItems={{ base: 'center', md: 'center', lg: 'flex-start' }}
                      flexShrink={0}
                    >
                      <Text
                        aria-label={`${aria('expertName')}: ${person?.displayName}`}
                        textStyle='mobile.body.medium.s'
                      >
                        {person?.displayName.value}
                      </Text>
                      <Text aria-label={`${aria('expertOccupation')}: ${person?.jobTitle}`} textStyle='mobile.body.s'>
                        {person.jobTitle.value}
                      </Text>
                      {model.elements.showPhoneNumber?.value[0] && !!person.phoneNumber.value && (
                        <Box
                          my='xs'
                          textStyle='mobile.body.xs'
                          textAlign={{ base: 'center', md: 'center', lg: 'start' }}
                        >
                          {person.phoneNumber.value}
                        </Box>
                      )}
                      {!!person.description.value && (
                        <Box
                          my='xs'
                          textStyle='mobile.body.xs'
                          textAlign={{ base: 'start', md: 'start' }}
                          dangerouslySetInnerHTML={{ __html: person.description.value }}
                        />
                      )}
                      {contactModal &&
                        getButtonWithLinkStyling(
                          `${aria('openContactGetInTouchWith')} ${person?.displayName}`,
                          model.elements.formModalCtaButtonText.value,
                          onOpen,
                          contactModal.system.name,
                        )}
                      {contactModal && (
                        <ContactFormModal
                          id={`${model.system.id}${contactModal.system.id}`}
                          model={contactModal}
                          isOpen={isOpen}
                          contactPersonEmail={person.email.value}
                          onClose={onClose}
                        />
                      )}
                    </Box>
                  </Box>
                </>
              );
            })}
          </SimpleGrid>
        </Box>
      </Box>
    </Container>
  );
};
