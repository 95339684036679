import { Text } from '@chakra-ui/react';
import React from 'react';
interface CustomTagProps {
  text: string;
  backgroundColor: string;
  textColor: string;
}

export const CustomTag = ({ text, backgroundColor, textColor }: CustomTagProps) => {
  return (
    <Text
      display='inline-block'
      w='fit-content'
      background={backgroundColor}
      aria-label={`tag ${text}`}
      color={textColor}
      textStyle='mobile.body.xs'
      fontSize={'14px'}
      pl={'xxs'}
      pr={'xxs'}
      py={'3xs'}
    >
      {text}
    </Text>
  );
};
