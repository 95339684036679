// 'use client';
import { AspectRatio, Box, Button, Container, Flex, Text, VisuallyHidden } from '@chakra-ui/react';
import { usePiwikPro } from '@piwikpro/next-piwik-pro';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect } from 'react';
import { withTranslations } from 'store/translations';
import { imageFormatter } from 'utils/imageFormatter';
import { hrefLink } from 'utils/normalization/hrefLink';

import { LinkType } from '../../lib/enums/LinkType';
import { QuestionBlock as QuestionBlockModel } from '../../models';
import { IconComp } from '../base/IconComp';
import { Link as CustomLink } from '../base/Link';

interface QuestionBlockProps {
  model: QuestionBlockModel;
}

export const QuestionBlock: FunctionComponent<QuestionBlockProps> = ({ model }) => {
  const translations = withTranslations();
  const router = useRouter();
  const { ContentTracking } = usePiwikPro();
  const { CustomEvent } = usePiwikPro();
  // const {UserManagement} = usePiwikPro()
  // let visitorInfo = UserManagement.getVisitorInfo()
  //  console.log('visitorInfo',visitorInfo)
  let index = 0;
  const [options, setOptions] = React.useState(model.elements.showcasedOptions?.linkedItems[index]);
  const [restart, setRestart] = React.useState(false);
  const [question, setQuestion] = React.useState<string>(
    model.elements.showcasedOptions?.linkedItems[index]?.elements.decisionTreeOptionBlockMainQuestion.value,
  );
  //2-block option
  const [firstOpt, setFirstOpt] = React.useState(false);
  const [secondOpt, setSecondOpt] = React.useState(false);

  //3-block options
  const [firstTwoOpt, setFirstTwoOpt] = React.useState(false);
  const [secondTwoOpt, setSecondTwoOpt] = React.useState(false);
  const [thirdTwoOpt, setThirdTwoOpt] = React.useState(false);

  useEffect(() => {
    model.elements.showcasedOptions?.linkedItems?.map((item, ind) => {
      if (question == item.elements.decisionTreeOptionBlockMainQuestion.value) {
        index = ind;
      }
    });
    setOptions(model.elements.showcasedOptions?.linkedItems[index]);
  }, [question]);

  //End of wizard data
  const EOWData = {
    title: options?.elements.title.value,
    description: options?.elements.description.value,
    ctaText: options?.elements.ctaSnippetCtaText.value,
    fileFrontify: options?.elements.ctaSnippetFrontifyFile.value,
    kontentAiItem: options?.elements.ctaSnippetKontentAiUrl.linkedItems[0],
    ctaSnippetUrl: options?.elements.ctaSnippetUrl?.value,
  };
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  const imageEOWData = imageFormatter(options?.elements.imageFrontify.value, '');
  const url = hrefLink(options, (slug as unknown as string)?.split('/'), false, router.locale, translations);

  const toggleRestart = () => {
    setRestart(!restart);
    index = 0;
    setOptions(model.elements.showcasedOptions?.linkedItems[index]);
    setQuestion(model.elements.showcasedOptions?.linkedItems[index].elements.decisionTreeOptionBlockMainQuestion.value);
  };
  if (options?.elements.endOfWizard?.value[0]?.codename == 'yes') {
    // CustomEvent.trackEvent('EndOfWizard','view',EOWData.title)
    CustomEvent.trackEvent('Decision Tree Final block View', 'Reached end of Wizard', `Block title - ${EOWData.title}`);
  }
  if (options?.elements.endOfWizard?.value[0]?.codename == 'yes') {
    CustomEvent.trackEvent('Decision Tree Final block View', 'Reached end of Wizard', `Block title - ${EOWData.title}`);
    // UserManagement.getUserId()
    //  ContentTracking.trackContentImpression('User ID',`${UserManagement.getUserId()}`,'Curre');
  }
  return (
    <>
      {options?.elements.decisionTreeOptionBlockOptionOne.value.length <= 0 ||
      options?.elements.endOfWizard?.value[0]?.codename == 'yes' ? (
        <Box
          data-track-content
          w='1280px'
          h='382px'
          bg='forest.10'
          display={{ base: 'none', lg: 'block' }}
          data-content-name='decison tree block'
        >
          <Box
            as={'button'}
            style={{
              marginLeft: '1217px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flexShrink: 0,
              marginTop: '26px',
            }}
            onClick={toggleRestart}
          >
            <IconComp iconName='rotate' />
          </Box>
          <Text
            style={{
              display: 'flex',
              width: '497px',
              // height: "134px",
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: '143px',
              flexShrink: 0,
            }}
            color='forest.base'
            textStyle='desktop.h4'
          >
            {EOWData.title}
          </Text>
          <Text
            style={{
              display: 'flex',
              width: '663px',
              height: '134px',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: '143px',
              flexShrink: 0,
            }}
            color='forest.base'
            textStyle='desktop.body.l'
          >
            {EOWData.description}
          </Text>

          {/* CTA block */}
          {EOWData.fileFrontify ? (
            <Flex mt={{ base: 's', md: 'xs' }} justifyContent='left' ml='143px'>
              <CustomLink
                href={EOWData.fileFrontify}
                // href="/"
                type={LinkType.SecondaryButton}
                iconName='chevronRightForLink'
                colorScheme='forest.base'
                locale={router.locale}
                onClick={() => {
                  CustomEvent.trackEvent('Decision tree CTA click', 'CTA Click', `URL - ${EOWData.fileFrontify}`);
                }}
              >
                {EOWData.ctaText}
                {/* {mediaContent.ctaSnippetCtaText.value} */}
                <VisuallyHidden>{/* {translations.aria('about')} {mediaContent.headline.value} */}</VisuallyHidden>
              </CustomLink>{' '}
            </Flex>
          ) : EOWData.kontentAiItem ? (
            <Flex mt={{ base: 's', md: 'xs' }} justifyContent='left' ml='143px'>
              <CustomLink
                href={url}
                // href="/"
                type={LinkType.SecondaryButton}
                iconName='chevronRightForLink'
                colorScheme='forest.base'
                locale={router.locale}
                onClick={() => {
                  CustomEvent.trackEvent('Decision tree CTA click', 'CTA Click', `URL - ${url}`);
                }}
              >
                {EOWData.ctaText}
                {/* {mediaContent.ctaSnippetCtaText.value} */}
                <VisuallyHidden>{/* {translations.aria('about')} {mediaContent.headline.value} */}</VisuallyHidden>
              </CustomLink>{' '}
            </Flex>
          ) : EOWData.ctaSnippetUrl ? (
            <Flex mt={{ base: 's', md: 'xs' }} justifyContent='left' ml='143px'>
              <CustomLink
                href={EOWData.ctaSnippetUrl}
                // href="/"
                type={LinkType.SecondaryButton}
                iconName='chevronRightForLink'
                colorScheme='forest.base'
                locale={router.locale}
                onClick={() => {
                  CustomEvent.trackEvent('Decision tree CTA click', 'CTA Click', `URL - ${EOWData.ctaSnippetUrl}`);
                }}
              >
                {EOWData.ctaText}
                {/* {mediaContent.ctaSnippetCtaText.value} */}
                <VisuallyHidden>{/* {translations.aria('about')} {mediaContent.headline.value} */}</VisuallyHidden>
              </CustomLink>{' '}
            </Flex>
          ) : null}

          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='815'
            height='4'
            viewBox='0 0 815 4'
            fill='none'
            style={{
              marginTop: '22px',
              marginLeft: '140px',
            }}
          >
            <path d='M2 2H813' stroke='url(#paint0_linear_31782_6389)' strokeWidth='4' strokeLinecap='round' />
            <defs>
              <linearGradient
                id='paint0_linear_31782_6389'
                x1='-21.6788'
                y1='-55.5'
                x2='838.294'
                y2='-55.5'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor='white' stopOpacity='0'>
                  <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                </stop>

                <stop offset='0.14393' stopColor='#125A40'>
                  <animate attributeName='stop-color' values='#FFFFFF;#E7EEEC;#125A40' dur='1s' repeatCount='1' />
                </stop>
                <animateTransform
                  attributeName='gradientTransform'
                  dur='1s'
                  type='rotate'
                  from='0 .5 .5'
                  to='360 .5 .5'
                  repeatCount='1'
                />
              </linearGradient>
            </defs>
          </svg>

          {/* ----- IMAGE START ----- */}
          <Box
            // justifyContent={styles.media.justifyContent}
            alignSelf='end'
            w='345px'
            h='345px'
            style={{ marginLeft: '845px', marginTop: '-155.9px', color: 'forest.base' }}
            // pr={mediaMode === 'start' ? { base: '0', lg: 'l' } : 'unset'}
            // pl={mediaMode === 'end' ? { base: '0', lg: 'l' } : 'unset'}
          >
            <AspectRatio w='100%' maxW='285px' ratio={16 / 9}>
              <Image
                sizes='(max-width: 1023) 100vw,(min-width: 1024) 50vw'
                style={{ objectFit: 'cover' }}
                src={imageEOWData.src}
                alt={imageEOWData.alt}
                width={imageEOWData.width}
                height={imageEOWData.height}
                //   quality={compressimage}
              ></Image>
            </AspectRatio>
          </Box>
          {/* ----- IMAGE END ----- */}
        </Box>
      ) : (
        <Box
          id={model.system.codename}
          //   w='1280px'
          w='100vw'
          maxW={'1280px'}
          h='382px'
          //   bg='pebble.20'
          style={{ flexShrink: 0, backgroundColor: '#FFF' }}
          px={{ base: 'xs', md: 's', lg: 'l' }}
          display={{ base: 'none', lg: 'flex' }}
        >
          {options?.elements.decisionTreeOptionBlockOptionThree.value.length > 0 ? (
            <Container
              pt='126px'
              pr='676px'
              pb='76px'
              pl='107px'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flexShrink: 0,
                color: '#0098EB',
                //  padding: '126px 676px 76px 107px'style={{padding: '192px 442px 192px 538px'}}
              }}
            >
              {!firstTwoOpt && !secondTwoOpt && !thirdTwoOpt ? (
                <Text
                  // data-track-content
                  id='main_question'
                  w='497px'
                  h='180px'
                  textStyle={'desktop.h4'}
                  // data-content-name={question}
                >
                  {/* {data.firstQuestion} */}
                  {question}
                </Text>
              ) : (
                <Text w='497px' h='180px' textStyle={'desktop.h4'} style={{ display: 'none' }}>
                  {/* {data.firstQuestion} */}
                  {question}
                </Text>
              )}
              {firstTwoOpt || secondTwoOpt || thirdTwoOpt ? (
                <svg
                  id='main_linear_three'
                  xmlns='http://www.w3.org/2000/svg'
                  width='910'
                  height='4'
                  viewBox='0 0 803 4'
                  fill='none'
                  style={{
                    // marginTop: '65px',
                    marginTop: '57px',
                    marginLeft: '19px',
                    //  marginRight: '934px'
                  }}
                >
                  <path d='M2 2H801' stroke='url(#paint0_linear_31782_6360)' strokeWidth='4' strokeLinecap='round' />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6360'
                      x1='-52'
                      y1='57.0001'
                      x2='853'
                      y2='57.0001'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='white' stopOpacity='0'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;#aad3e9;#6cbfeb;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>
                      <stop offset='0.33' stopColor='#0098EB'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;#aad3e9;#6cbfeb;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='349'
                  height='4'
                  viewBox='0 0 349 4'
                  fill='none'
                  style={{
                    // marginTop: '65px',
                    marginTop: '-120px',
                    marginLeft: '500px',
                  }}
                >
                  <path d='M2 2H347' stroke='url(#paint0_linear_31782_6448)' strokeWidth='4' strokeLinecap='round' />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6448'
                      x1='-41.5789'
                      y1='-52.9999'
                      x2='441.421'
                      y2='-52.9999'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='white' stopOpacity='0' />
                      <stop offset='0.247576' stopColor='#0098EB' />
                    </linearGradient>
                  </defs>
                </svg>
              )}
              <Button
                // data-track-content
                h='60px'
                w='184px'
                p='30px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  marginTop: '-35px',
                  marginLeft: '836px',
                  marginRight: '234px',
                  borderRadius: '4px',
                }}
                // data-content-name={question}
                onClick={() => {
                  ContentTracking.trackContentImpression(
                    'Question Block',
                    `Current question - ${question}`,
                    `Follow up question is ${options?.elements.decisionTreeOptionBlockFollowUpQuestionOne.value}`,
                  );
                  setFirstTwoOpt(true);
                  setTimeout(() => {
                    setFirstTwoOpt(false);
                  }, 2000);
                  setTimeout(() => {
                    setQuestion(options?.elements.decisionTreeOptionBlockFollowUpQuestionOne.value);
                  }, 2000);
                }}
              >
                {options?.elements.decisionTreeOptionBlockOptionOne.value[0].name}
              </Button>
              {firstTwoOpt ? (
                <svg
                  id='arrow_2_1'
                  xmlns='http://www.w3.org/2000/svg'
                  width='123'
                  height='4'
                  viewBox='0 0 123 4'
                  fill='none'
                  style={{
                    // marginTop: '65px',
                    marginTop: '-34px',
                    marginLeft: '993px',
                    marginRight: '234px',
                  }}
                >
                  <path d='M120.5 2H2' stroke='url(#paint0_linear_31782_6415)' strokeWidth='4' strokeLinecap='round' />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6415'
                      x1='137.5'
                      y1='57'
                      x2='-68.9999'
                      y2='57'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='white' stopOpacity='0'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>

                      <stop offset='0.33' stopColor='#0098EB'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : null}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='159'
                height='114'
                viewBox='0 0 159 114'
                fill='none'
                style={{
                  // marginTop: '65px',
                  marginTop: firstTwoOpt ? '-111px' : '-140px',
                  marginLeft: '690px',
                  marginRight: '234px',
                }}
              >
                <path
                  d='M2 112V18C2 9.16344 9.16344 2 18 2H157'
                  stroke='url(#paint0_linear_31782_6446)'
                  strokeWidth='4'
                  strokeLinecap='round'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_31782_6446'
                    x1='-276'
                    y1='2'
                    x2='54'
                    y2='2'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='white' stopOpacity='0' />
                    <stop offset='0.36' stopColor='#0098EB' />
                  </linearGradient>
                </defs>
              </svg>
              <Button
                // data-track-content
                h='60px'
                w='184px'
                p='30px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  marginTop: '-137px',
                  marginLeft: '840px',
                  marginRight: '234px',
                  borderRadius: '4px',
                }}
                // data-content-name={question}
                onClick={() => {
                  ContentTracking.trackContentImpression(
                    'Question Block',
                    `Current question - ${question}`,
                    `Follow up question is ${options?.elements.decisionTreeOptionBlockFollowUpQuestionTwo.value}`,
                  );
                  setSecondTwoOpt(true);
                  setTimeout(() => {
                    setSecondTwoOpt(false);
                  }, 2000);
                  setTimeout(() => {
                    setQuestion(options?.elements.decisionTreeOptionBlockFollowUpQuestionTwo.value);
                  }, 2000);
                }}
              >
                {options?.elements.decisionTreeOptionBlockOptionTwo.value[0].name}
              </Button>
              {secondTwoOpt ? (
                <svg
                  id='arrow_2_1'
                  xmlns='http://www.w3.org/2000/svg'
                  width='123'
                  height='4'
                  viewBox='0 0 123 4'
                  fill='none'
                  style={{
                    // marginTop: '65px',
                    marginTop: '-34px',
                    marginLeft: '993px',
                    marginRight: '234px',
                  }}
                >
                  <path d='M120.5 2H2' stroke='url(#paint0_linear_31782_6415)' strokeWidth='4' strokeLinecap='round' />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6415'
                      x1='137.5'
                      y1='57'
                      x2='-68.9999'
                      y2='57'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='white' stopOpacity='0'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>

                      <stop offset='0.33' stopColor='#0098EB'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : null}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='159'
                height='114'
                viewBox='0 0 159 114'
                fill='none'
                style={{
                  // marginTop: '65px',
                  marginTop: secondTwoOpt ? '105px' : '77px',
                  marginLeft: '690px',
                  marginRight: '234px',
                }}
              >
                <path
                  d='M2 2V96C2 104.837 9.16344 112 18 112H157'
                  stroke='url(#paint0_linear_31782_6447)'
                  strokeWidth='4'
                  strokeLinecap='round'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_31782_6447'
                    x1='-276'
                    y1='112'
                    x2='54'
                    y2='112'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='white' stopOpacity='0' />
                    <stop offset='0.36' stopColor='#0098EB' />
                  </linearGradient>
                </defs>
              </svg>
              <Button
                // data-track-content
                h='60px'
                w='184px'
                p='30px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  marginTop: '-30px',
                  marginLeft: '840px',
                  marginRight: '234px',
                  borderRadius: '4px',
                }}
                // data-content-name={question}
                onClick={() => {
                  ContentTracking.trackContentImpression(
                    'Question Block',
                    `Current question - ${question}`,
                    `Follow up question is ${options?.elements.decisionTreeOptionBlockFollowUpQuestionThree.value}`,
                  );

                  setThirdTwoOpt(true);
                  setTimeout(() => {
                    setThirdTwoOpt(false);
                  }, 2000);
                  setTimeout(() => {
                    setQuestion(options?.elements.decisionTreeOptionBlockFollowUpQuestionThree.value);
                  }, 2000);
                }}
              >
                {options?.elements.decisionTreeOptionBlockOptionThree.value[0]?.name}
              </Button>
              {thirdTwoOpt ? (
                <svg
                  id='arrow_2_1'
                  xmlns='http://www.w3.org/2000/svg'
                  width='123'
                  height='4'
                  viewBox='0 0 123 4'
                  fill='none'
                  style={{
                    // marginTop: '65px',
                    marginTop: '-34px',
                    marginLeft: '993px',
                    marginRight: '234px',
                  }}
                >
                  <path d='M120.5 2H2' stroke='url(#paint0_linear_31782_6415)' strokeWidth='4' strokeLinecap='round' />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6415'
                      x1='137.5'
                      y1='57'
                      x2='-68.9999'
                      y2='57'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='white' stopOpacity='0'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>

                      <stop offset='0.33' stopColor='#0098EB'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : null}
            </Container>
          ) : (
            <Container
              pt='126px'
              pr='676px'
              pb='76px'
              pl='107px'
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flexShrink: 0,
                color: '#0098EB',
                //  padding: '126px 676px 76px 107px'style={{padding: '192px 442px 192px 538px'}}
              }}
            >
              {!secondOpt && !firstOpt ? (
                <Text
                  // data-track-content
                  id='main_question'
                  w='497px'
                  h='180px'
                  textStyle={'desktop.h4'}
                  // data-content-name={question}
                >
                  {/* {data.firstQuestion} */}
                  {question}
                </Text>
              ) : (
                <Text w='497px' h='180px' textStyle={'desktop.h4'} style={{ display: 'none' }}>
                  {/* {data.firstQuestion} */}
                  {question}
                </Text>
              )}
              {secondOpt || firstOpt ? (
                <svg
                  id='main_linear_one'
                  xmlns='http://www.w3.org/2000/svg'
                  width='803'
                  height='4'
                  viewBox='0 0 803 4'
                  fill='none'
                  style={{
                    // marginTop: '65px',
                    marginTop: '57px',
                    marginLeft: '-70px',
                    //  marginRight: '934px'
                  }}
                >
                  <path d='M2 2H801' stroke='url(#paint0_linear_31782_6360)' strokeWidth='4' strokeLinecap='round' />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6360'
                      x1='-52'
                      y1='57.0001'
                      x2='853'
                      y2='57.0001'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='white' stopOpacity='0'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;#aad3e9;#6cbfeb;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>
                      <stop offset='0.33' stopColor='#0098EB'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;#aad3e9;#6cbfeb;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : (
                <svg
                  id='main_linear_two'
                  xmlns='http://www.w3.org/2000/svg'
                  width='304'
                  height='4'
                  viewBox='0 0 304 4'
                  fill='none'
                  style={{
                    // marginTop: '65px',
                    marginTop: '-115px',
                    marginLeft: '431px',
                    marginRight: '234px',
                  }}
                >
                  <path d='M2 2H302' stroke='url(#paint0_linear_31782_6430)' strokeWidth='4' strokeLinecap='round' />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6430'
                      x1='-26'
                      y1='57'
                      x2='354'
                      y2='57'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='white' stopOpacity='0'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>
                      <stop offset='0.306579' stopColor='#0098EB'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                      {/* <animate attributeName="offset" values=".95;.80;.60;.40;.20;0;.20;.40;.60;.80;.95" dur="4s" repeatCount="3"/></stop> */}
                    </linearGradient>
                  </defs>
                </svg>
              )}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='159'
                height='59'
                viewBox='0 0 159 59'
                fill='none'
                style={{
                  // marginTop: '65px',
                  marginTop: '-61px',
                  marginLeft: '731px',
                  marginRight: '234px',
                }}
              >
                <path
                  d='M2 57V18C2 9.16345 9.16344 2 18 2H157'
                  stroke='url(#paint0_linear_31782_6428)'
                  strokeWidth='4'
                  strokeLinecap='round'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_31782_6428'
                    x1='-276'
                    y1='2'
                    x2='54'
                    y2='2'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='white' stopOpacity='0'>
                      <animate
                        attributeName='stop-color'
                        values='#FFFFFF;FFFFFF;lightblue;#0098EB'
                        dur='1s'
                        repeatCount='1'
                      />
                    </stop>
                    <stop offset='0.36' stopColor='#0098EB'>
                      <animate
                        attributeName='stop-color'
                        values='#FFFFFF;FFFFFF;lightblue;#0098EB'
                        dur='1s'
                        repeatCount='1'
                      />
                    </stop>
                    <animateTransform
                      attributeName='gradientTransform'
                      dur='1s'
                      type='rotate'
                      from='0 .5 .5'
                      to='360 .5 .5'
                      repeatCount='1'
                    />
                  </linearGradient>
                </defs>
              </svg>
              <Button
                // data-track-content
                h='60px'
                w='104px'
                p='30px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  marginTop: '-85px',
                  marginLeft: '890px',
                  marginRight: '234px',
                  borderRadius: '4px',
                }}
                // data-content-name={question}
                onClick={() => {
                  ContentTracking.trackContentImpression(
                    'Question Block',
                    `Current question - ${question}`,
                    `Follow up question is ${options?.elements.decisionTreeOptionBlockFollowUpQuestionOne.value}`,
                  );
                  setFirstOpt(true);
                  setTimeout(() => {
                    setFirstOpt(false);
                  }, 2000);
                  setTimeout(() => {
                    setQuestion(options?.elements.decisionTreeOptionBlockFollowUpQuestionOne.value);
                  }, 2000);
                }}
              >
                {options?.elements.decisionTreeOptionBlockOptionOne.value[0]?.name}
              </Button>
              {firstOpt ? (
                <svg
                  id='arrow_2_1'
                  xmlns='http://www.w3.org/2000/svg'
                  width='123'
                  height='4'
                  viewBox='0 0 123 4'
                  fill='none'
                  style={{
                    // marginTop: '65px',
                    marginTop: '-34px',
                    marginLeft: '993px',
                    marginRight: '234px',
                  }}
                >
                  <path d='M120.5 2H2' stroke='url(#paint0_linear_31782_6415)' strokeWidth='4' strokeLinecap='round' />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6415'
                      x1='137.5'
                      y1='57'
                      x2='-68.9999'
                      y2='57'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='white' stopOpacity='0'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>

                      <stop offset='0.33' stopColor='#0098EB'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : null}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='159'
                height='59'
                viewBox='0 0 159 59'
                fill='none'
                style={{
                  // marginTop: '65px',
                  marginTop: firstOpt ? '50px' : '20px',
                  marginLeft: '731px',
                  marginRight: '234px',
                }}
              >
                <path
                  d='M2 2V41C2 49.8366 9.16344 57 18 57H157'
                  stroke='url(#paint0_linear_31782_6429)'
                  strokeWidth='4'
                  strokeLinecap='round'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_31782_6429'
                    x1='-276'
                    y1='57'
                    x2='54'
                    y2='57'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='white' stopOpacity='0'>
                      <animate
                        attributeName='stop-color'
                        values='#FFFFFF;#FFFFFF;lightblue;#0098EB'
                        dur='1s'
                        repeatCount='1'
                      />
                    </stop>

                    <stop offset='0.36' stopColor='#0098EB'>
                      {' '}
                      <animate
                        attributeName='stop-color'
                        values='#FFFFFF;#FFFFFF;lightblue;#0098EB'
                        dur='1s'
                        repeatCount='1'
                      />
                    </stop>
                    <animateTransform
                      attributeName='gradientTransform'
                      dur='1s'
                      type='rotate'
                      from='0 .5 .5'
                      to='360 .5 .5'
                      repeatCount='1'
                    />
                  </linearGradient>
                </defs>
              </svg>
              <Button
                data-track-content
                h='60px'
                w='104px'
                p='20px'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignSelf: 'stretch',
                  marginTop: '-30px',
                  marginLeft: '889px',
                  marginRight: '234px',
                  borderRadius: '4px',
                }}
                data-content-name={question}
                onClick={() => {
                  ContentTracking.trackContentImpression(
                    'Question Block',
                    `Current question - ${question}`,
                    `Follow up question is ${options?.elements.decisionTreeOptionBlockFollowUpQuestionTwo.value}`,
                  );
                  setSecondOpt(true);
                  setTimeout(() => {
                    setSecondOpt(false);
                  }, 2000);
                  setTimeout(() => {
                    setQuestion(options?.elements.decisionTreeOptionBlockFollowUpQuestionTwo.value);
                  }, 2000);
                }}
              >
                {options?.elements.decisionTreeOptionBlockOptionTwo?.value[0]?.name}
              </Button>
              {secondOpt ? (
                <svg
                  id='arrow_2_1'
                  xmlns='http://www.w3.org/2000/svg'
                  width='123'
                  height='4'
                  viewBox='0 0 123 4'
                  fill='none'
                  style={{
                    // marginTop: '65px',
                    marginTop: '-34px',
                    marginLeft: '993px',
                    marginRight: '234px',
                  }}
                >
                  <path d='M120.5 2H2' stroke='url(#paint0_linear_31782_6415)' strokeWidth='4' strokeLinecap='round' />
                  <defs>
                    <linearGradient
                      id='paint0_linear_31782_6415'
                      x1='137.5'
                      y1='57'
                      x2='-68.9999'
                      y2='57'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='white' stopOpacity='0'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>

                      <stop offset='0.33' stopColor='#0098EB'>
                        <animate
                          attributeName='stop-color'
                          values='#FFFFFF;lightblue;#0098EB'
                          dur='1s'
                          repeatCount='1'
                        />
                      </stop>
                      <animateTransform
                        attributeName='gradientTransform'
                        dur='1s'
                        type='rotate'
                        from='0 .5 .5'
                        to='360 .5 .5'
                        repeatCount='1'
                      />
                    </linearGradient>
                  </defs>
                </svg>
              ) : null}
            </Container>
          )}
        </Box>
      )}
    </>
  );
};
