import { Box, Button, Container, Flex, Text } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { Link } from 'components/base/Link';
import { Form, Formik, FormikHelpers } from 'formik';
import { emptyBody } from 'helpers/htmlResolver';
import { LinkType } from 'lib/enums/LinkType';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { FC, FunctionComponent, useState } from 'react';
import { withTranslations } from 'store/translations';
import { FieldType, getFormFieldType } from 'utils/form/fieldType';
import { getHost } from 'utils/getHost';
import { hrefLink } from 'utils/normalization/hrefLink';

import {
  CheckboxFormField,
  CustomForm as CustomFormModel,
  DropdownFormField,
  FormGroup,
  ImageVisualBreaker as ImageVisualBreakerModel,
  RadioFormField,
} from '../../models';
import Breadcrumb, { BackLink } from '../base/Breadcrumb';
import { FormConfirmation } from './FormConfirmation';
import { FormField } from './FormField';
import { ImageVisualBreaker } from './ImageVisualBreaker';
import { ThankYouFormBlock } from './ThankYouFormBlock';

interface CustomFormProps {
  model: CustomFormModel;
}
export interface FileInfo {
  fieldName: string;
  fileName: string;
  file: File;
}
const TranslateJson = {
  untitledContentItem1fb2c4f: 'lastName',
  fornavn: 'firstName',
  number: 'cprNumber',
  socialSecurityNumber: 'cprNumber',
  homeAddress: 'homeAddress',
  zipCodeAndTown: 'zipCodeAndTown',
  telefon: 'telephone',
  email: 'mail',
  stilling: 'position',
  untitledContentItemAeee5f9: 'positionDesignation',
  firmanavn: 'companyName',
  cvrNummer: 'cvrNumber',
  faktureringsmail: 'billingMail',
  faktureringsadresse: 'billingAddress',
  uddannelsestitel: 'educationTitle',
  uddannelsessted: 'placeOfEducation',
  uddannelsesargang: 'yearOfEducation',
  kommentarerText: 'comment',
  samtykkeerklaeringCheckbox: 'acceptedCertificationTerms',
  samtykkeerklaeringCheckbox2: 'acceptedIdentificationTerms',
  untitledContentItem0218f3d: 'fireClass',
  untitledContentItemA49a47d: 'ConstructionClass',
  untitledContentItemStructure: 'structureClass',
  name: 'Name',
  mail: 'Email',
  subject: 'Subject',
  message: 'Message',
};
const snakeCaseToLowerCamelCase = (value: string) => {
  const arr = value
    .split('_')
    .filter((word) => word !== 'form')
    .filter((word) => word !== 'input')
    .filter((word) => word !== 'certificering')
    .filter((word) => word !== 'af')
    .filter((word) => word !== 'statikere');

  const noEmptyString = arr.filter((element) => element !== '');
  const unTranslateText = noEmptyString
    .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
    .join('');
  const key = Object.keys(TranslateJson);
  return key.includes(unTranslateText) ? TranslateJson[unTranslateText] : unTranslateText;
};

const touchAllFields = (
  fields: any,
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void,
) => {
  Object.keys(fields).map((key) => {
    setFieldTouched(key, true, true);
  });
};

export const UPLOAD_FORM_FIELD_KEY = 'upload';

const ErrorMessage: FC<{ message: string }> = ({ message }) => (
  <Text textAlign={'center'} textStyle={{ base: 'mobile.h5', md: 'desktop.h5' }}>
    {message}
  </Text>
);

export const CustomForm: FunctionComponent<CustomFormProps> = ({ model }) => {
  const { t: common } = useTranslation('common');
  const router = useRouter();
  const translations = withTranslations();
  let uniqueIndex = '';

  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;

  const [shouldShowConfirmation, setShouldShowConfirmation] = useState<boolean>(false);
  const [shouldShowThankYouMessage, setShouldShowThankYouMessage] = useState<boolean>(false);
  const [isComeBack, setIsComeBack] = useState<boolean>(false);
  const [values, setValues] = useState<any>();
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [shouldShowErrorMessage, setShouldShowErrorMessage] = useState<boolean>(false);
  const host = typeof window !== 'undefined' ? getHost(window) : '';
  const breadcrumbOverride = model.elements.breadcrumbOverride.linkedItems.length
    ? ({
        href: hrefLink(
          model.elements.breadcrumbOverride.linkedItems[0],
          (slug as unknown as string)?.split('/'),
          true,
          router.locale,
          translations,
        ),
        label: model.elements.breadcrumbOverride.linkedItems[0].elements?.summaryTitle.value,
      } as BackLink)
    : undefined;
  const initialValues = {};
  const groups = model.elements.formGroups.linkedItems;
  groups.forEach((group) => {
    if (group.system.type === 'form_group') {
      (group as FormGroup).elements.sections.linkedItems.forEach((section) => {
        section.elements.subsections.linkedItems.forEach((subsection) => {
          subsection.elements.formFields.linkedItems.forEach((field) => {
            switch (getFormFieldType(field)) {
              case FieldType.Radio:
                if ((field as RadioFormField).elements.selectedOption.linkedItems.length > 0) {
                  initialValues[field.system.codename] = (
                    field as RadioFormField
                  ).elements.selectedOption.linkedItems[0].system.codename;
                } else {
                  initialValues[field.system.codename] = '';
                }
                break;
              case FieldType.Checkbox:
                initialValues[field.system.codename] =
                  (field as CheckboxFormField).elements.selected.value[0].codename === 'yes';
                break;
              case FieldType.Dropdown:
                if ((field as DropdownFormField).elements.selectedOption.linkedItems.length > 0) {
                  initialValues[field.system.codename] = (
                    field as DropdownFormField
                  ).elements.selectedOption.linkedItems[0].system.codename;
                } else {
                  initialValues[field.system.codename] = '';
                }

                break;
              case FieldType.Text:
              case FieldType.Number:
              case FieldType.Email:
              case FieldType.Telephone:
              case FieldType.Textarea:
                initialValues[field.system.codename] = '';
                break;
              case FieldType.UploadMultiple:
              case FieldType.UploadSingle:
                initialValues[UPLOAD_FORM_FIELD_KEY + field.system.codename] = '';
                break;
            }
          });
        });
      });
    }
  });

  const preparePersonalInfoForApi = () => {
    const formData = {};
    const fileDetails = {};
    for (const [index, file] of files.entries()) {
      const fieldName = file.fieldName;
      if (index != 0 && fileDetails[fieldName]) {
        fileDetails[fieldName] = fileDetails[fieldName] + ' ,' + file.fileName;
      } else {
        fileDetails[fieldName] = file.fileName;
      }
    }
    for (const [key, value] of Object.entries(values)) {
      if (!key.startsWith(UPLOAD_FORM_FIELD_KEY) && value !== '') {
        formData[snakeCaseToLowerCamelCase(key)] = value;
      }
    }
    const currentDateTime = new Date().toLocaleString('en-GB', { timeZone: 'Europe/Copenhagen' });
    const currentDateAndTimeDenmark = `${currentDateTime.replace(/[/]/g, '.').replace(',', '')} CET`;

    if (!window.location.pathname.includes('california-consumer-privacy-act-ccpa-request-form')) {
      formData['formUrl'] = window.location.href;
      formData['dateAndTimeOfSubmission'] = currentDateAndTimeDenmark;
    }

    const result = {
      formType: window.location.pathname.includes('california-consumer-privacy-act-ccpa-request-form')
        ? 'disclosure'
        : model.elements.type.value[0].codename,
      formData: formData,
      fileDetails: fileDetails,
    };
    return result;
  };

  const sendData = async (actions: FormikHelpers<any>) => {
    // send personal info
    const personalInfo = preparePersonalInfoForApi();
    if (window.location.pathname.includes('california-consumer-privacy-act-ccpa-request-form')) {
      await fetch(`${host}/api/sendDisclosureFormData`, {
        method: 'POST',
        body: JSON.stringify(personalInfo),
      })
        .then((response) => {
          if (response.status == 200) {
            setShouldShowThankYouMessage(true);
          } else {
            setShouldShowErrorMessage(true);
          }
        })
        .catch(() => {
          setShouldShowErrorMessage(true);
        });
    } else {
      const personalInfoResponse = await fetch(`${host}/api/sendPersonalInfo`, {
        method: 'POST',
        body: JSON.stringify(personalInfo),
      })
        .then(async (response) => {
          if (response.status !== 202) {
            setShouldShowErrorMessage(true);
            return;
          }
          return await response.json().then((result) => result);
        })
        .catch(() => {
          setShouldShowErrorMessage(true);
        });

      if (personalInfoResponse && personalInfoResponse.requestId) {
        // send files
        files.map(async (file) => {
          const formdata = new FormData();
          formdata.append('file', file.file);
          await fetch(
            `${host}/api/sendArtifact?requestId=${personalInfoResponse.requestId}&formType=${personalInfo.formType}`,
            {
              method: 'POST',
              body: formdata,
            },
          );
          setShouldShowThankYouMessage(true);
          actions.setSubmitting(false);
        });
      }
    }
  };

  return (
    <Box id='formContainer' minW={'1440px'} alignItems='center'>
      <Box ml='40px'>
        <Breadcrumb color='mountain.base' customLink={breadcrumbOverride} />
      </Box>
      {shouldShowErrorMessage ? (
        <ErrorMessage message={common('errorWhileSubmittingForm')} />
      ) : shouldShowThankYouMessage ? (
        <ThankYouFormBlock model={model} />
      ) : (
        <Formik
          initialValues={isComeBack ? values : initialValues}
          onSubmit={(_, actions) => {
            sendData(actions);
          }}
        >
          {(data) => (
            <Form style={{ paddingTop: '120px', paddingBottom: '60px' }}>
              {shouldShowConfirmation ? (
                <FormConfirmation
                  groups={groups.filter((item) => item.system.type === 'form_group') as FormGroup[]}
                  values={values}
                  files={files}
                  translate={TranslateJson}
                  editingDisabled={data.isSubmitting}
                  onGoBackClick={() => {
                    touchAllFields(data.values, data.setFieldTouched);
                    setShouldShowConfirmation(false);
                    setIsComeBack(true);
                  }}
                />
              ) : (
                <Flex flexDir='column' gap={{ base: 'm', md: 'l', lg: 'lr' }}>
                  {groups.map((group) => {
                    if (group.system.type === 'image_visual_breaker') {
                      return <ImageVisualBreaker key={group.system.id} model={group as ImageVisualBreakerModel} />;
                    }
                    return (group as FormGroup).elements.sections.linkedItems.map((section, index) => {
                      uniqueIndex == '' ? (uniqueIndex = section.elements.title.value) : null;
                      return (
                        <Container key={index} flexDir='column' gap='m' w='100vw' maxW={'1920px'} margin='auto'>
                          {section.elements.title.value && (
                            <Text
                              as={uniqueIndex == section.elements.title.value ? 'h1' : 'h2'}
                              margin={'0 auto'}
                              textStyle={{ base: `mobile.h3`, lg: `desktop.h3` }}
                              color={'cyan.web'}
                              textAlign={['center']}
                              mb={{ base: 's', lg: 'm' }}
                              px={{ base: 'xs', lg: 'l' }}
                            >
                              {section.elements.title.value}
                            </Text>
                          )}
                          <Flex
                            flexDir='column'
                            gap={section.elements.showGapBetweenSubsections.value[0]?.codename === 'yes' ? 's' : 'xxs'}
                          >
                            {section.elements.subsections.linkedItems.map((subsection, index) => {
                              const html = createRichTextHtmlResolver(nodeParser).resolveRichText({
                                element: subsection.elements.text,
                              }).html;
                              return (
                                <Box key={index}>
                                  {subsection.elements.title.value !== '' && (
                                    <Text
                                      as='h3'
                                      textStyle={'desktop.body.medium.l'}
                                      mb={{ base: 's', lg: 's' }}
                                      color={'cyan.web'}
                                    >
                                      {subsection.elements.title.value}
                                    </Text>
                                  )}
                                  <Flex flexDir='column' gap='s'>
                                    {html !== emptyBody && (
                                      <Flex
                                        textStyle={'desktop.body.l'}
                                        flexDir='column'
                                        gap='xs'
                                        sx={{
                                          'ul,ol': {
                                            pl: 'xs',
                                          },
                                          a: {
                                            textDecor: 'underline',
                                          },
                                        }}
                                        dangerouslySetInnerHTML={{ __html: html }}
                                      />
                                    )}
                                    {subsection.elements.downloadButtonText.value !== '' &&
                                      subsection.elements.fileFrontify.value && (
                                        <Link
                                          href={subsection.elements.fileFrontify.value}
                                          iconName='chevronRightForLink'
                                          type={LinkType.SecondaryButton}
                                          locale={router.locale}
                                        >
                                          {subsection.elements.downloadButtonText.value}
                                        </Link>
                                      )}
                                    {subsection.elements.formFields.linkedItems.length > 0 && (
                                      <Box
                                        display='grid'
                                        gridTemplateColumns={{
                                          base: 'repeat(1, auto)',
                                          md: `repeat(${
                                            subsection.elements.wrapFormFieldsIn2Columns.value[0].codename === 'yes'
                                              ? '2'
                                              : '1'
                                          }, 1fr)`,
                                        }}
                                        rowGap='15px'
                                        columnGap='25px'
                                        w={{
                                          base: '100%',
                                          md:
                                            subsection.elements.formFieldsFullWidth.value[0].codename === 'yes'
                                              ? '100%'
                                              : '90%',
                                          lg:
                                            subsection.elements.formFieldsFullWidth.value[0].codename === 'yes'
                                              ? '100%'
                                              : '80%',
                                        }}
                                      >
                                        {subsection.elements.formFields.linkedItems.map((formField, index) => {
                                          const fieldType = getFormFieldType(formField);
                                          const isUploadField =
                                            fieldType &&
                                            [FieldType.UploadMultiple, FieldType.UploadSingle].includes(fieldType);
                                          return (
                                            <Box key={index}>
                                              <FormField
                                                formField={formField}
                                                files={files}
                                                initialValue={
                                                  isComeBack
                                                    ? values[
                                                        `${isUploadField ? UPLOAD_FORM_FIELD_KEY : ''}${
                                                          formField.system.codename
                                                        }`
                                                      ]
                                                    : initialValues[
                                                        `${isUploadField ? UPLOAD_FORM_FIELD_KEY : ''}${
                                                          formField.system.codename
                                                        }`
                                                      ]
                                                }
                                                setFiles={setFiles}
                                              />
                                            </Box>
                                          );
                                        })}
                                      </Box>
                                    )}
                                  </Flex>
                                </Box>
                              );
                            })}
                          </Flex>
                        </Container>
                      );
                    });
                  })}
                </Flex>
              )}
              {/* send data button */}
              <Container centerContent w='min(780px, 100vw)' px={{ base: 'xs', '1md': '0px' }} pb={'117px'}>
                {shouldShowConfirmation && (
                  <Button
                    mt='s'
                    type='submit'
                    h='60px'
                    px='xs'
                    fontFamily={'HCo Gotham Rounded Medium'}
                    fontSize='18px'
                    lineHeight='20px'
                    fontWeight={350}
                    borderRadius={'4px'}
                    disabled={data.isSubmitting}
                    isLoading={data.isSubmitting}
                    loadingText={model.elements.loadingText.value}
                  >
                    {model.elements.submitButtonText.value}
                  </Button>
                )}
                {/* show confirmation button */}
                {!shouldShowConfirmation && !shouldShowThankYouMessage && (
                  <Button
                    mt='m'
                    type='button'
                    h='60px'
                    px='xs'
                    borderRadius={'4px'}
                    fontFamily={'HCo Gotham Rounded Medium'}
                    fontSize='18px'
                    lineHeight='20px'
                    fontWeight={350}
                    isDisabled={!data.dirty}
                    onClick={() => {
                      touchAllFields(data.values, data.setFieldTouched);
                      data
                        .validateForm()
                        .then(() => {
                          if (data.isValid && data.dirty) {
                            setShouldShowConfirmation(true);
                            setValues(data.values);
                            document.querySelector('#formContainer')?.scrollIntoView({
                              behavior: 'smooth',
                            });
                          }
                        })
                        .catch((err) => err);
                    }}
                  >
                    {model.elements.goToConfirmationButtonText.value}
                  </Button>
                )}
              </Container>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
