import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { IconComp } from 'components/base/IconComp';
import { Field } from 'formik';
import { InputFormField } from 'models';
import React, { FunctionComponent } from 'react';

import { getLabelName, validateInputField } from '../../utils/form/validation';

interface InputFieldProps {
  model: InputFormField;
}

export const InputField: FunctionComponent<InputFieldProps> = ({ model }) => {
  return (
    <Field name={model.system.codename} validate={validateInputField(model)}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[model.system.codename] && form.touched[model.system.codename]}>
          {model.elements.label.value !== '' && <FormLabel>{getLabelName(model)}</FormLabel>}
          <InputGroup>
            <Input
              type={model.elements.type.value[0].codename === 'number' ? 'text' : model.elements.type.value[0].codename}
              {...field}
              placeholder={model.elements.placeholder.value}
              textStyle='mobile.body.s'
              bg={'white'}
              color={'mountain.60'}
            />
            {form.errors[model.system.codename] && form.touched[model.system.codename] && (
              <InputRightElement>
                <IconComp iconName={'alert'} />
              </InputRightElement>
            )}
          </InputGroup>
          <FormErrorMessage>{form.errors[model.system.codename]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
