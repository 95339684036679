import { Box, Flex, VisuallyHidden } from '@chakra-ui/react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslations } from 'store/translations';

import { IconObject } from '../../lib/Icons';
import { IconComp } from './IconComp';

const shareData = [
  { iconName: 'facebook', url: 'https://www.facebook.com/share.php?u=' },
  { iconName: 'linkedin', url: 'https://www.linkedin.com/sharing/share-offsite/?url=' },
  { iconName: 'twitter', url: 'https://www.twitter.com/share?url=' },
];

const chakraStyleShareIcons = {
  _hover: {
    cursor: 'pointer',
  },
};

export const ShareButton: FunctionComponent = () => {
  const { aria, common } = withTranslations();

  const [canShare, setCanShare] = useState<boolean>();
  const [locationHref, setLocationHref] = useState<string>();

  useEffect(() => {
    setCanShare('share' in window.navigator);
    setLocationHref(location.href);
  }, []);

  const share = async () => {
    await navigator
      .share({
        title: document.title,
        url: location.href,
      })
      .catch(() => {
        return;
      });
  };

  return (
    <>
      <Box display={{ base: canShare ? 'unset' : 'none', lg: 'none' }}>
        <button onClick={async () => await share()}>
          <VisuallyHidden>{aria('shareOnSocialMedia')}</VisuallyHidden>
          <IconComp
            iconName='share'
            chakraStyle={{
              ...chakraStyleShareIcons,
              w: '44px',
              h: '44px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: '2px',
              borderRadius: '50%',
              borderColor: 'mountain.base',
            }}
          />
        </button>
      </Box>

      <Box display={{ base: canShare ? 'none' : 'unset', lg: 'unset' }}>
        <Flex dir='row' gap='xs'>
          {shareData.map((data) => {
            return (
              <a
                key={data.iconName}
                href={data.url + encodeURIComponent(locationHref ?? '')}
                rel='noopener noreferrer'
                target='_blank'
              >
                <VisuallyHidden>
                  {common('shareOn')} {data.iconName}
                </VisuallyHidden>
                <IconComp iconName={data.iconName as keyof typeof IconObject} chakraStyle={chakraStyleShareIcons} />
              </a>
            );
          })}
        </Flex>
      </Box>
    </>
  );
};
