import { Box, Container, Flex } from '@chakra-ui/react';
import { motion, useInView } from 'framer-motion';
import { htmlResolver } from 'helpers/htmlResolver';
import React, { FunctionComponent, useEffect, useRef } from 'react';

import { VideoVisualBreaker as VideoVisualBreakerModel } from '../../models/content-types/video_visual_breaker';
interface VideoVisualBreakerProps {
  model: VideoVisualBreakerModel;
}

const MotionBox = motion(Box);

export const VideoVisualBreaker: FunctionComponent<VideoVisualBreakerProps> = ({ model }) => {
  const shouldAutoplayOnDesktop = model.elements.autoplay.value[0]?.codename !== undefined;

  const headline = htmlResolver(model.elements.headline);
  // VideoVisualBreaker cannot use AspectRatio for all cases, thats why setting padding bottom of the div will cause it to be that aspect ratio
  const video = model.elements.video23EmbedLink.value
    .replaceAll('allowfullscreen', '')
    .replace(/padding-bottom:\s*\d+%/g, 'padding-bottom: 56.25%');
  // Use if we want to start affecting the iframe, videoSrc[1] is an attribute of the iframe to get it
  // To remove black line above video, to change player button, maybe initiate onClick on the iframe video to autoplay
  // const videoSrc = video.match(/<iframe.*?src=["'](.*?)["']/);
  const paddings = model.elements.paddings?.value.map((value) => value.codename);

  const setPaddings = () => {
    const top = paddings.filter((item) => item.includes('top')).join(',');
    const bottom = paddings.filter((item) => item.includes('bottom')).join(',');
    return {
      pt: top.includes('all') ? 0 : top.includes('half') ? { base: 'xs', lg: 's' } : { base: 's', lg: 'l' },
      pb: bottom.includes('all') ? 0 : bottom.includes('half') ? { base: 'xs', lg: 's' } : { base: 's', lg: 'l' },
    };
  };

  const ref = useRef<HTMLVideoElement>(null);
  const isInView = useInView(ref, {
    margin: '-25% 25%',
    once: true,
  });

  useEffect(() => {
    if (shouldAutoplayOnDesktop && isInView) {
      ref.current?.click();
    }
  }, [isInView]);

  return (
    <Container id={model.system.codename} w='100vw'>
      <Flex flexDir='column' sx={setPaddings()} gap={{ base: 'xs', md: 's' }}>
        {headline && (
          <Box
            as='h2'
            color={model.elements.headlineColor.value[0]?.codename === 'cyan' ? 'cyan.web' : 'mountain.base'}
            textStyle={{ base: `mobile.${headline[0].tag}`, md: `desktop.${headline[0].tag}` }}
            alignSelf={model.elements.headlinePosition?.value[0]?.codename}
            textAlign={model.elements.headlinePosition?.value[0]?.codename === 'left' ? 'start' : 'center'}
            maxWidth={{ base: '100%', lg: '70%' }}
            dangerouslySetInnerHTML={{ __html: headline[0].body }}
          />
        )}
        <Box w='100%' display='flex' justifyContent='center'>
          <MotionBox
            ref={ref}
            alignSelf='center'
            w='100%'
            initial={{ scale: 0.61 }}
            style={{ scale: isInView ? 1 : 0.61 }}
            transition='ease-out'
            transitionDuration='1s'
            overflow='hidden'
          >
            {model.elements.video23EmbedLink.value && (
              <Box id={`video-${model.system.id}`} dangerouslySetInnerHTML={{ __html: video }} />
            )}
          </MotionBox>
        </Box>
      </Flex>
    </Container>
  );
};
