import { Box, Container, Flex, Grid, GridItem, useMediaQuery } from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';
import { imageFormatter } from 'utils/imageFormatter';
import { hrefLink } from 'utils/normalization/hrefLink';

import { toTitleCase } from '../../helpers/textCase';
import { LinkType } from '../../lib/enums/LinkType';
import { ContentPage, ThemePageNavigation as ThemePageNavigationModel } from '../../models';
import { ContentPage as ContentPageModel } from '../../models/content-types/content_page';
import { Link } from '../base/Link';

interface ThemePageNavigationProps {
  model: ThemePageNavigationModel;
}

interface ThemePageNavigationItemProps {
  item: ContentPageModel;
  itemHeadlineSize: any;
  color: any;
  index: number;
  shouldHideCategory: boolean;
}

const ThemePageNavigationItem: FunctionComponent<ThemePageNavigationItemProps> = ({
  item,
  itemHeadlineSize,
  color,
  shouldHideCategory,
}) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)');
  const { common, category } = withTranslations();
  const translations = withTranslations();
  const router = useRouter();
  const image = imageFormatter(item.elements.summaryImageFrontify.value);
  const slug = router.query.slug || router.query.mainSubpage || router.query.subcategory;
  // const url = hrefLink(item, slug, true, translations.locale,translations);
  const url = hrefLink(
    item as ContentPage,
    (slug as unknown as string)?.split('/'),
    true,
    translations.locale,
    translations,
  );

  const categoryTitle =
    item.elements.category.value[0] === undefined
      ? ''
      : toTitleCase(category(item.elements.category.value[0].codename));
  return (
    <Flex flexDir='column' px='20px' h='100%'>
      <Box position='relative'>
        <Link href={url} type={LinkType.Text} locale={router.locale}>
          <Image
            width={image.width}
            height={isSmallScreen ? 320 : 426}
            src={image.src}
            alt={image.alt}
            style={{
              objectFit: 'cover',
              objectPosition: `${image.focalPoint[0] * 100}% ${image.focalPoint[1] * 100}%`,
              height: isSmallScreen ? 320 : 426,
            }}
            //objectFit='cover'
            //objectPosition={`${focalPoints[0] * 100}% ${focalPoints[1] * 100}%`}
          />
        </Link>
      </Box>
      <Flex flexDirection='column' h='100%' justifyContent='space-between' pb={{ base: 's', md: 's' }}>
        <Box>
          {!shouldHideCategory && (
            <Flex pb='xxs' color={color.text} textStyle={{ base: 'desktop.body.book.l' }}>
              {categoryTitle}
            </Flex>
          )}
          <Link href={url} type={LinkType.Text} locale={router.locale}>
            <Flex
              as={itemHeadlineSize}
              textStyle={{ base: `mobile.${itemHeadlineSize}`, md: `desktop.${itemHeadlineSize}` }}
              color={color.text}
              overflowWrap='anywhere'
            >
              {item.elements.summaryTitle.value}
            </Flex>
          </Link>
        </Box>

        {item.elements.url && (
          <Link
            href={url}
            type={LinkType.SecondaryButton}
            colorScheme={color.text}
            iconName='chevronRightForLink'
            locale={router.locale}
          >
            {common('learnMore')}
          </Link>
        )}
      </Flex>
    </Flex>
  );
};

export const ThemePageNavigation: FunctionComponent<ThemePageNavigationProps> = ({ model }) => {
  const [isSmallScreen] = useMediaQuery('(max-width: 1000px)');
  const elements = model.elements;
  const items = elements.amountOfNavigationItems.linkedItems as ContentPageModel[];
  const mainHeadline = elements.headline.value.length ? elements.headline.value : false;
  const itemHeadlineSize = elements.navigationItemHeadlineSize.value[0].codename;
  const shouldHideCategory = elements.hideCategories.value[0]?.codename === 'yes';
  const colors = [
    { bg: 'ocean.20', text: 'ocean.base' },
    { bg: 'forest.20', text: 'forest.base' },
    { bg: 'pebble.40', text: 'mountain.base' },
    { bg: 'ocean.20', text: 'ocean.base' },
  ];

  return (
    <Container
      id={model.system.codename}
      paddingInlineStart={{ base: '0', lg: '0' }}
      paddingInlineEnd={{ base: '0', lg: '0' }}
      ml={{ base: 0, md: '' }}
      pb={{ base: 's', md: 'm' }}
    >
      {mainHeadline && (
        <Box
          as='h3'
          py='s'
          pl={{ base: 'xs', lg: 'l' }}
          maxW='640px'
          textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }}
        >
          {mainHeadline}
        </Box>
      )}
      {isSmallScreen ? (
        <Flex
          flexDir='row'
          w='100%'
          overflowX='scroll'
          maxW='100vw'
          sx={{
            '::-webkit-scrollbar': {
              width: '3px',
              height: '3px',
            },
            '::-webkit-scrollbar-track': {
              width: '3px',
              height: '3px',
            },
            '::-webkit-scrollbar-thumb': {
              background: 'cyan.web',
              borderRadius: '0',
            },

            scrollbarColor: 'var(--chakra-colors-mountain-20) blue',
            scrollbarWidth: 'thin',
          }}
        >
          {items.map((item, index) => (
            <Flex
              key={index}
              minH={{ base: '600px', md: '800px' }}
              minW={{ base: '73%', lg: '0' }}
              bgGradient={`linear(to top, ${colors[index].bg} 80%, white 20%)`}
            >
              <ThemePageNavigationItem
                item={item}
                itemHeadlineSize={itemHeadlineSize}
                color={colors[index]}
                index={index}
                shouldHideCategory={shouldHideCategory}
              />
            </Flex>
          ))}
        </Flex>
      ) : (
        <Grid flexDir={'row'} pb='s' templateColumns={`repeat(${items.length}, 1fr)`} overflow='auto'>
          {items.map((item, index) => (
            <GridItem
              key={index}
              minH='800px'
              minW={{ base: '73%', lg: '0' }}
              bgGradient={`linear(to top, ${colors[index].bg} 80%, white 20%)`}
            >
              <ThemePageNavigationItem
                item={item}
                itemHeadlineSize={itemHeadlineSize}
                color={colors[index]}
                index={index}
                shouldHideCategory={shouldHideCategory}
              />
            </GridItem>
          ))}
        </Grid>
      )}
    </Container>
  );
};
