import { Box, Flex, Text } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import Image from 'next/image';
import React, { FunctionComponent } from 'react';
import { imageFormatter } from 'utils/imageFormatter';

import { LinkType } from '../../lib/enums/LinkType';
import { DownloadItem as DownloadItemModel } from '../../models/content-types/download_item';
import { IconComp } from '../base/IconComp';
import { Link } from '../base/Link';

interface DownloadItemProps {
  model: DownloadItemModel;
  flexDir: 'column' | 'row';
  iconBgColor: string;
}

export const DownloadItem: FunctionComponent<DownloadItemProps> = ({ model, flexDir, iconBgColor }) => {
  const altText = model.elements.alternativeText.value;
  const compressimage = model.elements.compressImage.value ? model.elements.compressImage.value : 75;
  const image = imageFormatter(model.elements.imageFrontify.value, altText);
  const fileFrontify = JSON.parse(model.elements.fileFrontify.value);
  const descriptionHtml = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: model.elements.description,
  }).html;
  return (
    <Flex
      //gap='xs'
      flexDir={{ base: flexDir, md: flexDir }}
      style={{
        display: 'flex',
        // flexDirection: "column",
        alignItems: 'flex-start',
        gap: '20px',
        flex: '1 0 0',
      }}
    >
      {model.elements.imageFrontify.value != null ? (
        <Image
          aria-label='image'
          src={image.src}
          alt={image.alt}
          style={{
            objectPosition: image.focalPoint
              ? `${image.focalPoint[0] * 100}% ${image.focalPoint[1] * 100}%`
              : '50% 50%',
            objectFit: 'cover',
          }}
          width={120}
          height={120}
          quality={compressimage}
        />
      ) : (
        <Flex bg='white' w='120px' h='120px' alignItems='center' justifyContent='center'>
          <IconComp
            aria-label='icon'
            iconName='file'
            chakraStyle={{
              // w: 'fit-content',
              // h: 'fit-content',
              bg: iconBgColor,
              p: 'xs',
              borderRadius: '50%',
              path: { fill: 'ocean.base' },
            }}
          />
        </Flex>
      )}
      <Flex
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '10px',
          alignSelf: 'stretch',
        }}
      >
        <Text
          as='h3'
          textStyle='desktop.body.medium.s'
          color={'mountain.base'}
          pb='xxs'
          style={{ alignSelf: 'stretch', fontWeight: 350 }}
        >
          {model.elements.headline.value}
        </Text>
        {descriptionHtml.replace(/<\/?[^>]+(>|$)/g, '').length > 0 && (
          <Box
            textStyle='desktop.body.s'
            color={'mountain.base'}
            dangerouslySetInnerHTML={{ __html: descriptionHtml }}
            pb='xs'
            style={{ alignSelf: 'stretch' }}
          />
        )}
        {fileFrontify && (
          <Link
            // textStyle='desktop.body.medium.s'
            href={fileFrontify.downloadUrl}
            type={LinkType.SecondaryButton}
            download={fileFrontify.downloadUrl}
            iconName='chevronRightForLink'
          >
            {model.elements.downloadButtonText.value}
          </Link>
        )}
      </Flex>
    </Flex>
  );
};
