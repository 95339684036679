import { Box, Flex, Text, VisuallyHidden } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { getButtonWithLinkStyling } from 'components/base/Button';
import { CheckboxFormField, RadioFormField } from 'models';
import { FormGroup } from 'models/content-types/form_group';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';
import { FieldType, getFormFieldType } from 'utils/form/fieldType';
import { getLocalizedDate } from 'utils/getLocalizedDate';

import { FileInfo, UPLOAD_FORM_FIELD_KEY } from './CustomForm';

interface FormConfirmationProps {
  groups: FormGroup[];
  onGoBackClick: () => void;
  values: any;
  files: FileInfo[];
  editingDisabled: boolean;
  translate: {};
}

export const FormConfirmation: FunctionComponent<FormConfirmationProps> = ({
  groups,
  onGoBackClick,
  values,
  files,
  editingDisabled,
}) => {
  const { aria, common, locale } = withTranslations();

  const filteredGroups = groups.filter((group) => group.elements.showInConfirmation.value[0].codename === 'yes');
  return (
    <Flex flexDir='column' minW={'1440px'} px={'80px'} textAlign={'center'}>
      <Flex flexDir='column' gap='100px'>
        {filteredGroups.map((group, groupIndex) => {
          return (
            <Flex key={groupIndex} flexDir='column' alignItems={'center'}>
              {groupIndex === filteredGroups.length - 1 && (
                <Flex mb='100px' flexDir='column' gap='m'>
                  <Text as='h2' textStyle={'desktop.h5'}>
                    {common('date')}
                  </Text>
                  <Text>
                    <VisuallyHidden>{aria('dateOfSumbitForm')}</VisuallyHidden>
                    {getLocalizedDate(undefined, locale)}
                  </Text>
                </Flex>
              )}
              <Flex key={groupIndex} flexDir='column' gap='m' w={'780px'}>
                <Flex justifyContent='space-between'>
                  <Text as='h2' textStyle={'desktop.h5'}>
                    {group.elements.confirmationTitle.value}
                  </Text>
                  {groupIndex !== filteredGroups.length - 1 &&
                    getButtonWithLinkStyling(
                      aria('goBackEditForm'),
                      common('editFields'),
                      onGoBackClick,
                      undefined,
                      editingDisabled,
                    )}
                </Flex>
                <Flex
                  borderColor='mountain.base'
                  borderBottom={groupIndex === filteredGroups.length - 1 ? '0px' : '1px'}
                  flexDir='column'
                  as='dl'
                  textStyle='mobile.body.s'
                  // w={'780px'}
                >
                  {group.elements.sections.linkedItems.map((section) => {
                    return section.elements.subsections.linkedItems.map((subsection, subsectionIndex) => {
                      if (subsection.elements.formFields.linkedItems.length > 0) {
                        return subsection.elements.formFields.linkedItems.map((field, fieldIndex) => {
                          switch (getFormFieldType(field)) {
                            case FieldType.Checkbox:
                              if (values[field.system.codename] === true) {
                                const html = createRichTextHtmlResolver(nodeParser).resolveRichText({
                                  element: (field as CheckboxFormField).elements.formOption.linkedItems[0].elements
                                    .label,
                                }).html;
                                return (
                                  <Box
                                    key={fieldIndex}
                                    textStyle={'desktop.body.l'}
                                    mb={'s'}
                                    sx={{
                                      a: {
                                        textDecor: 'underline',
                                      },
                                    }}
                                    dangerouslySetInnerHTML={{ __html: html }}
                                  />
                                );
                              }
                              return undefined;
                            case FieldType.Radio:
                              const option = (field as RadioFormField).elements.options.linkedItems.filter(
                                (option) =>
                                  createRichTextHtmlResolver(nodeParser)
                                    .resolveRichText({
                                      element: option.elements.label,
                                    })
                                    .html.replace(/<\/?[^>]+(>|$)/g, '') === values[field.system.codename],
                              );
                              const html = option[0]
                                ? createRichTextHtmlResolver(nodeParser).resolveRichText({
                                    element: option[0].elements.label,
                                  }).html
                                : '';

                              return (
                                <Flex
                                  key={fieldIndex}
                                  p='xxs'
                                  flexDir='row'
                                  borderTopWidth={subsectionIndex === 0 ? '1px' : '0px'}
                                  borderColor='mountain.base'
                                  borderLeftWidth={subsectionIndex % 2 !== 0 ? '1px' : '0px'}
                                  borderRightWidth={subsectionIndex % 2 !== 0 ? '1px' : '0px'}
                                >
                                  <Box w='50%' as='dt'>
                                    {field.elements.name.value}
                                  </Box>
                                  <Box w='50%' as='dd' dangerouslySetInnerHTML={{ __html: html }} />
                                </Flex>
                              );
                            case FieldType.Text:
                            case FieldType.Number:
                            case FieldType.Email:
                            case FieldType.Textarea:
                              return (
                                <Flex
                                  key={fieldIndex}
                                  p='xxs'
                                  flexDir='row'
                                  borderTopWidth={'1px'}
                                  borderColor='mountain.base'
                                  id='form-field'
                                  borderLeftWidth={fieldIndex % 2 !== 0 ? '1px' : '0px'}
                                  borderRightWidth={fieldIndex % 2 !== 0 ? '1px' : '0px'}
                                >
                                  <Box w='50%' as='dt'>
                                    {field.elements.name.value}
                                  </Box>
                                  <Box w='50%' as='dd'>
                                    {values[field.system.codename]}
                                  </Box>
                                </Flex>
                              );
                            case FieldType.UploadSingle:
                            case FieldType.UploadMultiple:
                              const filteredFiles = files.filter(
                                (file) => file.fieldName === UPLOAD_FORM_FIELD_KEY + field.system.codename,
                              );
                              return (
                                <Flex
                                  key={fieldIndex}
                                  p='xxs'
                                  flexDir='row'
                                  borderTopWidth={'1px'}
                                  borderColor='mountain.base'
                                  borderLeftWidth={subsectionIndex % 2 !== 0 ? '1px' : '0px'}
                                  borderRightWidth={subsectionIndex % 2 !== 0 ? '1px' : '0px'}
                                >
                                  <Box w='50%' as='dt'>
                                    {field.elements.name.value}
                                  </Box>
                                  <Box w='50%' as='dd'>
                                    {filteredFiles.length > 1 ? (
                                      <Box as='ol'>
                                        {filteredFiles.map((file, index) => {
                                          return (
                                            <Box key={index} ml='xs' as='li'>
                                              {file.fileName}
                                            </Box>
                                          );
                                        })}
                                      </Box>
                                    ) : filteredFiles.length > 0 ? (
                                      <Box>{filteredFiles[0].fileName}</Box>
                                    ) : undefined}
                                  </Box>
                                </Flex>
                              );
                          }
                        });
                      }
                    });
                  })}
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};
