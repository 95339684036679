import { AspectRatio, Box, Container, Flex, List, ListItem, Text } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import Image from 'next/image';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { imageFormatter } from 'utils/imageFormatter';

import { emptyBody, resolveHtml } from '../../helpers/htmlResolver';
import { LayeredContent as LayeredContentModel, Tab as TabModel } from '../../models';
import { IconComp } from '../base/IconComp';
import { Tab } from '../base/Tab';
interface LayeredContentProps {
  model: LayeredContentModel;
}

interface TabData {
  index: number;
  headline: string;
  bodyText: string;
  imageData?: ReturnType<typeof imageFormatter>;
  isreadmorelessc: string;
}

// ---------------- TAB DATA & SCROLL ----------------
const scrollBtnWidth = 210;
const scrollBtnWidthMobile = 80;
const tabHeight = 47;
const scrollAmount = 550;

const getTabData = (index: number, tab: TabModel) => {
  const bodyText = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: tab.elements.bodyText1,
    urlResolver: (linkId, linkText, link) => {
      return {
        linkHtml: `<a class="xLink" href="/${link?.urlSlug}">${linkText}</a>`,
      };
    },
  }).html;
  const bodyText2Html = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: tab.elements.bodyText2,
    urlResolver: (linkId, linkText, link) => {
      return {
        linkHtml: `<a class="xLink" href="/${link?.urlSlug}">${linkText}</a>`,
      };
    },
  }).html;
  const doesBodyText2Exist = !bodyText2Html.match(emptyBody);
  const isreadmoreless = tab.elements.isReadMoreLessRequired.value[0] ? 'yes' : 'no';
  const compressimage = tab.elements.compressImage.value ? tab.elements.compressImage.value : 75;
  const tabData = {
    index,
    headline: tab.elements.headline.value,
    bodyText: doesBodyText2Exist
      ? resolveHtml(bodyText).body + resolveHtml(bodyText2Html).body
      : resolveHtml(bodyText).body,
    imageData: doesBodyText2Exist
      ? undefined
      : imageFormatter(tab.elements.imageFrontify?.value, tab.elements.alternativeText?.value, false, compressimage),
    isreadmorelessc: isreadmoreless,
    quality: compressimage,
  } as TabData;

  return tabData;
};
// ---------------- TAB DATA & SCROLL END ----------------

export const LayeredContent: FunctionComponent<LayeredContentProps> = ({ model }) => {
  // ---------------- VARIABLES & FUNCTIONS ----------------
  // const [isSmallScreen] = useMediaQuery('(max-width: 768px)');
  const scrollContainerRef = useRef<null | HTMLDivElement>(null);

  const [shouldShowScrollLeft, setShouldShowScrollLeft] = useState<boolean>(false);
  const [shouldShowScrollRight, setShouldShowScrollRight] = useState<boolean>(false);
  const [tabData, setTabData] = useState<TabData>(getTabData(0, model.elements.tabs.linkedItems[0]));

  const [scrollContainerWidth, setScrollContainerWidth] = useState<number>();
  const [tabIndex, setTabIndex] = useState(0);
  const autoTabSwitch = model.elements.automaticTabSwitch?.value[0]?.codename;
  //------------------- View More or Less -------------------------
  const IsReadMoreLess = tabData.isreadmorelessc; //model.elements.isReadMoreLessRequired.value[0]?.name;
  const [isReadMoreLessDesc, setReadMoreLessDesc] = useState(false);

  const showFullDescriptionHandler = () => {
    setReadMoreLessDesc(!isReadMoreLessDesc);
  };

  //--------------------View More or Less End ---------------------

  const scrollRight = () => {
    scrollContainerRef.current?.scroll({
      top: 0,
      left: scrollContainerRef.current?.scrollLeft + scrollAmount,
      behavior: 'smooth',
    });
  };
  const scrollLeft = () => {
    scrollContainerRef.current?.scroll({
      top: 0,
      left: scrollContainerRef.current?.scrollLeft - scrollAmount,
      behavior: 'smooth',
    });
  };
  // const tabSwitch = model.elements.automaticTabSwitch?.value[0]?.codename == "yes" ? true : false;
  // console.log(tabSwitch);
  useEffect(() => {
    // setautoTabSwitch(model.elements.automaticTabSwitch?.value[0]?.codename);
    if (autoTabSwitch == 'yes') {
      //  scrollContainerRef.current!.scrollLeft += 210
      tabData.index == 1 || tabData.index == 0
        ? (scrollContainerRef.current!.scrollLeft = 0)
        : scrollContainerRef.current?.scroll({
            top: 0,
            left: scrollContainerRef.current?.scrollLeft + scrollAmount,
            behavior: 'smooth',
          });
      const interval = setInterval(() => {
        // Calculate the next tab index
        const nextIndex = (tabData.index + 1) % model.elements.tabs.linkedItems.length;
        setTabIndex(nextIndex);
        setTabData(getTabData(nextIndex, model.elements.tabs.linkedItems[nextIndex]));
      }, 5000); // Switch tabs every 5 seconds (adjust this value as needed)

      return () => clearInterval(interval); // Cleanup on unmount
    }
  }, [tabIndex, tabData.index, model.elements.tabs.linkedItems]);
  useEffect(() => {
    setScrollContainerWidth(scrollContainerRef.current?.clientWidth);
    window.addEventListener('resize', () => {
      setScrollContainerWidth(scrollContainerRef.current?.clientWidth);
    });
    const isOverflowing =
      scrollContainerRef.current != undefined &&
      scrollContainerRef.current?.clientWidth < scrollContainerRef.current?.scrollWidth;
    setShouldShowScrollRight(isOverflowing);
  }, []);

  scrollContainerRef.current?.addEventListener('wheel', (event: WheelEvent) => {
    if (event.deltaY) {
      event.preventDefault();
      scrollContainerRef.current?.scroll({
        top: 0,
        left: scrollContainerRef.current?.scrollLeft + event.deltaY,
        behavior: 'smooth',
      });
    }
  });
  scrollContainerRef.current?.addEventListener('scroll', () => {
    const scrollLeft = scrollContainerRef.current!.scrollLeft;
    const scrollWidth = scrollContainerRef.current!.scrollWidth;
    const clientWidth = scrollContainerRef.current!.clientWidth;

    setShouldShowScrollLeft(scrollLeft === 0 ? false : true);
    setShouldShowScrollRight(scrollWidth - scrollLeft <= clientWidth + 5 ? false : true);
  });
  // ---------------- VARIABLES & FUNCTIONS END ----------------

  return (
    <Container
      id={model.system.codename}
      display='flex'
      flexDir='column'
      px={{ base: 'xs', md: 'l' }}
      py={{ base: 's', md: 'l' }}
      paddingInlineStart={{ base: 'xs', sm: 'xs', md: 'xs', lg: 'l' }}
      paddingInlineEnd={{ base: 'xs', sm: 'xs', md: 'xs', lg: 'l' }}
      w='100vw'
    >
      <Text as='h2' textStyle={{ base: 'mobile.body.l', md: 'desktop.h5' }} pb='60px'>
        {model.elements.headline.value}
      </Text>
      {model.elements.subHeadline.value && (
        <Text
          as='h3'
          textStyle={{ base: 'mobile.h4', md: 'desktop.h4' }}
          gridArea={'title'}
          pb={{ base: 's', lg: 'm' }}
        >
          {model.elements.subHeadline.value}
        </Text>
      )}
      {/* ---------------- NAVIGATION ---------------- */}
      <Box
        ref={scrollContainerRef}
        position='relative'
        height={tabHeight}
        overflowX='auto'
        sx={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box
          tabIndex={-1}
          aria-hidden='true'
          display={shouldShowScrollLeft ? 'unset' : 'none'}
          background='linear-gradient(90deg, rgba(255,255,255,1) 20%, rgba(0,0,0,0) 100%)'
          as='button'
          zIndex={7}
          position='sticky'
          top='0'
          left='0'
          w={`${scrollBtnWidth}px`}
          h='75%'
          onClick={scrollLeft}
        >
          <IconComp
            iconName='chevronLeft'
            chakraStyle={{
              path: {
                stroke: 'mountain.base',
              },
              h: '24px',
              w: '24px',
            }}
          />
        </Box>
        {scrollContainerWidth && (
          <Box
            tabIndex={-1}
            aria-hidden='true'
            as='button'
            display={shouldShowScrollRight ? 'unset' : 'none'}
            background='linear-gradient(270deg, rgba(255,255,255,1) 20%, rgba(0,0,0,0) 100%)'
            zIndex={7}
            position='sticky'
            top='0'
            left={{ base: scrollContainerWidth - scrollBtnWidthMobile, md: scrollContainerWidth - scrollBtnWidth }}
            w={{ base: `${scrollBtnWidthMobile}px`, md: `${scrollBtnWidth}px` }}
            h='75%'
            //disabled={isSmallScreen}
            onClick={scrollRight}
          >
            <Flex justifyContent={'end'}>
              <IconComp
                iconName='chevronRight'
                chakraStyle={{
                  path: {
                    stroke: 'mountain.base',
                  },
                  h: '24px',
                  w: '24px',
                }}
              />
            </Flex>
          </Box>
        )}
        <Box as='nav'>
          <List id='row' w='fit-content' display='flex' flexDir='row' position='absolute' top='0' left='0'>
            {model.elements.tabs.linkedItems.map((tab, index) => {
              return (
                <ListItem
                  key={index}
                  w='auto'
                  px='xs'
                  borderBottom={'1px solid'}
                  borderColor={'mountain.20'}
                  _first={{ paddingLeft: '0' }}
                  _last={{ paddingRight: '0' }}
                  id='tab-nav'
                >
                  {autoTabSwitch == 'yes' ? (
                    <div>
                      <Tab
                        ariaControls='tabContent'
                        active={index === tabData.index}
                        text={tab.elements.headline.value}
                        onClick={() => {
                          setTabData(getTabData(index, tab));
                        }}
                      />
                    </div>
                  ) : (
                    <Tab
                      ariaControls='tabContent'
                      active={index === tabData.index}
                      text={tab.elements.headline.value}
                      onClick={() => {
                        setTabData(getTabData(index, tab));
                      }}
                    />
                  )}
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
      {/* ---------------- NAVIGATION END ---------------- */}

      {/* ---------------- CONTENT ---------------- */}
      <Flex id='tabContent' direction={{ base: 'column', lg: 'row' }} w='100%' pt={{ base: 's', md: 'm' }}>
        <Box
          w={{ base: '100%', lg: tabData.imageData ? 'calc(33% + 80px)' : 'calc(55% + 80px)' }}
          pr={{ base: 'unset', lg: 'l' }}
        >
          <Text
            as='h3'
            textStyle={{ base: 'mobile.h4', md: 'desktop.h4' }}
            gridArea={'title'}
            pb={{ base: 's', lg: 'm' }}
          >
            {tabData.headline}
          </Text>
          <Flex
            display={{ base: 'none', lg: 'unset' }}
            gridArea={'description'}
            flexDir='column'
            textStyle='desktop.body.l'
            gap='xs'
            dangerouslySetInnerHTML={
              IsReadMoreLess == 'yes'
                ? { __html: isReadMoreLessDesc ? tabData.bodyText : tabData.bodyText.slice(0, 300).concat('.....') }
                : { __html: tabData.bodyText }
            }
          />
          {IsReadMoreLess == 'yes' ? (
            <a
              style={{
                color: 'var(--chakra-colors-cyan-ramboll)',
                cursor: 'pointer',
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
              }}
              className='xLink'
              onClick={showFullDescriptionHandler}
            >
              {' '}
              {isReadMoreLessDesc ? 'Read less' : 'Read more'}
            </a>
          ) : (
            <a> </a>
          )}
        </Box>
        {tabData.imageData && (
          <Box w={{ base: '100%', lg: '70%' }}>
            <AspectRatio ratio={16 / 9} w='100%'>
              <Image
                style={{
                  objectFit: 'cover',
                  objectPosition: tabData.imageData.focalPoint
                    ? `${tabData.imageData.focalPoint[0] * 100}% ${tabData.imageData.focalPoint[1] * 100}%`
                    : '50% 50%',
                  gridArea: 'image',
                }}
                width={tabData.imageData.width}
                height={tabData.imageData.height}
                src={tabData.imageData.src}
                alt={tabData.imageData.alt}
                quality={tabData.imageData.quality}
              />
            </AspectRatio>
          </Box>
        )}
        <Flex
          pt={tabData.imageData && 's'}
          display={{ base: 'unset', lg: 'none' }}
          gridArea={'description'}
          flexDir='column'
          textStyle='desktop.body.l'
          gap='xs'
          dangerouslySetInnerHTML={{
            __html: isReadMoreLessDesc ? tabData.bodyText : tabData.bodyText.slice(0, 300).concat('.....'),
          }}
        />
      </Flex>
      {/* ---------------- CONTENT END ---------------- */}
    </Container>
  );
};
