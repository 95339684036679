import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';
import { Field } from 'formik';
import { InputFormField } from 'models';
import React, { FunctionComponent } from 'react';

import { getLabelName, validateInputField } from '../../utils/form/validation';

interface TextareaFieldProps {
  model: InputFormField;
}

export const TextareaField: FunctionComponent<TextareaFieldProps> = ({ model }) => {
  return (
    <Field name={model.system.codename} validate={validateInputField(model)}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[model.system.codename] && form.touched[model.system.codename]}>
          {model.elements.label.value !== '' && <FormLabel>{getLabelName(model)}</FormLabel>}
          <Textarea
            {...field}
            placeholder={model.elements.placeholder.value}
            textStyle='mobile.body.s'
            bg={'white'}
            color={'mountain.60'}
          />
          <FormErrorMessage>{form.errors[model.system.codename]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
