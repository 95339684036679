// import { useRouter } from 'next/router';
import { Box, Text } from '@chakra-ui/react';
import { QuestionBlock } from 'components/modules/QuestionBlock';
import { QuestionBlockMobile } from 'components/modules/QuestionBlockMobile';
import React, { FunctionComponent } from 'react';

// import { withTranslations } from 'store/translations';
import { DecisionTree as DecisionTreeModel } from '../../models';
interface DecisionTreeProps {
  model: DecisionTreeModel;
}
export const DecisionTree: FunctionComponent<DecisionTreeProps> = ({ model }) => {
  // const translations = withTranslations();
  // const router = useRouter();
  // console.log('Decision tree - ',model)
  const data = {
    headline: model.elements.headline.value,
    subheadline: model.elements.subheading.value,
    questions: model.elements.showcasedQuestions.linkedItems[0],
    //  firstQuestion: model.elements.firstQuestion.value
  };

  return (
    <>
      <Box
        id={model.system.codename}
        //  w='1440px'
        // h='1376px'
        //   bg='pebble.20'
        w='100vw'
        maxW={'1440px'}
        mx='auto'
        style={{
          display: 'flex',
          padding: '120px 80px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '60px',
          flexShrink: 0,
          backgroundColor: '#F9F9F7',
        }}
        px={{ base: 'xs', md: 's', lg: 's' }}
      >
        <Text
          mt='xs'
          maxWidth={{ base: '100%', lg: '780px' }}
          // textStyle={'desktop.h3'}
          textStyle={{ base: `mobile.h3`, md: `desktop.h3` }}
          // w='780px'
          style={{ textAlign: 'center' }}
          // color='mountain'
        >
          {data.headline}
        </Text>
        <Text
          mt='xs'
          // textStyle={'desktop.h5'}
          textStyle={{ base: `mobile.h5`, md: `desktop.h5` }}
          //  w='780px'
          style={{ textAlign: 'center', color: 'mountain' }}
          // color='mountain'
          maxWidth={{ base: '100%', lg: '780px' }}
        >
          {data.subheadline}
        </Text>
        <QuestionBlock model={data.questions} />
        <QuestionBlockMobile model={data.questions} />
      </Box>
    </>
  );
};
