import { Spacing } from 'components/base/Spacing';
import { ArticleHero } from 'components/modules/ArticleHero';
import { AtAGlance } from 'components/modules/AtAGlance';
import { ColorBlockHero } from 'components/modules/ColorBlockHero';
import { ContactGroupItem } from 'components/modules/ContactGroupItem';
import { ContactPersonBlock } from 'components/modules/ContactPersonBlock';
import { CustomForm } from 'components/modules/CustomForm';
import { DecisionTree } from 'components/modules/DecisionTree';
import { Download } from 'components/modules/Download';
import { DownloadItem } from 'components/modules/DownloadItem';
import { EditorialSpots } from 'components/modules/EditorialSpots';
import { ExpertBlock } from 'components/modules/ExpertBlock';
import { Fact } from 'components/modules/Fact';
import { Factbox } from 'components/modules/Factbox';
import { FAQ } from 'components/modules/FAQ';
import { FeaturedContent } from 'components/modules/FeaturedContent';
import { FormHeroWithAsset } from 'components/modules/FormHeroWithAsset';
import { FormHeroWithText } from 'components/modules/FormHeroWithText';
import { FrontpageCarouselHero } from 'components/modules/FrontpageCarouselHero';
import { ImageGallery } from 'components/modules/ImageGallery';
import { ImageVisualBreaker } from 'components/modules/ImageVisualBreaker';
import { InbuiltForm } from 'components/modules/InbuiltForm';
import { LayeredContent } from 'components/modules/LayeredContent';
import { MediaBackgroundHero } from 'components/modules/MediaBackgroundHero';
import { MediaContent } from 'components/modules/MediaContent';
import { NarrowTextHero } from 'components/modules/NarrowTextHero';
import { OverviewBlock } from 'components/modules/OverviewBlock';
import { OverviewSpotAutomatic } from 'components/modules/OverviewSpotAutomatic';
import { ProjectHero } from 'components/modules/ProjectHero';
import { QuoteBlock } from 'components/modules/QuoteBlock';
import { StandaloneForm } from 'components/modules/StandaloneForm';
import { Team } from 'components/modules/Team';
import { TextModule } from 'components/modules/TextModule';
import { ThemePageNavigation } from 'components/modules/ThemePageNavigation';
import { VideoVisualBreaker } from 'components/modules/VideoVisualBreaker';
import { NavBar } from 'components/nav/NavBar';

export enum ContentType {
  article_hero = 'article_hero',
  at_a_glance = 'at_a_glance',
  color_block_hero = 'color_block_hero',
  contact_group_item = 'contact_group_item',
  contact_person_block = 'contact_person_block',
  download = 'download',
  download_item = 'download_item',
  editorial_spots = 'editorial_spots',
  expert_block = 'expert_block',
  fact = 'fact',
  factbox = 'factbox',
  faq = 'faq',
  featured_spots = 'featured_spots',
  frontpage_carousel_hero = 'frontpage_carousel_hero',
  image_gallery = 'image_gallery',
  image_visual_breaker = 'image_visual_breaker',
  layered_content = 'layered_content',
  media_background_hero = 'media_background_hero',
  media_content = 'media_content',
  narrow_text_hero = 'narrow_text_hero',
  navigation = 'navigation',
  overview_spot___automatic = 'overview_spot___automatic',
  quote = 'quote',
  team = 'team',
  text = 'text',
  theme_page_navigation = 'theme_page_navigation',
  video_visual_breaker = 'video_visual_breaker',
  overview = 'overview',
  form_hero_with_text = 'form_hero_with_text',
  form_hero_with_asset = 'form_hero_with_asset',
  standalone_form = 'standalone_form',
  inbuilt_form = 'inbuilt_form',
  project_hero = 'project_hero',
  spacing = 'spacing',
  custom_form = 'custom_form',
  cookie_bot = 'cookie_bot',
  decision_tree = 'decision_tree',
}

export const mapper = (type: ContentType) => {
  switch (type) {
    // Static imports (for purpose of search engine and web scrapers)
    case ContentType.layered_content:
      return LayeredContent;
    case ContentType.at_a_glance:
      return AtAGlance;
    case ContentType.fact:
      return Fact;
    case ContentType.factbox:
      return Factbox;
    case ContentType.quote:
      return QuoteBlock;
    case ContentType.text:
      return TextModule;
    case ContentType.faq:
      return FAQ;
    case ContentType.navigation:
      return NavBar;
    case ContentType.decision_tree:
      return DecisionTree;
    case ContentType.article_hero:
      return ArticleHero;
    case ContentType.color_block_hero:
      return ColorBlockHero;
    case ContentType.contact_group_item:
      return ContactGroupItem;
    case ContentType.contact_person_block:
      return ContactPersonBlock;
    case ContentType.download:
      return Download;
    case ContentType.editorial_spots:
      return EditorialSpots;
    case ContentType.video_visual_breaker:
      return VideoVisualBreaker;
    case ContentType.image_gallery:
      return ImageGallery;
    case ContentType.image_visual_breaker:
      return ImageVisualBreaker;
    case ContentType.narrow_text_hero:
      return NarrowTextHero;
    case ContentType.expert_block:
      return ExpertBlock;
    case ContentType.featured_spots:
      return FeaturedContent;
    case ContentType.overview_spot___automatic:
      return OverviewSpotAutomatic;
    case ContentType.media_background_hero:
      return MediaBackgroundHero;
    case ContentType.media_content:
      return MediaContent;
    case ContentType.frontpage_carousel_hero:
      return FrontpageCarouselHero;
    case ContentType.download_item:
      return DownloadItem;
    case ContentType.overview:
      return OverviewBlock;
    case ContentType.form_hero_with_text:
      return FormHeroWithText;
    case ContentType.form_hero_with_asset:
      return FormHeroWithAsset;
    case ContentType.standalone_form:
      return StandaloneForm;
    case ContentType.inbuilt_form:
      return InbuiltForm;
    case ContentType.team:
      return Team;
    case ContentType.theme_page_navigation:
      return ThemePageNavigation;
    case ContentType.project_hero:
      return ProjectHero;
    case ContentType.spacing:
      return Spacing;
    case ContentType.custom_form:
      return CustomForm;
  }
};
