import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  StackItem,
  VStack,
} from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { useRouter } from 'next/router';
import { normalize } from 'pages/api/sitemap';
import React, { FC, useEffect, useState } from 'react';
import { withTranslations } from 'store/translations';

import { LinkType } from '../../lib/enums/LinkType';
import { ContentPage, FAQ as FAQModel } from '../../models';
import { IconComp } from '../base/IconComp';
import { Link } from '../base/Link';

export const FAQ: FC<{ model: FAQModel }> = ({ model }) => {
  const router = useRouter();
  const translations = withTranslations();
  const [items, setItems] = useState<
    {
      ctaText?: string | undefined;
      ctaUrl?: any;
      ctaOpenInNewTab?: boolean | undefined;
      headline: string;
      bodyHtml: string;
    }[]
  >();

  const fetchItems = async () => {
    const items = await Promise.all(
      model.elements.items.linkedItems.map(async (item) => {
        const externalUrl = item.elements.ctaSnippetUrl.value;
        const kontentAiItem = item.elements.ctaSnippetKontentAiUrl.value[0];
        const fileFrontify = JSON.parse(item.elements.ctaSnippetFrontifyFile.value);
        const setUrl = async () => {
          if (fileFrontify) return fileFrontify.downloadUrl as string;
          if (externalUrl) return externalUrl;
          if (kontentAiItem) {
            const item = (await fetch(`/api/sitemap?codename=${kontentAiItem}&locale=${router.locale}`)
              .then((res) => res.json())
              .catch(() => null)) as ContentPage | null;
            return item ? Object.keys(normalize(item, router.locale, undefined, true, translations))?.[0] : '';
          }
        };
        return {
          headline: item.elements.headline.value,
          bodyHtml: createRichTextHtmlResolver(nodeParser).resolveRichText({
            element: item.elements.body,
            urlResolver: (linkId, linkText, link) => {
              return {
                linkHtml: `<a class="xLink" href="/${link?.urlSlug}">${linkText}</a>`,
              };
            },
          }).html,
          ...((externalUrl || fileFrontify || kontentAiItem) && {
            ctaText: item.elements.ctaSnippetCtaText.value,
            ctaUrl: await setUrl(),
            ctaOpenInNewTab: item.elements.ctaSnippetOpenIn.value[0].codename === 'new_tab',
          }),
        };
      }),
    );
    setItems(items);
  };

  useEffect(() => {
    fetchItems();
  }, []);
  useEffect(() => {
    fetchItems();
  }, [router.locale]);
  const data = {
    headline: model.elements.headline.value,
    items,
  };
  return (
    <Box w={{ base: '100vw', '1md': '780px' }} px={{ base: 'xs', '1md': '0px' }} id={model.system.codename}>
      <VStack my={{ base: 's', md: 'l' }}>
        <StackItem as='h2' textStyle={{ base: 'mobile.h3', md: 'desktop.h3' }} color='cyan.ramboll' mb='m'>
          {data.headline}
        </StackItem>
        <Accordion allowToggle w='100%' flexDirection={'column'}>
          {data.items?.map((item, index) => (
            <AccordionItem
              key={index}
              borderTop={'1px solid transparent'}
              borderBottom={'1px solid'}
              borderBottomColor={'mountain.20'}
              pb='xs'
              pt={index && 's'}
              w='100%'
            >
              {({ isExpanded }) => {
                return (
                  <>
                    <AccordionButton
                      textAlign='start'
                      as={'button'}
                      _hover={{ cursor: 'pointer' }}
                      justifyContent='space-between'
                      p='0'
                      textStyle={{ base: 'desktop.body.book.s', md: 'desktop.body.book.l' }}
                      w='100%'
                    >
                      {item.headline}
                      <Flex ml='xs'>
                        <IconComp styleName='active' iconName={isExpanded ? 'minus' : 'plus'} />
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel textStyle={{ base: 'desktop.body.book.s', md: 'desktop.body.l' }} p='0'>
                      <Flex
                        mt='xs'
                        flexDir='column'
                        gap='xs'
                        sx={{
                          a: {
                            color: 'cyan.ramboll',
                            textDecor: 'underline',
                          },
                          'ul, ol': {
                            listStylePosition: 'inside',
                          },
                        }}
                        dangerouslySetInnerHTML={{ __html: item.bodyHtml }}
                      />
                      {item.ctaUrl && (
                        <Box mt='xs'>
                          <Link
                            type={LinkType.SecondaryButton}
                            href={item.ctaUrl}
                            isExternal={item.ctaOpenInNewTab}
                            iconName='chevronRightForLink'
                            locale={router.locale}
                          >
                            {item.ctaText}
                          </Link>
                        </Box>
                      )}
                    </AccordionPanel>
                  </>
                );
              }}
            </AccordionItem>
          ))}
        </Accordion>
      </VStack>
    </Box>
  );
};
