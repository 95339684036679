import { Box, Button, Container, Flex, Heading, Text } from '@chakra-ui/react';
import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { colorScheme } from 'lib/colorScheme';
import Image from 'next/image';
import React, { FunctionComponent, useState } from 'react';
import { imageFormatter } from 'utils/imageFormatter';
import { FormHeroWithTextData } from 'utils/types/formData';

import { FormHeroWithText as FormHeroWithTextModel } from '../../models/content-types/form_hero_with_text';
import { FormWrapper } from './FormWrapper';

interface FormHeroWithTextProps {
  model: FormHeroWithTextModel;
  onSubmit?: () => void;
}

export const FormHeroWithText: FunctionComponent<FormHeroWithTextProps> = ({ model, onSubmit }) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  const formDataObj = JSON.parse(model.elements.hubspotFormSnippetForm.value);
  const headline = model.elements.headline.value;
  const title = model.elements.title.value;
  const backgroundSize = model.elements.backgroundSize.value[0].codename;
  const backgroundColor = model.elements.backgroundColor.value;
  const textPosition = model.elements.textPosition.value[0].codename;
  const shouldShowTextAfterSubmit = model.elements.showTextAfterSubmit.value[0].codename === 'yes';
  const downloadItem = model.elements.downloadItem.linkedItems;

  const textHtml = createRichTextHtmlResolver(nodeParser).resolveRichText({
    element: model.elements.text,
  }).html;

  const thankImgAlt = model.elements.altTextThankyouImage?.value;
  const thankYouImageData = imageFormatter(model.elements.frontifyThankYouImage?.value, thankImgAlt);
  const thankBgAlt = model.elements.altTextBackgroundImage?.value;
  const thankYouBackgroundImageData = imageFormatter(model.elements.frontifyThankYouBackgroundImage?.value, thankBgAlt);
  const bgColor = colorScheme[backgroundColor[0].codename as keyof typeof colorScheme].base;

  const data = {
    formId: formDataObj.FormGUID,
    portalId: formDataObj.PortalID,
    backgroundSize: backgroundSize,
    backgroundColor: bgColor,
    headline: headline,
    title: title != '' ? title : undefined,
    showTextAfterSubmit: shouldShowTextAfterSubmit,
    textPosition: textPosition,
    thankYouImage: thankYouImageData,
    thankImgAlt: thankImgAlt,
    thankYouBackgroundImage: thankYouBackgroundImageData,
    thankBgAlt: thankBgAlt,
    downloadItem: downloadItem.length
      ? {
          fileUrl: JSON.parse(downloadItem[0].elements.fileFrontify.value).downloadUrl,
          descriptionHtml: createRichTextHtmlResolver(nodeParser).resolveRichText({
            element: downloadItem[0].elements.description,
          }).html,
          buttonText: downloadItem[0].elements.headline.value,
        }
      : undefined,
    submittedMessageAlignment: model.elements.thankYouMessageAlignment.value[0].codename,
    fieldColor: backgroundSize === 'full' && bgColor !== 'white' ? 'white' : 'gray.98',
  } as FormHeroWithTextData;

  return (
    <Container id={model.system.codename} px='0'>
      <Flex
        backgroundSize='cover'
        backgroundImage={
          hasBeenSubmitted && data.thankYouBackgroundImage
            ? `linear-gradient(90deg, rgba(39, 57, 67, 0.4) 41.95%, rgba(39, 57, 67, 0) 64.08%), url(${data.thankYouBackgroundImage.src})`
            : ''
        }
        w='100%'
        flexDir={{ base: 'column', lg: data.textPosition === 'left' ? 'row' : 'row-reverse' }}
      >
        {/* text */}
        {(!hasBeenSubmitted || data.showTextAfterSubmit) && (
          <Box
            bg={hasBeenSubmitted && data.thankYouBackgroundImage?.src ? '' : data.backgroundColor}
            w={{ base: '100%', lg: '43%' }}
            px={{ base: 'xs', lg: 'l' }}
            py={{ base: 's', lg: '100px' }}
            color={hasBeenSubmitted && data.thankYouBackgroundImage ? 'white' : 'mountain.base'}
          >
            <Box h='fit-content' position={{ base: 'static', lg: 'sticky' }} top={0}>
              <Heading as='h1' textStyle={{ base: 'mobile.h3', lg: 'desktop.h3' }} mb='50px'>
                {data.headline}
              </Heading>
              {data.title && (
                <Text textStyle={'desktop.body.medium.l'} fontWeight='bold' mb='xxs'>
                  {data.title}
                </Text>
              )}
              <Flex
                flexDir={'column'}
                gap='xs'
                textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.s' }}
                dangerouslySetInnerHTML={{ __html: textHtml }}
                sx={{
                  ul: {
                    listStyle: 'inside',
                  },
                }}
              />
            </Box>
          </Box>
        )}
        {/* form */}
        <Box
          w={{ base: '100%', lg: !data.showTextAfterSubmit && hasBeenSubmitted ? '100%' : '57%' }}
          px={{ base: 'xs', lg: 'l' }}
          py={{ base: 's', lg: '100px' }}
          bg={
            hasBeenSubmitted && data.thankYouBackgroundImage
              ? ''
              : data.backgroundSize === 'full' || hasBeenSubmitted
              ? data.backgroundColor
              : 'white'
          }
        >
          <Flex h='100%' flexDir='column' justifyContent='space-between'>
            {hasBeenSubmitted && data.thankYouImage && !data.showTextAfterSubmit && (
              <Box
                mb='l'
                w='fit-content'
                h='fit-content'
                mx={data.submittedMessageAlignment === 'center' ? 'auto' : ''}
              >
                <Image
                  width={60}
                  height={70}
                  src={data.thankYouImage.src}
                  alt={data.thankYouImage.alt}
                  style={{ objectFit: 'cover' }}
                />
              </Box>
            )}
            <FormWrapper
              data={data}
              onSubmit={() => {
                setHasBeenSubmitted(true);
                if (onSubmit) {
                  onSubmit();
                }
              }}
            />
            {hasBeenSubmitted && data.downloadItem && (
              <Flex
                mt={{ base: 's', lg: 'xl' }}
                flexDir='column'
                gap='30px'
                alignItems={data.submittedMessageAlignment}
              >
                <Box
                  textStyle='desktop.body.s'
                  color={data.thankYouBackgroundImage ? 'white' : 'mountain.base'}
                  dangerouslySetInnerHTML={{ __html: data.downloadItem.descriptionHtml }}
                />
                <Button
                  maxW={'300px'}
                  whiteSpace='normal'
                  wordBreak={'break-word'}
                  as='a'
                  size='medium'
                  href={data.downloadItem.fileUrl}
                  download={data.downloadItem.fileUrl}
                  borderRadius='4px'
                >
                  {data.downloadItem.buttonText}
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
};
