export function isInViewport(element: any): boolean {
  const rect = element.current?.getBoundingClientRect();
  if (!rect) return false;
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function getElementPosition(element: any) {
  let rect = element.current;
  if (!rect) return undefined;
  let x = 0;
  let y = 0;
  //eslint-disable-next-line no-constant-condition
  while (true) {
    x += rect.offsetLeft;
    y += rect.offsetTop;
    if (rect.offsetParent === null) {
      break;
    }
    rect = rect.offsetParent;
  }
  return { offsetX: x, offsetY: y };
}
