import { AtAGlance } from 'models';

export const normalize = (model: AtAGlance) => {
  return {
    partners: model.elements.partners.value,
    client: model.elements.client.value,
    completion: model.elements.completion.value,
    location: model.elements.location.value,
    contacts: model.elements.contact.linkedItems,
    contactsCtaText: model.elements.formModalCtaButtonText.value,
    contactsCtaModal: model.elements.formModalCtaFormModal.linkedItems[0],
  };
};
